import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { onSnapshot } from "firebase/firestore";
import { MediaType } from "groupphoto-models";

import { styled } from "@mui/material/styles";
import { Box, IconButton, IconButtonProps } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { formatDateRange } from "../../lib/helpers";

import { ModifiedAssetSummary, ModifiedCluster } from "../../lib/interfaces";
import { useMediaViewer, usePool, usePoolPermissions } from "../../hooks";

import MediaViewerVideo from "./MediaViewerVideo";
import MediaViewerPhoto from "./MediaViewerPhoto";
import { useAssetService } from "../../services";
import MediaUnavailable from "./MediaUnavailable";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { generateAssetSummary } from "../../converters/AssetConverter";
import { ROUTES } from "../../routes";

const ArrowButtons = styled(IconButton)<IconButtonProps>(() => ({
    backgroundColor: "rgba(0,0,0,0.3)",
}));

const MediaViewer: React.FC = () => {
    const loading = useRef<boolean>(false);

    const [asset, setAsset] = useState<ModifiedAssetSummary | null>(null);
    const [previousAssets, setPreviousAsset] = useState<ModifiedAssetSummary[]>([]);
    const [nextAssets, setNextAsset] = useState<ModifiedAssetSummary[]>([]);

    const [previousCluster, setPreviousCluster] = useState<ModifiedCluster | null>(null);
    const [nextCluster, setNextCluster] = useState<ModifiedCluster | null>(null);

    const { pool, referenceId } = usePool();
    const { canRate, canArchiveAsset } = usePoolPermissions(pool?.role);

    const { getAssetQuery, fetchNextTimelineAsset, fetchPreviousTimelineAsset } = useMediaViewer();
    const { archiveAsset, fetchClusters } = useAssetService();

    // ===========================
    const navigate = useNavigate();
    const params = useParams();
    const type = params.assetId ? "timeline" : "cluster";
    const isTimeline = type === "timeline" ? true : false;

    let assetId: string = "";
    let x: number = 0;
    let y: number = 0;

    if (type === "timeline") {
        assetId = params.assetId ?? "";
    } else if (type === "cluster") {
        x = parseInt(params.x || "0");
        y = parseInt(params.y || "0");
    }

    useEffect(() => {
        let shouldContinue = true;
        let subscribe;
        if (assetId) {
            const q = getAssetQuery(assetId);

            subscribe = onSnapshot(q, (snapshot) => {
                if (!snapshot.exists()) {
                    setAsset(null);
                    return;
                }

                const fetchedAsset = snapshot.data() || null;

                if (!shouldContinue) return;
                setAsset(fetchedAsset);
            });
        }

        return () => {
            shouldContinue = false;
            if (subscribe) subscribe();
        };
    }, [assetId, getAssetQuery]);

    /**
     * Fetch the next and previous assets whenever the current media changes
     */
    useEffect(() => {
        if (!referenceId || !isTimeline) return;

        const fetcher = async () => {
            if (loading.current) return;

            if (assetId) {
                loading.current = true;

                try {
                    const [_nextAssets, _previousAssets] = await Promise.all([
                        fetchNextTimelineAsset(referenceId, assetId),
                        fetchPreviousTimelineAsset(referenceId, assetId),
                    ]);

                    if (_nextAssets !== null) setNextAsset(_nextAssets);
                    if (_previousAssets !== null) setPreviousAsset(_previousAssets);
                } catch (error: any) {
                    console.log(error);
                } finally {
                    loading.current = false;
                }
            }
        };

        fetcher();
    }, [assetId, referenceId, isTimeline, fetchNextTimelineAsset, fetchPreviousTimelineAsset]);

    /**
     * Fetch the next and previous clusters whenever the current media changes
     */
    useEffect(() => {
        if (!referenceId || isTimeline) return;

        const fetcher = async () => {
            if (loading.current) return;

            loading.current = true;

            let previousX = x - 1;
            let nextX = x + 1;

            if (previousX < 0) {
                previousX = 0;
            }

            try {
                const [_current, _previousCluster, _nextCluster] = await Promise.all([
                    fetchClusters(referenceId, x, y),
                    fetchClusters(referenceId, previousX, y),
                    fetchClusters(referenceId, nextX, y),
                ]);

                if (_current !== null) {
                    const summary = generateAssetSummary(_current.keystone_asset);
                    summary.takenAt = new Date((_current.keystone_asset.takenAt as any) * 1000);
                    setAsset(summary);
                }

                if (_nextCluster !== null) setNextCluster(_nextCluster);
                if (_previousCluster !== null) setPreviousCluster(_previousCluster);
            } catch (error: any) {
                console.log(error);
            } finally {
                loading.current = false;
            }
        };

        fetcher();
    }, [fetchClusters, assetId, referenceId, isTimeline, x, y]);

    /**
     * Navigate to the next asset relative to the current viewed asset
     *
     * @returns void
     */
    const onNext = useCallback(() => {
        if (isTimeline && nextAssets.length > 0) {
            navigate(generatePath(ROUTES.MEDIA_VIEWER_TIMELINE, { id: params.id, assetId: nextAssets[0].id }), {
                replace: true,
            });
        } else if (!isTimeline && nextCluster) {
            navigate(
                generatePath(ROUTES.MEDIA_VIEWER_CLUSTER, {
                    id: params.id,
                    x: nextCluster.x,
                    y: nextCluster.y,
                }),
                { replace: true }
            );
        }
    }, [navigate, params, nextAssets, nextCluster, isTimeline]);

    /**
     * Navigate to the previous asset relative to the current viewed asset
     *
     * @returns void
     */
    const onPrev = useCallback(() => {
        if (isTimeline && previousAssets.length > 0) {
            navigate(generatePath(ROUTES.MEDIA_VIEWER_TIMELINE, { id: params.id, assetId: previousAssets[0].id }), {
                replace: true,
            });
        } else if (!isTimeline && previousCluster) {
            navigate(
                generatePath(ROUTES.MEDIA_VIEWER_CLUSTER, {
                    id: params.id,
                    x: previousCluster.x,
                    y: previousCluster.y,
                }),
                { replace: true }
            );
        }
    }, [navigate, params, previousAssets, previousCluster, isTimeline]);

    /**
     * Enable Keyboard controls for Media Viewer
     */
    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.code === "Escape") {
                navigate(-1);
            } else if (event.code === "ArrowRight") {
                onNext();
            } else if (event.code === "ArrowLeft") {
                onPrev();
            } else {
                return;
            }
        };

        document.addEventListener("keydown", onKeyDown);

        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [navigate, onNext, onPrev]);

    const closePreview = () => {
        navigate(-1);
    };

    const title = useMemo(() => {
        if (!asset) return "";

        if (typeof asset.takenAt === "number") {
            if (type === "cluster") {
                const takenAt = new Date(asset.takenAt * 1000);
                return formatDateRange(takenAt, takenAt);
            } else {
                const takenAt = new Date(asset.takenAt);
                return formatDateRange(takenAt, takenAt);
            }
        }

        return formatDateRange(asset.takenAt, asset.takenAt);
    }, [type, asset]);

    const onArchiveAsset = async () => {
        try {
            if (!asset) throw new Error("Cannot archive undefined media");
            await archiveAsset(asset.id, referenceId);
            if (isTimeline && nextAssets.length > 0) onNext();
            else if (isTimeline && previousAssets.length > 0) onPrev();
            else if (!isTimeline && nextCluster) onNext();
            else if (!isTimeline && previousCluster) onPrev();
        } catch (e) {
            console.error(e);
        }
    };

    const assetIsPhotoAndAvailable = () => {
        if (!asset) return false;
        if (asset.highResURL && asset.mediaType === MediaType.Photo) return true;
        return false;
    };

    const assetIsVideoAndAvailable = () => {
        if (!asset) return false;
        if (asset.highResURL && asset.mediaType === MediaType.Video) return true;
        return false;
    };

    if (!asset) return null;

    return (
        <Box data-id={asset.id} className="preview">
            {!asset.highResURL && <MediaUnavailable closePreview={closePreview} title={title} />}

            {assetIsPhotoAndAvailable() && (
                <MediaViewerPhoto
                    asset={asset}
                    canRate={canRate}
                    canArchiveAsset={canArchiveAsset}
                    title={title}
                    closePreview={closePreview}
                    onArchiveAsset={onArchiveAsset}
                />
            )}

            {assetIsVideoAndAvailable() && (
                <MediaViewerVideo
                    asset={asset}
                    canRate={canRate}
                    canArchiveAsset={canArchiveAsset}
                    title={title}
                    closePreview={closePreview}
                    onArchiveAsset={onArchiveAsset}
                />
            )}

            <Box className="preview__controls">
                <ArrowButtons className="button icon galleryPrev" disabled={loading.current} onClick={onPrev}>
                    <ChevronLeftIcon />
                </ArrowButtons>
                <ArrowButtons className="button icon galleryNext" disabled={loading.current} onClick={onNext}>
                    <ChevronRightIcon />
                </ArrowButtons>
            </Box>
        </Box>
    );
};

export default MediaViewer;
