import { LoadingButton } from "@mui/lab";
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    circularProgressClasses,
    Divider,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { translator } from "../../i18n/translator";
import { formatDateRange, stringInitials } from "../../lib/helpers";
import { ROUTES } from "../../routes";
import { useUserService } from "../../services";
import { useAppSelector } from "../../store";
import ConfirmDeleteAccountDialog from "../../components/Dialog/ConfirmDeleteAccountDialog";
import SkeletonIndex from "./SkeletonIndex";

const AccountProgressCircle = ({ plan }) => {
    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                userSelect: "none",
            }}
        >
            <CircularProgress
                variant="determinate"
                sx={{
                    color: (theme) => (theme.palette.mode === "dark" ? "rgba(255,255,255,0.2)" : "#fff"),
                }}
                size={100}
                thickness={2}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                sx={{
                    position: "absolute",
                    color: "#1a90ff",
                    animationDuration: "550ms",
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                    },
                }}
                size={100}
                thickness={2}
                value={plan.storage.percent}
            />
            <Box
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                }}
            >
                <div>
                    <span className="accountProfile__progress__text--value">{Math.round(plan.storage.percent)}</span>
                    <span>%</span>
                </div>
                <div className="accountProfile__progress__text--used">used</div>
            </Box>
        </Box>
    );
};

const Account: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const user = useAppSelector((state) => state.user);

    const { cancelAccountDeletion } = useUserService();

    const handleDeleteClick = async () => {
        try {
            if (!user || !user.plan.deletion || user.plan.deletion.status === "cancelled") {
                setOpenDeleteConfirm(true);
                return;
            }

            setLoading(true);

            await cancelAccountDeletion();
            navigate(ROUTES.ACCOUNT_CANCELLED_DELETE);
        } catch (e) {
            setLoading(false);
            console.error(e);
        }
    };

    const onCloseDeleteConfirm = () => {
        setOpenDeleteConfirm(false);
    };

    if (!user) return <SkeletonIndex />;

    const plan = user.plan;

    return (
        <>
            <Box mt={5}>
                <Paper
                    elevation={0}
                    sx={{
                        mb: 4,
                        p: 2,
                        bgcolor: (theme) => theme.palette.action.hover,
                    }}
                >
                    <Box display="flex">
                        <Box position="relative" flexGrow={1}>
                            <Box
                                display="flex"
                                alignItems="center"
                                sx={{
                                    position: "absolute",
                                    top: "-35px",
                                }}
                            >
                                <Avatar
                                    sx={{
                                        width: "84px",
                                        height: "84px",
                                        border: (theme) => `8px solid ${theme.palette.background.default}`,
                                    }}
                                >
                                    {stringInitials(auth.currentUser?.displayName || "")}
                                </Avatar>
                                <Typography sx={{ ml: 1 }}>{user.details.username}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Paper elevation={1} sx={{ mt: 8 }}>
                        <List>
                            <ListItem>
                                <ListItemText primary="Name" secondary={user.details.fullName} />
                                <Button
                                    component={Link}
                                    to={ROUTES.ACCOUNT_EDIT_NAME}
                                    state={{ dialogLocation: location }}
                                    disableElevation
                                    variant="contained"
                                >
                                    {translator("edit")}
                                </Button>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Username" secondary={user.details.username} />
                                <Button
                                    component={Link}
                                    to={ROUTES.ACCOUNT_EDIT_USERNAME}
                                    state={{ dialogLocation: location }}
                                    disableElevation
                                    variant="contained"
                                >
                                    {translator("edit")}
                                </Button>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Email" secondary={user.details.email} />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={translator("phone.number")}
                                    secondary={user.details.phone ?? translator("phone.unavailable")}
                                />
                                <Button
                                    component={Link}
                                    to={ROUTES.ACCOUNT_EDIT_PHONE}
                                    state={{ dialogLocation: location }}
                                    disableElevation
                                    variant="contained"
                                >
                                    {translator("edit")}
                                </Button>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Account Type" secondary={translator(user.plan.type)} />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Date Joined"
                                    secondary={formatDateRange(
                                        new Date(user.details.createdAt),
                                        new Date(user.details.createdAt)
                                    )}
                                />
                            </ListItem>
                        </List>
                    </Paper>
                </Paper>

                <Divider />

                <Box my={4}>
                    <Typography variant="h6">{translator("storage")}</Typography>

                    <Paper
                        elevation={0}
                        sx={{
                            mt: 2,
                            p: 2,
                            bgcolor: (theme) => theme.palette.action.hover,
                        }}
                    >
                        <AccountProgressCircle plan={user.plan} />

                        <Box mt={1} textAlign="center">
                            {translator("account.storage", {
                                used: <strong>{user.plan.storage.size}</strong>,
                                total: <strong>{user.plan.storage.total}</strong>,
                            })}
                        </Box>
                    </Paper>
                </Box>

                <Divider />

                <Box mt={4}>
                    <Typography variant="h6">{translator("account.deleteAccount")}</Typography>

                    <Typography variant="body2" mt={3} mb={2}>
                        {translator("account.deleteAccountDesc")}
                    </Typography>

                    <LoadingButton
                        disableElevation
                        disabled={loading}
                        loading={loading}
                        variant="contained"
                        color={plan.deletion && plan.deletion.status !== "cancelled" ? "primary" : "error"}
                        onClick={handleDeleteClick}
                    >
                        {plan.deletion && plan.deletion.status !== "cancelled"
                            ? translator("account.cancelDelete")
                            : translator("account.requestDelete")}
                    </LoadingButton>
                </Box>
            </Box>

            <ConfirmDeleteAccountDialog open={openDeleteConfirm} onClose={onCloseDeleteConfirm} />
        </>
    );
};

export default Account;
