import { useEffect, useRef, useState } from "react";
import { onSnapshot } from "firebase/firestore";

import { styled } from "@mui/material/styles";
import { Button, ButtonGroup, ButtonProps } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { useAssetRating } from "../../hooks";
import { ModifiedAssetSummary } from "../../lib/interfaces";
import { useAssetService } from "../../services";

interface Props {
    assetId: string;
}

const RatingButton = styled(Button)<ButtonProps>(() => ({
    color: "#fff",
    borderColor: "transparent !important",
}));

const GalleryRating: React.FC<Props> = ({ assetId }) => {
    const [asset, setAsset] = useState<ModifiedAssetSummary | null>(null);
    const [localRating, setLocalRating] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const assetIdRef = useRef<string>(assetId);

    const { getAssetByIdRef } = useAssetService();

    useEffect(() => {
        let shouldContinue = true;

        const q = getAssetByIdRef(assetIdRef.current);
        const subscription = onSnapshot(q, (snapshot) => {
            if (!snapshot.exists() && shouldContinue) {
                setAsset(null);
                return;
            }

            const fetchedAsset = snapshot.data();

            if (fetchedAsset) {
                setAsset(fetchedAsset);
                setLocalRating(fetchedAsset.rating);
            } else {
                setAsset(null);
                setLocalRating(0);
            }
        });

        return () => {
            shouldContinue = false;
            subscription();
        };
    }, [getAssetByIdRef]);

    const { incrementCounter, decrementCounter } = useAssetRating();

    const onUpvote = async (e: React.MouseEvent<HTMLButtonElement>) => {
        try {
            e.preventDefault();
            if (!asset) return;
            if (!asset.id) throw new Error("Keystone ID not found");

            setLoading(true);

            if (loading) return;
            setLocalRating((r) => ++r);

            await incrementCounter(asset.id);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const onDownvote = async (e: React.MouseEvent<HTMLButtonElement>) => {
        try {
            e.preventDefault();
            if (!asset) throw new Error("Firebase is not initialized");
            if (!asset.id) throw new Error("Keystone ID not found");

            setLoading(true);

            if (loading) return;
            setLocalRating((r) => --r);

            await decrementCounter(asset.id);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    if (!asset) return null;

    return (
        <ButtonGroup
            variant="text"
            orientation="vertical"
            disableElevation
            sx={{ position: "absolute", bottom: 0, right: 0, zIndex: 1109, bgcolor: "rgba(0,0,0,0.3)" }}
        >
            <RatingButton onClick={onUpvote}>{<KeyboardArrowUpIcon />}</RatingButton>
            <RatingButton>{localRating}</RatingButton>
            <RatingButton onClick={onDownvote}>{<KeyboardArrowDownIcon />}</RatingButton>
        </ButtonGroup>
    );
};

export default GalleryRating;
