import { TransformComponent, TransformWrapper } from "@kokarn/react-zoom-pan-pinch";

import { assetURLHelper } from "../../lib/helpers";

import { ModifiedAssetSummary } from "../../lib/interfaces";

import { Box } from "@mui/material";
import MediaViewerDelete from "./MediaViewerDelete";
import MediaViewerHeader from "./MediaViewerHeader";
import MediaViewerRating from "./MediaViewerRating";

interface Props {
    asset: ModifiedAssetSummary;
    canRate: boolean;
    canArchiveAsset: boolean;
    title: string | JSX.Element;
    closePreview: () => void;
    onArchiveAsset: Function;
}

const MediaViewerPhoto: React.FC<Props> = ({
    asset,
    canRate,
    canArchiveAsset,
    title,
    closePreview,
    onArchiveAsset,
}) => {
    return (
        <TransformWrapper centerOnInit>
            {({ zoomIn, zoomOut }) => (
                <>
                    <MediaViewerHeader
                        onZoomIn={() => zoomIn()}
                        onZoomOut={() => zoomOut()}
                        onClosePreview={closePreview}
                        mediaType={asset.mediaType}
                        title={title}
                    >
                        {canRate && <MediaViewerRating id={asset.id} rating={asset.rating} />}

                        {canArchiveAsset && <MediaViewerDelete id={asset.id} onArchiveAsset={onArchiveAsset} />}
                    </MediaViewerHeader>

                    <TransformComponent>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ width: "100vw", height: "100vh" }}
                        >
                            <img src={assetURLHelper(asset.highResURL)} alt="High Res" />
                        </Box>
                    </TransformComponent>
                </>
            )}
        </TransformWrapper>
    );
};

export default MediaViewerPhoto;
