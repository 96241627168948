import { useState } from "react";
import { useParams } from "react-router-dom";

import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    Popover,
    PopoverOrigin,
    Tooltip,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";

import { useAppSelector } from "../../store";
import { translator } from "../../i18n/translator";

const popoverOptions: {
    anchorOrigin: PopoverOrigin;
    transformOrigin: PopoverOrigin;
} = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "right",
    },
};

const MediaViewerDelete = ({ id, onArchiveAsset }) => {
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchor] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const { poolId } = useParams();
    const pools = useAppSelector((state) => state.pools);
    const pool = pools.find((p) => p.id === poolId) ?? null;

    const title = pool?.myTitle || pool?.title || "";

    const onOpen = (event) => {
        setAnchor(event.currentTarget);
    };

    const onDismiss = () => {
        setAnchor(null);
    };

    const onArchive = async () => {
        try {
            if (loading) return;
            setLoading(true);

            await onArchiveAsset(id);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
            setAnchor(null);
        }
    };

    return (
        <Box>
            <Tooltip title="Archive">
                <IconButton onClick={onOpen}>
                    <DeleteIcon sx={{ color: "#fff" }} />
                </IconButton>
            </Tooltip>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={onDismiss}
                anchorOrigin={popoverOptions.anchorOrigin}
                transformOrigin={popoverOptions.transformOrigin}
            >
                <DialogContent>
                    <DialogContentText>{translator("pools.removeFromPool", { poolName: title })}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDismiss}>{translator("cancel")}</Button>
                    <LoadingButton color="error" onClick={onArchive} loading={loading}>
                        {translator("archive")}
                    </LoadingButton>
                </DialogActions>
            </Popover>
        </Box>
    );
};

export default MediaViewerDelete;
