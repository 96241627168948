const themeConfig = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
    palette: {
        primary: {
            main: "#1d92ff",
        },
        error: {
            main: "#f45962",
        },
    },
    typography: {
        fontFamily: [
            "Lato",
            "Poppins",
            "Open Sans",
            "Montserrat",
            "Circular Std",
            "Helvetica Neue",
            "Helvetica Neue",
            "Helvetica",
            "Arial",
            "sans-serif",
        ].join(","),
    },
    components: {
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true,
            },
        },
        MuiButton: {
            defaultProps: {
                sx: {
                    textTransform: "initial",
                },
            },
        },
        MuiLink: {
            defaultProps: {
                sx: {
                    textDecoration: "none",
                },
            },
        },
    },
};

export { themeConfig };
