import { QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { ModifiedPhotobook } from "../lib/interfaces";

const PhotobookConverter = {
    toFirestore: (photobook) => photobook,
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions) {
        const data = snapshot.data(options);

        if (data) {
            return {
                ...data,
                id: data.id || snapshot.id,
                createdAt: data.createdAt.toMillis(),
                updatedAt: data.updatedAt.toMillis(),
            } as ModifiedPhotobook;
        }

        return null;
    },
};

export default PhotobookConverter;
