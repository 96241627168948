//@ts-ignore
import { useEffect, useId, useState } from "react";
import { useParams } from "react-router-dom";

import {
    AppBar,
    Box,
    IconButton,
    //Link,
    Pagination,
    //Stack,
    Toolbar,
    Typography,
} from "@mui/material";

// import DownloadIcon from "@mui/icons-material/Download";
import MenuIcon from "@mui/icons-material/Menu";
// import PreviewIcon from "@mui/icons-material/Preview";

import { useAppDispatch, useAppSelector } from "../../store";
import { toggleMainSidebar } from "../../store/sidebarSlice";

import Layout from "./Layout";
import { translator } from "../../i18n/translator";

const Storybooks = () => {
    // Local State
    const [activePage, setActivePage] = useState(0);

    const pagesBlockPrefix = useId();

    // React Router
    const { id } = useParams();

    // Redux
    const dispatch = useAppDispatch();
    const storybooks = useAppSelector((state) => state.storybook);
    const storybook = storybooks.find((p) => p.id === id);

    useEffect(() => {
        setActivePage(0);
    }, [id]);

    useEffect(() => {
        if (!storybook) return;

        const max = storybook.pages.length;
        const handleKeyboard = (event: KeyboardEvent) => {
            switch (event.code) {
                case "ArrowLeft":
                    setActivePage((s) => (s === 0 ? 0 : --s));
                    break;
                case "ArrowRight":
                    setActivePage((s) => (s + 1 === max ? s : ++s));
                    break;
                default:
                    break;
            }
        };

        document.addEventListener("keydown", handleKeyboard);

        return () => {
            document.removeEventListener("keydown", handleKeyboard);
        };
    }, [storybook]);

    const onChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setActivePage(value - 1);
    };

    const hasPages = () => {
        if (storybook && storybook.pages.length > 0) return true;
        return false;
    };

    const onToggleMainSidebar = () => {
        dispatch(toggleMainSidebar());
    };

    return (
        <Box flex="1 1 auto" sx={{ display: "flex", flexDirection: "column" }}>
            <AppBar
                position="static"
                color="transparent"
                sx={{
                    boxShadow: "none",
                    borderWidth: "0 0 thin",
                    borderStyle: "solid",
                    borderColor: "rgba(0,0,0,0.2)",
                }}
            >
                <Toolbar variant="dense" disableGutters>
                    <IconButton
                        onClick={onToggleMainSidebar}
                        sx={{
                            display: "inline-flex",
                            ml: 2,
                        }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography sx={{ pl: 2, fontWeight: 900 }}>
                        {storybook ? storybook.title : translator("storybook")}
                    </Typography>
                    <Box flexGrow={1} />
                    {/* <Stack direction="row" spacing={1}>
                            <IconButton component={Link} href={storybook.artifacts.previewPdfUrl} target="_blank">
                                <PreviewIcon />
                            </IconButton>
                            <IconButton component={Link} href={storybook.artifacts.printPdfUrl} target="_blank">
                                <DownloadIcon />
                            </IconButton>
                        </Stack> */}
                </Toolbar>
            </AppBar>

            {storybook && (
                <>
                    <Box className="storybook">
                        {hasPages() &&
                            storybook.pages.map((page, index) => (
                                <Layout
                                    key={pagesBlockPrefix + index}
                                    visible={index === activePage ? true : false}
                                    page={page}
                                />
                            ))}
                    </Box>

                    {hasPages() && (
                        <Pagination
                            count={storybook.pages.length}
                            page={activePage + 1}
                            onChange={onChangePage}
                            sx={{
                                py: 2,
                                ul: {
                                    justifyContent: "center",
                                },
                            }}
                        />
                    )}
                </>
            )}

            {!storybook && (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                    <Typography color="grey.500">Create a storybook from your pools</Typography>
                </Box>
            )}
        </Box>
    );
};

export default Storybooks;
