import { useMemo } from "react";
import { PoolRole } from "groupphoto-models";

const usePoolPermissions = (role?: PoolRole | null) => {
    const canEdit = useMemo(() => {
        if (!role) return false;

        return role === PoolRole.Owner ? true : false;
    }, [role]);

    const canRate = useMemo(() => {
        switch (role) {
            case PoolRole.Owner:
            case PoolRole.Moderator:
            case PoolRole.Contributor:
            case PoolRole.VotingParticipant:
            case PoolRole.Voter:
                return true;
            default:
                return false;
        }
    }, [role]);

    const canArchive = useMemo(() => {
        if (!role) return false;

        return role === PoolRole.Owner ? true : false;
    }, [role]);

    const canArchiveAsset = useMemo(() => {
        switch (role) {
            case PoolRole.Owner:
            case PoolRole.Moderator:
            case PoolRole.Contributor:
                return true;
            default:
                return false;
        }
    }, [role]);

    const canInvite = useMemo(() => {
        switch (role) {
            case PoolRole.Owner:
            case PoolRole.Moderator:
            case PoolRole.ViewerWithInvites:
                return true;
            default:
                return false;
        }
    }, [role]);

    return {
        canEdit,
        canRate,
        canArchive,
        canInvite,
        canArchiveAsset,
    };
};

export default usePoolPermissions;
