import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle, IconButton } from "@mui/material";

interface DialogTitleProps {
    children?: React.ReactNode;
    onClose?: any;
}

const CustomDialogTitle: React.FC<DialogTitleProps> = ({
    children,
    onClose,
    ...props
}) => {
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...props}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default CustomDialogTitle;
