import { useCallback } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../store";

const usePool = () => {
    const location = useLocation();
    const [, setSearch] = useSearchParams();
    const { id } = useParams();

    const pools = useAppSelector((state) => state.pools);
    const pool = pools.find((p) => p.id === id) ?? null;
    const referenceId = pool?.poolId || pool?.id || "";

    const updateQueryParams = useCallback(
        (params: any, partial?: boolean) => {
            const search = new URLSearchParams(window.location.search);

            const options = {
                replace: true,
                state: location.state,
            };

            if (!params) {
                const query: any = {};

                if (search.has("entry")) {
                    query["entry"] = search.get("entry");
                }

                if (search.has("mode")) {
                    query["mode"] = search.get("mode");
                }

                if (search.has("x")) {
                    query["x"] = search.get("x");
                }

                if (search.has("y")) {
                    query["y"] = search.get("y");
                }

                setSearch(query);

                return;
            }

            if (partial) {
                const currentParams = Object.fromEntries(Array.from(search));

                setSearch(
                    {
                        ...currentParams,
                        ...params,
                    },
                    options
                );
            } else {
                setSearch(params, options);
            }
        },
        [setSearch]
    );

    return {
        pool,
        referenceId,
        updateQueryParams,
    };
};

export default usePool;
