import { Dialog, DialogContent, Divider, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDialog } from "../../../hooks";
import { translator } from "../../../i18n/translator";
import KeyboardTile from "./KeyboardTile";
import CustomDialogTitle from "../CustomDialogTitle";

import KeyboardButtonStack from "./KeyboardButtonStack";
import KeyboardTimelineList from "./KeyboardTimelineList";
import KeyboardClusterList from "./KeyboardClusterList";
import KeyboardMediaViewerList from "./KeyboardMediaViewerList";

const KeyboardHelpDialog: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));
    const { fullScreen, onClose } = useDialog();

    return (
        <Dialog fullScreen={fullScreen} fullWidth maxWidth="md" open onClose={onClose}>
            <CustomDialogTitle onClose={onClose}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="h6" fontWeight={900}>
                        {translator("pools.keyboardShortcuts")}
                    </Typography>
                    <KeyboardButtonStack>
                        <KeyboardTile>Shift</KeyboardTile>
                        <KeyboardTile>/</KeyboardTile>
                    </KeyboardButtonStack>
                </Stack>
            </CustomDialogTitle>
            <DialogContent>
                <Stack
                    spacing={1}
                    justifyContent="space-between"
                    divider={<Divider orientation={matches ? "vertical" : "horizontal"} flexItem />}
                    sx={{
                        flexDirection: { xs: "column", md: "row" },
                        mt: 3,
                    }}
                >
                    <KeyboardTimelineList />
                    <KeyboardClusterList />
                    <KeyboardMediaViewerList />
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default KeyboardHelpDialog;
