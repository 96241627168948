import { useEffect, useRef } from "react";
import videojs from "video.js/dist/alt/video.core.novtt";
import "video.js/dist/video-js.css";

import { Box } from "@mui/material";

import { assetURLHelper } from "../../lib/helpers";
import { ModifiedAssetSummary } from "../../lib/interfaces";

import MediaViewerDelete from "./MediaViewerDelete";
import MediaViewerHeader from "./MediaViewerHeader";
import MediaViewerRating from "./MediaViewerRating";

interface Props {
    asset: ModifiedAssetSummary;
    canRate: boolean;
    canArchiveAsset: boolean;
    title: string | JSX.Element;
    closePreview: () => void;
    onArchiveAsset: Function;
}

const MediaViewerVideo: React.FC<Props> = ({
    asset,
    canRate,
    canArchiveAsset,
    title,
    closePreview,
    onArchiveAsset,
}) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);

    const thumbnailRef = useRef<string>(asset.thumbnailURL);
    const highResRef = useRef<string>(asset.highResURL);

    useEffect(() => {
        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            const videoElement = videoRef.current;

            if (!videoElement) return;

            const options = {
                autoplay: false,
                controls: true,
                responsive: true,
                poster: assetURLHelper(thumbnailRef.current),
                sources: [
                    {
                        src: assetURLHelper(highResRef.current),
                        type: "video/mp4",
                    },
                ],
            };

            playerRef.current = videojs(videoElement, options);

            // You could update an existing player in the `else` block here
            // on prop change, for example:
        } else {
            // const player = playerRef.current;
            // player.autoplay(options.autoplay);
            // player.src(options.sources);
        }
    }, [videoRef]);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player) {
                // @ts-ignore
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <>
            <MediaViewerHeader onClosePreview={closePreview} mediaType={asset.mediaType} title={title}>
                {canRate && <MediaViewerRating id={asset.id} rating={asset.rating} />}
                {canArchiveAsset && <MediaViewerDelete id={asset.id} onArchiveAsset={onArchiveAsset} />}
            </MediaViewerHeader>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                }}
            >
                <div data-vjs-player style={{ height: "100%" }}>
                    <video ref={videoRef} className="video-js vjs-big-play-centered" />
                </div>
            </Box>
        </>
    );
};

export default MediaViewerVideo;
