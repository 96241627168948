export const LOCALES = {
    ENGLISH: "en",
    SPANISH: "es",
    GERMAN: "de",
    PORTUGUESE: "pt",
    FRENCH: "fr",
    JAPANESE: "ja",
    MANDARIN: "man",
    CANTONESE: "zh",
};
