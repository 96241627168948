import { ArrowBack, AudioFile, Image, VideoFile } from "@mui/icons-material";
import {
    AppBar,
    Box,
    Drawer,
    IconButton,
    LinearProgress,
    Paper,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store";
import { closeUploadDrawer } from "../../../store/uploadSlice";

const UploadDrawer = () => {
    const { showDrawer, files } = useAppSelector((state) => state.files);
    const dispatch = useAppDispatch();

    const onClose = () => {
        dispatch(closeUploadDrawer());
    };

    return (
        <Drawer open={showDrawer} anchor="right" onClose={onClose}>
            <AppBar sx={{ position: "static", mb: 2 }}>
                <Toolbar>
                    <IconButton sx={{ mr: 1 }} onClick={onClose}>
                        <ArrowBack />
                    </IconButton>
                    <Typography sx={{ mr: 1 }}>Uploads</Typography>
                    <Typography
                        variant="body2"
                        color="grey.500"
                        sx={{ flex: 1 }}
                    >
                    </Typography>
                </Toolbar>
            </AppBar>

            {files.length === 0 && (
                <Typography color="grey.500">
                    No currently uploading files
                </Typography>
            )}

            {files.map((file) => (
                <Box
                    key={file.id}
                    sx={{ px: 3, mb: 1, width: "350px", maxWidth: "100%" }}
                >
                    <Paper variant="outlined" sx={{ p: 2 }}>
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{ alignItems: "center" }}
                        >
                            {file.type === "video" && <VideoFile />}
                            {file.type === "image" && <Image />}
                            {file.type === "audio" && <AudioFile />}

                            <Box sx={{ width: "100%" }}>
                                <Typography variant="body2">
                                    {file.name}
                                </Typography>
                                <LinearProgress
                                    value={file.progress}
                                    variant={
                                        file.progress === 0
                                            ? "indeterminate"
                                            : "determinate"
                                    }
                                    color={
                                        file.progress === 100
                                            ? "success"
                                            : "primary"
                                    }
                                />
                            </Box>
                        </Stack>
                    </Paper>
                </Box>
            ))}
        </Drawer>
    );
};

export default UploadDrawer;
