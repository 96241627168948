import { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, TextField } from "@mui/material";
import { translator } from "../../../i18n/translator";
import CustomDialogTitle from "../CustomDialogTitle";
import ColorSwatch from "../../ColorSwatch";
import { usePoolService } from "../../../services";
import { remainingChars } from "../../../lib/helpers";
import { useDialog } from "../../../hooks";

const CreatePoolDialog: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [color, setColor] = useState("#1d92ff");
    const [title, setTitle] = useState("");

    const PoolService = usePoolService();
    const { fullScreen, onClose } = useDialog();

    const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setTitle(value);
    };

    const onColorSelect = (selectedColor: string) => {
        setColor(selectedColor);
    };

    const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();

            if (loading || !color || !title) return;

            setLoading(true);

            await PoolService.createPool({ title, color });

            setLoading(false);
            setTitle("");
            setColor("#1d92ff");
            onClose();
        } catch (e) {
            setLoading(false);
            console.error(e);
        }
    };

    return (
        <Dialog fullScreen={fullScreen} open onClose={onClose}>
            <CustomDialogTitle onClose={onClose}>Create a Pool</CustomDialogTitle>
            <DialogContent>
                <DialogContentText>
                    Pools are collections of photos. For example "Family Vacation '22"
                </DialogContentText>
                <Box id="createPoolForm" component="form" onSubmit={handleFormSubmit}>
                    <Box sx={{ mt: 3 }}>
                        <TextField
                            autoFocus
                            fullWidth
                            required
                            disabled={loading}
                            label="Pool Title"
                            onChange={onTitleChange}
                            value={title}
                            inputProps={{
                                maxLength: 35,
                            }}
                            helperText={remainingChars(title)}
                        />
                    </Box>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <ColorSwatch value={color} onSelect={onColorSelect} disabled={loading} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} type="button" onClick={onClose}>
                    {translator("cancel")}
                </Button>
                <Button form="createPoolForm" disabled={loading} type="submit">
                    {translator("save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreatePoolDialog;
