import { Link as RouterLink, NavLink } from "react-router-dom";
import { ArrowBack, ExitToApp } from "@mui/icons-material";
import {
    Box,
    Drawer,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useAuth } from "../../../services";
import { translator } from "../../../i18n/translator";
import { ROUTES } from "../../../routes";
import { useAppDispatch, useAppSelector } from "../../../store";
import { closeAccountSidebar } from "../../../store/sidebarSlice";
import { changeTheme } from "../../../store/themeModeSlice";

const sidebarMenuItems = [
    { route: ROUTES.ACCOUNT, label: "account.myAccount" },
    { route: ROUTES.ACCOUNT_DIGEST, label: "account.backupDigest" },
    { route: ROUTES.ACCOUNT_SUBSCRIPTION, label: "subscription" },
];

const AccountSidebar: React.FC<any> = () => {
    const authHook = useAuth();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));

    const sidebars = useAppSelector((state) => state.sidebars);
    const dispatch = useAppDispatch();

    const mode = theme.palette.mode;
    const isDark = mode === "dark" ? true : false;

    const onChangeTheme = (value: string) => {
        return () => dispatch(changeTheme(value));
    };

    const onCloseAccountSidebar = () => {
        dispatch(closeAccountSidebar());
    };

    return (
        <Drawer
            variant={matches ? "permanent" : "temporary"}
            open={matches ? true : sidebars.openAccount}
            onClose={onCloseAccountSidebar}
            ModalProps={{ keepMounted: true }}
            sx={{
                flex: { xs: "0 0 240px", md: "1 0 218px" },
                "& .MuiDrawer-paper": {
                    position: { xs: "fixed", md: "relative" },
                    justifyContent: "space-between",
                },
            }}
        >
            <Box sx={{ mt: 5, ml: "auto", width: "218px" }}>
                <List dense component="nav">
                    <ListItemButton component={RouterLink} to={ROUTES.HOME}>
                        <ListItemIcon>
                            <ArrowBack />
                        </ListItemIcon>

                        <ListItemText>Back to Pools</ListItemText>
                    </ListItemButton>
                </List>

                <List
                    dense
                    component="nav"
                    subheader={
                        <ListSubheader
                            component={Typography}
                            sx={{
                                backgroundColor: { xs: isDark ? "#3e3e3e" : "#fff", md: isDark ? "#121212" : "#fff" },
                            }}
                        >
                            {translator("user.preferences")}
                        </ListSubheader>
                    }
                >
                    {sidebarMenuItems.map((item, index) => (
                        <ListItemButton
                            key={`${item}-${index}`}
                            end
                            replace
                            component={NavLink}
                            to={item.route}
                            sx={{
                                mb: 1,
                                borderTopLeftRadius: "4px",
                                borderBottomLeftRadius: "4px",
                                "&.active": {
                                    bgcolor: (theme) => theme.palette.action.selected,
                                },
                            }}
                        >
                            <ListItemText>{translator(item.label)}</ListItemText>
                        </ListItemButton>
                    ))}
                </List>
                <List
                    dense
                    component="nav"
                    sx={{ ml: "auto", width: "218px" }}
                    subheader={
                        <ListSubheader
                            component={Typography}
                            sx={{
                                backgroundColor: { xs: isDark ? "#3e3e3e" : "#fff", md: isDark ? "#121212" : "#fff" },
                            }}
                        >
                            {translator("appearance")}
                        </ListSubheader>
                    }
                >
                    <ListItemButton onClick={onChangeTheme("light")}>
                        <ListItemText>{translator("theme.light")}</ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={onChangeTheme("dark")}>
                        <ListItemText>{translator("theme.dark")}</ListItemText>
                    </ListItemButton>
                </List>
                <List dense sx={{ ml: "auto", mb: 5, width: "218px" }}>
                    <ListItemButton
                        onClick={authHook.signOut}
                        sx={{
                            mb: 1,
                            borderRadius: "4px",
                        }}
                    >
                        <ListItemText primary={translator("logout")} />
                        <ExitToApp sx={{ width: "20px" }} />
                    </ListItemButton>
                </List>
            </Box>
            <List dense sx={{ ml: "auto", mb: 5, width: "218px" }}>
                <ListItem>
                    <Typography variant="body2" textAlign="center" sx={{ width: "100%" }}>
                        {translator("auth.footer", {
                            link: (
                                <Link href="https://groupphoto.com/legal" target="_blank" rel="noreferrer">
                                    {translator("auth.footerProtect")}
                                </Link>
                            ),
                        })}
                    </Typography>
                </ListItem>
            </List>
        </Drawer>
    );
};

export default AccountSidebar;
