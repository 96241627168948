import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { PoolType } from "groupphoto-models";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDialog, usePool } from "../../../hooks";
import { translator } from "../../../i18n/translator";
import { usePoolService } from "../../../services";
import ColorSwatch from "../../ColorSwatch";
import CustomDialogTitle from "../CustomDialogTitle";

const PoolSettingsDialog: React.FC = () => {
    const [name, setName] = useState("");
    const [colorTag, setColorTag] = useState("");

    const intl = useIntl();
    const { updatePool } = usePoolService();
    const { fullScreen, onClose } = useDialog();

    const { pool } = usePool();

    useEffect(() => {
        if (pool) {
            const currentTitle = pool.myTitle ?? pool.title;
            const currentColor = pool.myColorTag || pool.colorTag || "#1d92ff";

            setName(currentTitle);
            setColorTag(currentColor);
        }
    }, [pool]);

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setName(value);
    };

    const handleColorTagSelect = (color: string) => {
        setColorTag(color);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();

            if (!pool || !name || !colorTag) return;

            await updatePool(
                {
                    title: name,
                    color: colorTag,
                },
                pool.id
            );
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Dialog open fullScreen={fullScreen} fullWidth maxWidth="sm" onClose={onClose}>
            <CustomDialogTitle onClose={onClose}>{translator("pools.poolSettings")}</CustomDialogTitle>
            <DialogContent>
                <Box id="poolSettingsForm" component="form" mt={2} onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        required
                        name="name"
                        type="text"
                        value={name}
                        onChange={handleTextChange}
                        label={intl.formatMessage({ id: "name" })}
                        disabled={pool?.type === PoolType.Home ? true : false}
                        sx={{ mb: 2 }}
                    />
                    <ColorSwatch value={colorTag ?? ""} onSelect={handleColorTagSelect} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={onClose}>
                    {translator("cancel")}
                </Button>
                <Button form="poolSettingsForm" type="submit">
                    {translator("saveChanges")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default PoolSettingsDialog;
