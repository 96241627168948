import { LoadingButton } from "@mui/lab";

const UpgradeFooter = ({ hasActivePlan, loading, onPlanSelect }) => {
    return (
        <LoadingButton
            fullWidth
            variant="contained"
            color="primary"
            loading={loading}
            onClick={onPlanSelect}
            sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        >
            {hasActivePlan ? "Change Plan" : "Continue to checkout"}
        </LoadingButton>
    );
};

export default UpgradeFooter;
