import { useTheme } from "@mui/system";

const useAuthenticationStyles = () => {
    const theme = useTheme();

    const wrapperStyle = () => {
        if (theme.palette.mode === "light") {
            return {
                "&:before": {
                    position: "fixed",
                    top: "0",
                    left: "0",
                    zIndex: "-1",
                    width: "100%",
                    height: "100%",
                    background: "#f7faff",
                    content: `""`,
                },
            };
        } else {
            return undefined;
        }
    };

    const appBarStyle = () => {
        if (theme.palette.mode === "light") {
            return {
                bgcolor: theme.palette.common.white,
                boxShadow: "1px 4px 8px 4px rgb(219 240 255 / 25%)",
            };
        } else {
            return undefined;
        }
    };

    const passwordHeadingStyle = () => {
        const defaults = {
            display: "block",
            mb: 3,
            textAlign: "center",
            fontWeight: 700,
        };

        if (theme.palette.mode === "light") {
            return {
                ...defaults,
                color: "#062f5d",
            };
        } else if (theme.palette.mode === "dark") {
            return defaults;
        } else {
            return undefined;
        }
    };

    return {
        appBarStyle,
        passwordHeadingStyle,
        wrapperStyle,
    };
};

export default useAuthenticationStyles;
