import { useState } from "react";
import { auth } from "../../../firebase";

import { Box, Drawer, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store";
import { closeMainSidebar } from "../../../store/sidebarSlice";

import { useAuth } from "../../../services";
import { stringInitials } from "../../../lib/helpers";
import { toggleTheme } from "../../../store/themeModeSlice";
import PoolsList from "./PoolsList";
import StorybooksList from "./StorybooksList";
import MainSidebarHeader from "./Header";
import MainSidebarFooter from "./Footer";
import { useFeatures } from "../../../hooks";

const MainSidebar: React.FC = () => {
    // MUI
    const authHook = useAuth();
    const theme = useTheme();

    const mode = theme.palette.mode;

    const { allowStorybooks } = useFeatures();

    // Redux
    const sidebar = useAppSelector((state) => state.sidebars);
    const pools = useAppSelector((state) => state.pools);
    const storybooks = useAppSelector((state) => state.storybook);
    const dispatch = useAppDispatch();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onCloseMainSidebar = () => {
        dispatch(closeMainSidebar());
    };

    const onToggleTheme = () => {
        dispatch(toggleTheme());
    };

    return (
        <Drawer
            variant="temporary"
            open={sidebar.openMain}
            onClose={onCloseMainSidebar}
            ModalProps={{ keepMounted: true }}
            sx={{
                flex: "0 0 240px",
                "& .MuiDrawer-paper": {
                    width: "240px",
                    height: "100%",
                    justifyContent: "space-between",
                },
            }}
        >
            <MainSidebarHeader
                mode={mode}
                open={open}
                anchorEl={anchorEl}
                handleClick={handleClick}
                handleClose={handleClose}
                onToggleTheme={onToggleTheme}
                signOut={authHook.signOut}
            />

            <Box sx={{ flex: "1 1 auto", overflow: "auto" }}>
                <PoolsList user={authHook.user} pools={pools} />
                {storybooks.length > 0 && allowStorybooks && (
                    <StorybooksList user={authHook.user} storybooks={storybooks} />
                )}
            </Box>

            <MainSidebarFooter
                mode={mode}
                displayName={auth.currentUser?.displayName}
                initials={stringInitials(auth.currentUser?.displayName || auth.currentUser?.email || "")}
                onToggleTheme={onToggleTheme}
            />
        </Drawer>
    );
};

export default MainSidebar;
