import { PaletteMode } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";

const storedMode =
    (window.localStorage.getItem("theme") as PaletteMode) || "dark";

const initialState: {
    mode: PaletteMode;
} = {
    mode: storedMode,
};

export const themeModeSlice = createSlice({
    name: "themeMode",
    initialState,
    reducers: {
        changeTheme: (_, { payload }) => {
            window.localStorage.setItem("theme", payload);
            return {
                mode: payload,
            };
        },
        toggleTheme: (state) => {
            const mode = state.mode === "dark" ? "light" : "dark";
            window.localStorage.setItem("theme", mode);

            return {
                mode,
            };
        },
    },
});

export const { changeTheme, toggleTheme } = themeModeSlice.actions;

export default themeModeSlice.reducer;
