import { useEffect, useState } from "react";
import { Collections, PoolMember } from "groupphoto-models";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import CustomDialogTitle from "../CustomDialogTitle";
import { translator } from "../../../i18n/translator";
import { filterMembers, filterPendingMembers } from "../../../lib/helpers";
import { PendingMember } from "../../../lib/interfaces";
import { useDialog, usePool, usePoolPermissions } from "../../../hooks";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import MembersList from "./MembersList";
import InviteMembersForm from "./InviteMembersForm";

const SharingDialog: React.FC = () => {
    const [members, setMembers] = useState<PoolMember[]>([]);
    const [pendingMembers, setPendingMembers] = useState<PendingMember[]>([]);

    const { fullScreen, onClose } = useDialog();

    const { pool, referenceId } = usePool();

    const onMemberProcess = (member) => {
        setPendingMembers((p) => [...p, member]);
    };

    useEffect(() => {
        let shouldContinue = true;

        if (!referenceId) {
            setMembers([]);
            setPendingMembers([]);
            return;
        }

        const q = query(
            collection(db, Collections.PoolMember),
            where("poolId", "==", referenceId),
            orderBy("createdAt", "asc")
        );

        const unsub = onSnapshot(q, (snapshot) => {
            if (!shouldContinue) return;

            if (snapshot.empty) {
                setMembers([]);
                return;
            }

            const fetchedMembers = snapshot.docs.map((doc) => doc.data()) as PoolMember[];
            const filteredMembers = filterMembers(fetchedMembers);

            setPendingMembers((currentPendingMembers) => filterPendingMembers(filteredMembers, currentPendingMembers));
            setMembers(filteredMembers);
        });

        return () => {
            shouldContinue = false;
            unsub();
        };
    }, [referenceId]);

    const { canInvite } = usePoolPermissions(pool?.role);

    return (
        <Dialog maxWidth="sm" fullScreen={fullScreen} fullWidth open onClose={onClose}>
            <CustomDialogTitle onClose={onClose}>
                {translator(canInvite ? "inviteToPoolDynamic" : "members", {
                    name: (pool?.myTitle || pool?.title) ?? "pool",
                })}
            </CustomDialogTitle>
            <DialogContent>
                {pool && (
                    <>
                        <InviteMembersForm poolId={referenceId} onSubmit={onMemberProcess} />
                        <MembersList poolRole={pool.role} members={members} pendingMembers={pendingMembers} />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{translator("cancel")}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SharingDialog;
