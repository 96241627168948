import { CalendarPickerView } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, Stack, TextField, Typography } from "@mui/material";

const StepSelectRange = ({ rangeType, fromDate, toDate, onDateChange }) => {
    const views: CalendarPickerView[] = rangeType === "monthly" ? ["year", "month"] : ["year"];
    const openTo = rangeType === "monthly" ? "month" : "year";

    return (
        <Box sx={{ my: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Typography variant="body2" color="grey.500" fontWeight={600} sx={{ mb: 2 }}>
                    Dates
                </Typography>
                <Stack direction="row" spacing={2}>
                    <DatePicker
                        views={views}
                        label="From"
                        value={fromDate}
                        openTo={openTo}
                        onChange={(newValue) => {
                            onDateChange(newValue, "from");
                        }}
                        renderInput={(params) => <TextField {...params} fullWidth required helperText={null} />}
                    />

                    <DatePicker
                        views={views}
                        label="To"
                        minDate={fromDate}
                        value={toDate}
                        openTo={openTo}
                        onChange={(newValue) => {
                            onDateChange(newValue, "to");
                        }}
                        renderInput={(params) => <TextField {...params} fullWidth required helperText={null} />}
                    />
                </Stack>
            </LocalizationProvider>
        </Box>
    );
};

export default StepSelectRange;
