import { http } from "../http";

const useUserService = () => {
    const requestAccountDeletion = async () => {
        try {
            const { data } = await http.post("/api/user/account/delete");
            return data;
        } catch (e) {
            throw e;
        }
    };

    const confirmAccountDeletion = async (token: string | null) => {
        try {
            if (!token) throw new Error("Token is null or undefined");

            const { data } = await http.post(
                "/api/user/account/delete-confirm",
                {
                    token,
                }
            );
            return data;
        } catch (e) {
            throw e;
        }
    };

    const cancelAccountDeletion = async () => {
        try {
            const { data } = await http.post("/api/user/account/delete-cancel");
            return data;
        } catch (e) {
            throw e;
        }
    };

    return {
        requestAccountDeletion,
        confirmAccountDeletion,
        cancelAccountDeletion,
    };
};

export default useUserService;
