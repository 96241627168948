import {
    Box,
    FormControl,
    FormControlLabel,
    Link,
    Radio,
    RadioGroup,
    Skeleton,
    Stack,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { auth } from "../../firebase";
import { translator } from "../../i18n/translator";
import { useProfileService } from "../../services";
import { useAppDispatch, useAppSelector } from "../../store";
import { updateUserDigest } from "../../store/userSlice";

const options = [
    { value: "daily", label: translator("digest.frequency.daily") },
    { value: "every_3_days", label: translator("digest.frequency.every3Days") },
    { value: "weekly", label: translator("digest.frequency.weekly") },
    { value: "disabled", label: translator("digest.frequency.noUpdates") },
];

const AccountDigest: React.FC = () => {
    const [freq, setFreq] = useState("");

    const { updateDigestSettings } = useProfileService();
    const user = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!user) return;

        const { digest } = user;
        if (!digest.enabled) {
            setFreq("disabled");
        } else {
            setFreq(digest.frequency);
        }
    }, [user]);

    const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const oldValue = freq;
        try {
            const { value } = event.target;
            setFreq(value);

            const data = await updateDigestSettings(value);

            if (!data.success) {
                setFreq(oldValue);
            } else {
                dispatch(updateUserDigest(value));
            }
        } catch (e) {
            console.error(e);
            setFreq(oldValue);
        }
    };

    return (
        <Box>
            <Box my={3}>
                <Typography variant="body2">
                    {translator("digest.description")}
                </Typography>

                {auth.currentUser && auth.currentUser.email && (
                    <Typography variant="body2">
                        {translator("digest.emailSend", {
                            email: (
                                <Link href={`mailto:${auth.currentUser.email}`}>
                                    {auth.currentUser.email}
                                </Link>
                            ),
                        })}
                    </Typography>
                )}
            </Box>

            <FormControl>
                <RadioGroup value={freq} onChange={onChange}>
                    {freq &&
                        options.map((opt) => (
                            <FormControlLabel
                                key={opt.value}
                                value={opt.value}
                                control={<Radio />}
                                label={opt.label}
                                sx={{ my: 1 }}
                            />
                        ))}

                    {!freq &&
                        options.map((opt) => (
                            <Stack
                                key={opt.value}
                                direction="row"
                                spacing={1}
                                sx={{ alignItems: "center", my: 2 }}
                            >
                                <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={20}
                                />
                                <Skeleton
                                    variant="text"
                                    width={100}
                                    height={20}
                                />
                            </Stack>
                        ))}
                </RadioGroup>
            </FormControl>
        </Box>
    );
};

export default AccountDigest;
