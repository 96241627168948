import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const AuthEmailInput = styled(TextField)(({ theme }) => ({
    maxWidth: "298px",
    width: "100%",
    // background: theme.palette.background.default,
    // "& .MuiOutlinedInput-notchedOutline": {
    //     borderColor: "#c3d5ee",
    // },

    // "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    //     borderColor: theme.palette.primary.main,
    // },

    // "& .MuiInputLabel-root": {
    //     color: theme.palette.common.black,
    // },
}));

export default AuthEmailInput;
