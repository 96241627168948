import { getDocs, onSnapshot, Query } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

import { useAssetService } from "../services";
import { ModifiedAssetSummary, Months } from "../lib/interfaces";
import { createGalleryMarks } from "../lib/helpers";
import usePool from "./usePool";

const useTimeline = () => {
    const initializedRef = useRef<boolean>(false);
    const snapshotRef = useRef<any>(null);

    const [assetQuery, setAssetQuery] = useState<Query<ModifiedAssetSummary> | null>(null);

    const [isDoneFetching, setIsDoneFetching] = useState(false);
    const [assets, setAssets] = useState<ModifiedAssetSummary[]>([]);
    const [marks, setMarks] = useState<any>([]);
    const [months, setMonths] = useState<Months[]>([]);
    const [page, setPage] = useState(1);
    const [totalMonths, setTotalMonths] = useState(0);

    const { pool, referenceId } = usePool();
    const [search] = useSearchParams();
    const entryRef = useRef<string | null>(search.get("entry"));
    const isInitialLoading = useRef<boolean>(false);

    const { updateQueryParams } = usePool();

    const { fetchInitialAssets, fetchNextAssets, fetchPreviousAssets, fetchAssetByDate } = useAssetService();

    useEffect(() => {
        if (!referenceId) return;
        let shouldContinue = true;

        if (!isInitialLoading.current) {
            isInitialLoading.current = true;

            reset();

            fetchInitialAssets(referenceId, entryRef.current)
                .then((query) => {
                    if (shouldContinue) {
                        setAssetQuery(query);
                    }
                })
                .catch((e) => {
                    console.error(e);
                })
                .finally(() => {
                    isInitialLoading.current = false;
                });
        }
    }, [fetchInitialAssets, referenceId]);

    useEffect(() => {
        let shouldContinue = true;
        const currentEntry = search.get("entry");

        if (
            !currentEntry &&
            !isInitialLoading.current &&
            initializedRef.current &&
            window.location.pathname.startsWith("/pool/")
        ) {
            isInitialLoading.current = true;

            reset();

            fetchInitialAssets(referenceId, currentEntry)
                .then((query) => {
                    if (shouldContinue) {
                        setAssetQuery(query);
                    }
                })
                .catch((e) => {
                    console.error(e);
                })
                .finally(() => {
                    isInitialLoading.current = false;
                });
        }

        return () => {
            shouldContinue = false;
        };
    }, [fetchInitialAssets, referenceId, search]);

    useEffect(() => {
        if (!assetQuery || !pool) return;
        let shouldContinue = true;

        const unsubscribe = onSnapshot(
            assetQuery,
            (snapshot) => {
                setIsDoneFetching(true);

                if (snapshot.empty || !shouldContinue) {
                    snapshotRef.current = null;
                    setAssets([]);
                    return;
                }

                snapshotRef.current = snapshot;
                const fetchedAssets = snapshot.docs.map((doc) => doc.data());
                setAssets(fetchedAssets);

                if (!initializedRef.current) {
                    const { monthsCollection, counter, marks } = createGalleryMarks(pool);

                    setMonths(monthsCollection);
                    setMarks(marks);
                    setTotalMonths(counter);
                    setPage(counter);

                    initializedRef.current = true;
                }
            },
            (error) => {
                setIsDoneFetching(true);
                initializedRef.current = true;
                console.error(error);
            }
        );

        return () => {
            shouldContinue = false;
            unsubscribe();
        };
    }, [pool, assetQuery]);

    useEffect(() => {
        if (assets.length === 0) return;
        let shouldContinue = true;

        const label = dayjs(assets[0].takenAt).format("MMM YYYY");
        const index = months.findIndex((m) => m.label === label);
        const initialPage = totalMonths - index;

        if (!shouldContinue) return;
        setPage(initialPage);

        return () => {
            shouldContinue = false;
        };
    }, [assets, months, totalMonths]);

    useEffect(() => {
        if (assets.length > 0 && window.location.pathname.startsWith("/pool/")) {
            updateQueryParams({ entry: assets[0].id }, true);
        }
    }, [assets, updateQueryParams]);

    const getMonth = (index: number) => {
        return months[totalMonths - (index - 1) - 1];
    };

    const valueLabelFormat = (sliderValue: number) => {
        const currentMonth = getMonth(sliderValue);
        return currentMonth.label;
    };

    const onSliderDrag = (_, value) => {
        setPage(value);
    };

    const onSliderCommit = async (_, sliderValue) => {
        try {
            if (!referenceId) throw new Error("Cannot fetch assets by date when pool is undefined");

            const requestingMonth = getMonth(sliderValue);
            const query = fetchAssetByDate(referenceId, requestingMonth.date);
            setAssetQuery(query);
        } catch (error: any) {
            console.error(error.message);
        }
    };

    const onFetchNext = async () => {
        try {
            if (!referenceId) throw new Error("Cannot fetch next assets if pool is undefined");
            if (!assetQuery) throw new Error("Cannot access snapshot. Current query is set as null");

            const snapshot = await getDocs(assetQuery);
            const doc = snapshot.docs[snapshot.size - 1];
            const query = fetchNextAssets(referenceId, doc);

            if (!query) return null;
            const updatedSnapshot = await getDocs(query);
            if (updatedSnapshot.empty) return null;

            setAssetQuery(query);
        } catch (e) {
            throw e;
        }
    };

    const onFetchPrev = async () => {
        try {
            if (!referenceId) throw new Error("Cannot fetch next assets if pool is undefined");
            if (!assetQuery) throw new Error("Cannot access snapshot. Current query is set as null");

            const snapshot = await getDocs(assetQuery);
            const query = fetchPreviousAssets(referenceId, snapshot.docs[0]);

            if (!query) return null;
            const updatedSnapshot = await getDocs(query);
            if (updatedSnapshot.empty) return null;

            setAssetQuery(query);
        } catch (e) {
            throw e;
        }
    };

    const reset = () => {
        initializedRef.current = false;

        setAssetQuery(null);
        setAssets([]);
        setMonths([]);
        setMarks([]);
        setPage(1);
        setTotalMonths(0);
        setIsDoneFetching(false);
    };

    return {
        assets,
        isDoneFetching,
        slider: {
            totalMonths,
            months,
            marks,
            page,
            valueLabelFormat,
        },
        onSliderDrag,
        onSliderCommit,
        onFetchNext,
        onFetchPrev,
        getMonth,
        reset,
    };
};

export default useTimeline;
