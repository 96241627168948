import { LOCALES } from "../locales";

const TRANSLATIONS = {
    [LOCALES.GERMAN]: {
        signup: "Anmelden",
        login: "Anmeldung",
        logout: "Ausloggen",
        email: "Email",
        phone: "Phone",
        terms_of_service: "Nutzungsbedingungen",
        privacy_policy: "Datenschutz-Bestimmungen",
        continue: "Fortsetzen",
        back: "Zurück",
        you: "Du",
        done: "Getan",
        modalTitle: '"Gruppenfoto" möchte auf Ihre Kontakte zugreifen',
        modalDescription:
            "Group Photo synchronisiert nur Telefonnummern aus Ihrem Adressbuch mit Group Photo-Servern, um eine Verbindung mit anderen Group Photo-Benutzern herzustellen",
        suggested: "Empfohlen",
        choose_color: "Wähle eine Farbe",
        choose_color_instruction:
            "Wählen Sie eine benutzerdefinierte Farbe aus, indem Sie den Griff um den Ring ziehen",
        activity: "Aktivität",
        rate: "Bewertung",
        select: "Wählen",
        color: "Farbe",
        archive: "Archiv",
        save: "Sparen",
        no: "Nein",
        invite: "Einladen",
        owner: "der Inhaber",
        remove: "Entfernen",
        contributor: "der Beiträger",
        viewer: "der Zuschauer",
        moderator: "der Synodalpräsident",
        voter: "Wähler",
        inviteByEmail: "Einladung per E-Mail",
        participant: "der Teilnehmer",
        votingParticipant: "Wahlberechtigter",
        viewerWithInvites: "Viewer mit Einladungen",
        next: "Next",

        "email.required": "E-Mailadresse wird benötigt",
        "email.invalid": "E-Mail ist ungültig",
        "phone.required": "Telefon ist erforderlich",
        "phone.invalid": "Telefon ist ungültig",
        "phone.min": "Das Telefon muss mindestens 10 Zeichen haben",

        "login.welcome": "Willkommen zurück",
        "login.google": "Melden Sie sich mit Google an",
        "login.facebook": "Mit Facebook einloggen",
        "login.email": "Mit E-Mail anmelden",
        "login.apple": "Melden Sie sich mit Apple an",
        "login.to_signup": "Don't have an account? {signup}",

        "auth.footer":
            "Durch die Verwendung von Group Photo stimmen Sie unseren {terms} und {privacy} zu",

        "register.welcome": "Sharing What Matters",
        "register.google": "Melden Sie sich bei Google an",
        "register.facebook": "Melde dich über Facebook an",
        "register.email": "Registrieren Sie sich mit Ihrer E-Mail Adresse",
        "register.apple": "Melden Sie sich bei Apple an",
        "register.to_login": "Sie haben bereits ein Konto? {login}",

        "nav.pools": "Pools",
        "nav.members": "Mitglieder",
        "nav.inbox": "Posteingang",
        "nav.me": "Mich",

        "pools.pools": "Pools",
        "pools.new": "Neuer Pool",
        "pools.home": "Zuhause",
        "pools.select_pools": "Wählen Sie Pools",
        "pools.invite_contact": "Von Kontakten einladen",
        "pools.copy_share": "Link kopieren und freigeben",
        "pools.frequent_contacts": "Häufige Kontakte",
        "pools.poolChat": "Pool Chat",
        "pools.shareToRings": "Mit Ringen teilen",
        "pools.addMedia": "Medien hinzufügen",
        "pools.poolSettings": "Pooleinstellungen",
        "pools.managePool": "Pool verwalten",
        "pools.descriptionOptional": "Beschreibung (optional)",

        "members.members": "Mitglieder",
        "members.invite": "Laden Sie Familie und Freunde ein",

        "inbox.inbox": "Posteingang",
        "inbox.delete": "{user} haben das Foto von gelöscht {pool}",
        "inbox.added_single": "{user} hat ein Foto hinzugefügt {pool}",
        "inbox.added_bulk": "{user} hat {number} Fotos zu {pool} hinzugefügt",

        "account.account": "Mein Gruppenfotokonto",
        "account.archive": "Archiv",
        "account.backup": "Backup-Einstellungen",
        "account.refer": "Freunde werben",
        "account.help": "Hilfe Unterstützung",
        "account.feedback": "Hinterlasse Kommentar",
        "account.type": "Konto Typ",
        "account.space_used": "Platz genutzt",
        "account.upgrade": "Upgrade auf Group Photo Standard",
        "account.archived_pools": "Archivierte Pools",
        "account.unarchive": "Unarchiv",
        "account.media": "Medien",
        "account.backup_history": "Sicherungsverlauf",

        "upload.choose": "Aus der Bibliothek auswählen",
        "upload.take_photo": "Nehmen Sie ein Foto oder Video auf",
        "upload.cancel": "Stornieren",

        "memberRoles.description1":
            "Kann Medien hinzufügen / entfernen. Der Pool kann nicht verwaltet werden",
        "memberRoles.description2":
            "Kann nur Medien anzeigen und chatten. Der Pool kann nicht bearbeitet werden",
        "memberRoles.description3":
            "Kann eine Rolle zuweisen und Mitglieder verwalten. Hat das Privileg, Assets zu erstellen, hinzuzufügen, zu löschen und zu bewerten",
        "memberRoles.description4":
            "Entfernen Sie andere Mitglieder, verwalten Sie Mitglieder, Inhalte / Assets und können Sie die Pooleinstellungen nicht ändern",
        "memberRoles.description5": "Kann chatten und bewerten",
        "memberRoles.description6": "Kann nur chatten",
        "memberRoles.description7": "Kann nur bewerten",
        "memberRoles.description8": "Nur anzeigen, kann aber Personen einladen",
        "memberRoles.description9": "Erweiterte Berechtigungen anzeigen",
        "memberRoles.description10": "Can add assets, chat",
        "memberRoles.description11": "View only",
    },
};

export default TRANSLATIONS;
