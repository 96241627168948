import { Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { MediaType, PoolRole } from "groupphoto-models";
import { generateAssetSummary } from "../../converters/AssetConverter";
import { usePoolPermissions } from "../../hooks";
import { assetURLHelper, getAssetThumbnail } from "../../lib/helpers";
import { SubCluster } from "../../lib/interfaces";
import GalleryRating from "./GalleryRating";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../store";

const Item = styled(Paper)(({ theme }) => ({
    position: "relative",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: "rgba(0,0,0,0.3)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: "white",
}));

interface Props {
    subCluster: SubCluster;
    onClusterClick: any;
    isRateMode: boolean;
    memberRole?: PoolRole;
}

const GalleryGridItemCluster: React.FC<Props> = ({ subCluster, isRateMode, onClusterClick, memberRole }) => {
    const { canRate } = usePoolPermissions(memberRole);
    const asset = generateAssetSummary(subCluster.keystone_asset);
    const thumbnail = getAssetThumbnail(subCluster.keystone_asset);
    let path = "";

    const selectedClusters = useAppSelector((state) => state.selection.selectedClusters);

    const [search] = useSearchParams();
    const location = useLocation();

    if (thumbnail && thumbnail.path) {
        path = assetURLHelper(thumbnail.path);
    }

    search.set("x", subCluster.x.toString());
    search.set("y", subCluster.y.toString());

    const isSelected = () => {
        return selectedClusters.find((c) => {
            if (c.x === subCluster.x && c.y === subCluster.y && c.name === subCluster.name) {
                return true;
            } else {
                return false;
            }
        });
    };

    return (
        <Link to={{ pathname: location.pathname, search: search.toString() }} onClick={onClusterClick}>
            <Item
                data-id={asset.id}
                sx={{
                    backgroundImage: `url('${path}')`,
                    border: (theme) => (isSelected() ? `4px solid ${theme.palette.primary.main}` : undefined),
                }}
            >
                <Typography
                    position="absolute"
                    bottom={10}
                    left={0}
                    width="100%"
                    component="span"
                    sx={{
                        textShadow: "1px 1px 1px #000",
                        fontSize: { xs: "0.75rem", md: "1rem" },
                        "@media (orientation: landscape) and (max-width: 992px)": {
                            fontSize: "0.75rem !important",
                        },
                    }}
                >
                    {subCluster.description}
                </Typography>

                {asset.mediaType === MediaType.Video && (
                    <PlayCircleIcon
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            fontSize: { xs: "24px", sm: "48px" },
                            transform: "translate(-50%, -50%)",
                            filter: "drop-shadow(2px 4px 6px rgba(0,0,0,0.2))",
                        }}
                    />
                )}

                {isRateMode && canRate && <GalleryRating assetId={asset.id} />}
            </Item>
        </Link>
    );
};

export default GalleryGridItemCluster;
