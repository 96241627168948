import { Box, Skeleton } from "@mui/material";
import GalleryGrid from "./GalleryGrid";

const GalleryGridSkeleton = () => (
    <Box sx={{ display: "flex", height: "100%" }}>
        <GalleryGrid>
            {[...Array(8)].map((_, i) => (
                <Skeleton
                    key={i}
                    variant="rectangular"
                    width="100%"
                    height="100%"
                    sx={{
                        borderRadius: "4px",
                        bgcolor: (theme) =>
                            theme.palette.mode === "dark"
                                ? "grey.900"
                                : "grey.200",
                    }}
                />
            ))}
        </GalleryGrid>
    </Box>
);

export default GalleryGridSkeleton;
