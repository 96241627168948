import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../http";

interface Details {
    avatarURL?: string;
    createdAt: number;
    email?: string;
    fullName?: string;
    homePoolId: string;
    id: string;
    signedUpWithAuthUserId: string;
    username?: string;
}

interface Digest {
    enabled: boolean;
    frequency: "daily" | "weekly" | "every_3_days" | "disabled";
}

interface Plan {
    active: boolean;
    endsAt?: number;
    id: string;
    lastRenewedAt?: number;
    planCapacityBytes: number;
    startsAt?: number;
    storage: {
        percent: number;
        size: string;
        total: string;
        frequency: string | null;
    };
    type: string;
    usedBytes: number;
    userId: string;
}

const initialState: any = null;

export const fetchUserSlice = createAsyncThunk("user/fetchUser", async () => {
    const response = await http.get("/api/user");
    return response.data;
});

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        insertUserData: (_, { payload }) => {
            return {
                digest: payload.userData.digest as Digest,
                details: payload.userData.user as Details,
                plan: payload.userData.servicePlan as Plan,
            };
        },
        updateUserDigest: (state, { payload }) => {
            let enabled = true;

            if (payload === "disabled") {
                enabled = false;
            }

            return {
                ...state,
                digest: {
                    enabled,
                    frequency: payload,
                },
            };
        },
        updateUserSubscription: (state, { payload }) => {
            if (!payload.data) return state;

            return {
                ...state,
                sub: {
                    ...payload.data.subscription,
                    card: payload.data.card,
                },
            };
        },
        resetUserData: () => initialState,
    },
    extraReducers(builder) {
        builder.addCase(fetchUserSlice.fulfilled, (state, action) => {
            return {
                digest: action.payload.digest as Digest,
                details: action.payload.user as Details,
                plan: action.payload.servicePlan as Plan,
            };
        });
    },
});

export const {
    insertUserData,
    updateUserDigest,
    updateUserSubscription,
    resetUserData,
} = userSlice.actions;

export default userSlice.reducer;
