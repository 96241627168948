import { useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store";
import { toggleMainSidebar } from "../store/sidebarSlice";
import { ROUTES } from "../routes";

import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import GalleryToolbarTitle from "./Gallery/GalleryToolbarTitle";
import { Menu } from "@mui/icons-material";
import { useAuth } from "../services";
import { PoolType } from "groupphoto-models";

const Home: React.FC = () => {
    // Plugin Hooks
    const navigate = useNavigate();

    // Custom Hooks
    const authHook = useAuth();

    // Redux
    const user = useAppSelector((state) => state.user);
    const pools = useAppSelector((state) => state.pools);
    const dispatch = useAppDispatch();

    useEffect(() => {
        document.title = "Home - Group Photo";
    }, []);

    useEffect(() => {
        const redirect = (orgId: string, poolId: string) => {
            navigate(
                generatePath(ROUTES.POOL, {
                    orgId: orgId,
                    id: poolId,
                }),
                {
                    replace: true,
                }
            );
        };

        if (user && user.details.homePoolId) {
            redirect(user.details.id, user.details.homePoolId);
            return;
        }

        if (pools.length > 0) {
            const home = pools.find((p) => p.type === PoolType.Home);
            if (home && authHook.user) {
                redirect(authHook.user.uid, home.id);
            }
        }
    }, [user, pools, navigate, authHook]);

    const onToggleMainSidebar = () => {
        dispatch(toggleMainSidebar());
    };

    return (
        <Box flex="1 1 auto" sx={{ display: "flex", flexDirection: "column" }}>
            <AppBar
                position="static"
                color="transparent"
                sx={{
                    boxShadow: "none",
                    borderWidth: "0 0 thin",
                    borderStyle: "solid",
                    borderColor: "rgba(0,0,0,0.2)",
                }}
            >
                <Toolbar variant="dense" disableGutters>
                    <IconButton onClick={onToggleMainSidebar} sx={{ ml: 2 }}>
                        <Menu />
                    </IconButton>
                    <Box sx={{ px: 2 }}>
                        <GalleryToolbarTitle>Welcome to Group Photo</GalleryToolbarTitle>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: "calc(100% - 49px)" }}>
                Select a pool to view
            </Box>
        </Box>
    );
};

export default Home;
