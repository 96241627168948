import config from "./configs";

export const ROUTES = {
    MARKETING: config.domains.marketing,
    MARKETING_DOWNLOADS: `${config.domains.marketing}/download`,

    HOME: "/",
    EMPTY_POOL: "/pool",
    POOL: "/pool/:orgId/:id",

    CREATE_POOL: "/pools/create",
    POOL_ADD_MEDIA: "/pool/:orgId/:id/add-media",
    POOL_SETTINGS: "/pool/:orgId/:id/settings",
    INVITE_TO_POOL: "/pool/:orgId/:id/invite",
    POOL_ADD: "/pool/:orgId/:id/add",
    POOL_ASSET_UPDATE: "/pool/:orgId/:id/update-taken-at",

    PHOTOBOOK: "/photobook/:id",
    STORYBOOK: "/storybook/:id",

    MEDIA_VIEWER_TIMELINE: "/media/:id/timeline/:assetId",
    MEDIA_VIEWER_CLUSTER: "/media/:id/cluster/:x/:y",

    CREATE_STORYBOOK: "/pool/:orgId/:id/create-storybook",

    LOGIN: "/login",
    LOGIN_PASSWORD: "/login/password",

    REGISTER: "/register",
    REGISTER_PASSWORD: "/register/password",

    FORGOT_PASSWORD: "/forgot-password",

    INVITE: "/invitation",

    ACCOUNT: "/account",
    ACCOUNT_DIGEST: "/account/digest",
    ACCOUNT_SUBSCRIPTION: "/account/subscription",
    ACCOUNT_UPGRADE: "/account/upgrade",
    ACCOUNT_CHECKOUT: "/account/checkout",
    ACCOUNT_DELETE_CONFIRM: "/account/delete-confirm",
    ACCOUNT_CANCELLED_DELETE: "/account/cancelled-delete",

    ACCOUNT_EDIT_NAME: "/account/edit/name",
    ACCOUNT_EDIT_USERNAME: "/account/edit/username",
    ACCOUNT_EDIT_PHONE: "/account/edit/phone",

    KEYBOARD_SHORTCUTS: "/keyboard-shortcuts",
};
