import { FC, useMemo, useState } from "react";
import { PoolMember, PoolRole } from "groupphoto-models";

import { Avatar, Button, List, ListItem, ListItemIcon, ListItemText, Menu } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { translator } from "../../../i18n/translator";
import { useMemberService } from "../../../services";
import { usePoolPermissions } from "../../../hooks";
import { auth } from "../../../firebase";
import { stringInitials } from "../../../lib/helpers";
import RolePicker from "../../RolePicker";

interface ListProps {
    members: PoolMember[];
    pendingMembers?: any[];
    poolRole?: PoolRole | null;
    onUpdateSuccess?: any;
    onUpdateError?: any;
    onRemoveSuccess?: any;
    onRemoveError?: any;
}

interface ItemProps {
    member: PoolMember;
    poolRole: PoolRole | null;
    onUpdateSuccess?: any;
    onUpdateError?: any;
    onRemoveSuccess?: any;
    onRemoveError?: any;
}

const RoleLabel = ({ role }) => (
    <Button variant="text" disabled>
        {translator(role)}
    </Button>
);

const DropdownContent = ({ member, memberName, role, loading, onChange, onRemove }) => {
    const [anchorEl, setAnchor] = useState(null);
    const showPicker = Boolean(anchorEl);

    const onShowPicker = (e) => {
        setAnchor(e.currentTarget);
    };

    const handleClose = () => {
        setAnchor(null);
    };

    const onRemoveMember = (member: any) => {
        return () => onRemove(member);
    };

    return (
        <>
            <Button onClick={onShowPicker} endIcon={<ExpandMoreIcon />}>
                {translator(role)}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={showPicker}
                onClose={handleClose}
                transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                }}
                PaperProps={{
                    sx: {
                        width: "100%",
                        maxWidth: "335px",
                    },
                }}
            >
                <RolePicker
                    member={member}
                    memberName={memberName}
                    value={role}
                    loading={loading}
                    handleChange={onChange}
                    handleRemove={onRemoveMember(member)}
                    handleClose={handleClose}
                />
            </Menu>
        </>
    );
};

const MemberItem: FC<ItemProps> = ({
    member,
    poolRole,
    onUpdateSuccess,
    onUpdateError,
    onRemoveSuccess,
    onRemoveError,
}) => {
    const [loading, setLoading] = useState(false);

    const MemberService = useMemberService();

    const expectedRole = useMemo(() => {
        if (member.invitation) return member.invitation.role;
        return member.role;
    }, [member.invitation, member.role]);

    const [role, setRole] = useState(expectedRole || PoolRole.Contributor);

    const isPending = useMemo(() => {
        if (member.invitation) return true;
        return false;
    }, [member]);

    const memberName = useMemo(() => {
        if (isPending && member.invitation && member.invitation.email) {
            return member.invitation.email;
        }

        if (!member.userSummary) return "";

        return member.userSummary.displayName;
    }, [member, isPending]);

    const { canInvite } = usePoolPermissions(poolRole);

    const onChange = async (event) => {
        try {
            if (loading) return;

            const { value } = event.target;

            setLoading(true);
            setRole(value);

            const id = isPending ? member.id : `${member.poolId}-${member.userId}`;

            await MemberService.updateMember(id, isPending, value);

            setLoading(false);
            if (onUpdateSuccess) onUpdateSuccess();
        } catch (error) {
            console.error(error);
            setLoading(false);
            if (onUpdateError) onUpdateSuccess();
        }
    };

    const onRemove = async () => {
        try {
            if (loading) return;

            const id = isPending ? member.id : `${member.poolId}-${member.userId}`;

            if (!id) return;

            setLoading(true);
            await MemberService.removeMember(id);
            if (onRemoveSuccess) onRemoveSuccess();
        } catch (error) {
            console.error(error);
            setLoading(false);
            if (onRemoveError) onRemoveError();
        }
    };

    if (auth.currentUser && poolRole && member.userId && member.userId === auth.currentUser.uid) {
        return (
            <ListItem disableGutters>
                <ListItemIcon>
                    <Avatar>{stringInitials(memberName)}</Avatar>
                </ListItemIcon>
                <ListItemText>{memberName}</ListItemText>
                <RoleLabel role={role} />
            </ListItem>
        );
    }

    return (
        <ListItem disableGutters>
            <ListItemIcon>
                <Avatar>{stringInitials(memberName)}</Avatar>
            </ListItemIcon>
            <ListItemText>{memberName}</ListItemText>
            {canInvite ? (
                <DropdownContent
                    member={member}
                    memberName={memberName}
                    role={role}
                    loading={loading}
                    onChange={onChange}
                    onRemove={onRemove}
                />
            ) : (
                <RoleLabel role={role} />
            )}
        </ListItem>
    );
};

const PendingMemberItem = ({ member }) => (
    <ListItem disableGutters>
        <ListItemIcon>
            <Avatar>{stringInitials(member.identity)}</Avatar>
        </ListItemIcon>
        <ListItemText>{member.identity}</ListItemText>
        <RoleLabel role={member.role} />
    </ListItem>
);

const MembersList: FC<ListProps> = ({
    members,
    pendingMembers,
    poolRole,
    onUpdateSuccess,
    onUpdateError,
    onRemoveSuccess,
    onRemoveError,
}) => {
    if (!poolRole) return null;

    return (
        <List>
            {members.length > 0 &&
                members.map((member, index) => (
                    <MemberItem
                        key={member.id ?? index}
                        member={member}
                        poolRole={poolRole}
                        onUpdateSuccess={onUpdateSuccess}
                        onUpdateError={onUpdateError}
                        onRemoveSuccess={onRemoveSuccess}
                        onRemoveError={onRemoveError}
                    />
                ))}

            {pendingMembers &&
                pendingMembers.length > 0 &&
                pendingMembers.map((member, index) => <PendingMemberItem member={member} key={`pending-${index}`} />)}
        </List>
    );
};

export default MembersList;
