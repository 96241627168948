import { PoolMember, PoolRole } from "groupphoto-models";
import { translator } from "../i18n/translator";

// Components
import {
    Avatar,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Radio,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { stringInitials } from "../lib/helpers";

// Assets

interface Props {
    value: PoolRole | string;
    member?: PoolMember;
    memberName?: string;
    loading?: boolean;
    showMoreRoles?: boolean;
    handleChange: any;
    handleShowMoreRoles?: any;
    handleRemove?: any;
    handleClose?: any;
}

const roles = [
    {
        value: PoolRole.Contributor,
        label: PoolRole.Contributor,
        description: "memberRoles.description.contributor",
    },
    {
        value: PoolRole.Viewer,
        label: PoolRole.Viewer,
        description: "memberRoles.description.viewer",
    },
    {
        value: PoolRole.Owner,
        label: PoolRole.Owner,
        description: "memberRoles.description.owner",
    },
];

const RolePicker: React.FC<Props> = ({
    member,
    memberName,
    value,
    loading,
    handleChange,
    handleRemove,
}) => (
    <>
        <List>
            {member && (
                <ListItem>
                    <ListItemIcon>
                        <Avatar>{stringInitials(memberName || "")}</Avatar>
                    </ListItemIcon>
                    <ListItemText>{memberName}</ListItemText>
                    {member.role !== PoolRole.Owner && (
                        <LoadingButton
                            color="error"
                            onClick={handleRemove}
                            disabled={loading}
                            loading={loading}
                        >
                            {translator("remove")}
                        </LoadingButton>
                    )}
                </ListItem>
            )}
            {roles.map((role) => (
                <ListItem key={role.value}>
                    <Box
                        component="label"
                        sx={{
                            display: "flex",
                            alignItems: "flex-start",
                        }}
                    >
                        <Radio
                            checked={value === role.value}
                            onChange={handleChange}
                            value={role.value}
                        />

                        <ListItemText
                            primary={translator(role.label)}
                            secondary={translator(role.description)}
                        />
                    </Box>
                </ListItem>
            ))}
        </List>
    </>
);

export default RolePicker;
