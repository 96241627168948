import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { Provider as StoreProvider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import I18nProvider from "./i18n/provider";

import { store } from "./store";
import { LOCALES } from "./i18n";
import "./styles/main.scss";
import { ErrorBoundary } from "./ErrorBoundary";
import config from "./configs";
import { BrowserRouter } from "react-router-dom";

declare global {
    interface Window {
        mfaVerifier: any;
        recaptchaVerifier: any;
        confirmationResult: any;
    }
}

if (window.location.hostname !== "localhost" && config.sentry?.dsn) {
    if (config.environment === "dev") {
        console.log(`Initializing Sentry. Env: ${config.environment} rate: ${config.sentry.transactionSampleRate}`);
    }

    Sentry.init({
        dsn: config.sentry.dsn,
        environment: config.environment,
        integrations: [new BrowserTracing()],
        autoSessionTracking: true,
        tracesSampleRate: config.sentry.transactionSampleRate ?? 1.0,
    });
}

const element = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(element);

root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <I18nProvider locale={LOCALES.ENGLISH}>
                <StoreProvider store={store}>
                    <CookiesProvider>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </CookiesProvider>
                </StoreProvider>
            </I18nProvider>
        </ErrorBoundary>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
