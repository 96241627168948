import {
    Alert,
    AppBar,
    Box,
    Container,
    Divider,
    FormGroup,
    IconButton,
    Link,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { ROUTES } from "../routes";
import { useAuthenticationStyles } from "../hooks";
import { useAuth } from "../services";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { translator } from "../i18n/translator";

import AuthTextField from "../components/AuthEmailInput";
import AuthPasswordInput from "../components/AuthPasswordInput";
import { LogoHorizontal } from "../icons";

const RegisterPassword: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<string>("");

    const navigate = useNavigate();
    const { user } = useAuth();

    const styles = useAuthenticationStyles();

    useEffect(() => {
        const search = new URLSearchParams(window.location.search);
        const isAssisted = search.has("assistedLoginReqId");

        if (user) {
            navigate(ROUTES.HOME, { replace: true });
        } else if (user && isAssisted) {
            const searchString = search.toString();
            const REGISTER_ROUTE = `${ROUTES.REGISTER}?${searchString}`;
            navigate(REGISTER_ROUTE, { replace: true });
        }
    }, [user, navigate]);

    useEffect(() => {
        document.title = "Register - Group Photo";
    }, []);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();

            const formData = new FormData(event.currentTarget);
            const email = formData.get("email") as string;
            const password = formData.get("password") as string;

            if (!email || !password) return;

            setLoading(true);

            await createUserWithEmailAndPassword(auth, email, password);
        } catch (e: any) {
            setLoading(false);
            setError(`firebaseError.${e.code}`);
            console.error(e);
        }
    };

    const onTogglePasswordVisibility = () => {
        setShowPassword((p) => !p);
    };

    return (
        <Box sx={styles.wrapperStyle()}>
            <AppBar position="static" sx={styles.appBarStyle()}>
                <Toolbar>
                    <IconButton component={RouterLink} to={ROUTES.REGISTER} sx={{ marginRight: 3 }}>
                        <ArrowBackIcon color="primary" />
                    </IconButton>

                    <LogoHorizontal style={{ width: "150px" }} />

                    <Box sx={{ flexGrow: 1 }}></Box>

                    <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                        <Link href="https://groupphoto.com/legal/terms-of-service" target="_blank" rel="nofollow">
                            {translator("terms_of_service")}
                        </Link>
                        <Link href="https://groupphoto.com/legal/privacy-policy" target="_blank" rel="nofollow">
                            {translator("privacy_policy")}
                        </Link>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Container maxWidth="sm">
                <Box
                    component="form"
                    onSubmit={onSubmit}
                    sx={{
                        mt: 5,
                        "& .MuiTextField-root": { mb: 2 },
                    }}
                >
                    <Typography variant="h5" sx={styles.passwordHeadingStyle()}>
                        {translator("register.password")}
                    </Typography>

                    {error && (
                        <Alert severity="error" sx={{ mx: "auto", mb: 2, width: "298px" }}>
                            {translator(error)}
                        </Alert>
                    )}

                    <FormGroup sx={{ alignItems: "center", padding: "12px" }}>
                        <AuthTextField required autoFocus name="email" type="email" label="Email" />

                        <AuthPasswordInput
                            showPassword={showPassword}
                            onToggleVisibility={onTogglePasswordVisibility}
                        />

                        <Box sx={{ mt: 4, mb: 3 }}>
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    color: "#627796",
                                }}
                            >
                                {translator("auth.footer", {
                                    link: (
                                        <Box component="span" sx={{ display: "block" }}>
                                            <Link
                                                href="https://groupphoto.com/legal/terms-of-service"
                                                target="_blank"
                                                rel="nofollow"
                                            >
                                                {translator("auth.footerProtect")}
                                            </Link>
                                        </Box>
                                    ),
                                })}
                            </Typography>
                        </Box>

                        <LoadingButton
                            variant="contained"
                            type="submit"
                            loading={loading}
                            disableElevation
                            sx={{
                                maxWidth: "298px",
                                width: "100%",
                                height: "48px",
                            }}
                        >
                            {translator("continue")}
                        </LoadingButton>
                    </FormGroup>
                </Box>
            </Container>
        </Box>
    );
};

export default RegisterPassword;
