import { LOCALES } from "../locales";

const TRANSLATIONS = {
    [LOCALES.ENGLISH]: {
        signup: "Sign up",
        login: "Log in",
        logout: "Log out",
        email: "Email",
        password: "Password",
        phone: "Phone",
        terms_of_service: "Terms of Service",
        privacy_policy: "Privacy Policy",
        continue: "Continue",
        back: "Back",
        you: "You",
        done: "Done",
        modalTitle: '"Group Photo" would like to access your Contacts',
        modalDescription:
            "Group Photo syncs only phone numbers from your address book to Group Photo servers to help you connect with other Group Photo users",
        suggested: "Suggested",
        choose_color: "Choose Color",
        choose_color_instruction: "Select a custom color by dragging the handle around the ring",
        activity: "Activity",
        rate: "Rate",
        select: "Select",
        color: "Color",
        archive: "Archive",
        confirm: "Confirm",
        "archive.confirm": "Are you sure to archive {name}?",
        save: "Save",
        saveChanges: "Save Changes",
        yes: "Yes",
        no: "No",
        or: "or",
        add: "Add",
        name: "Name",
        username: "Username",
        accept: "Accept",
        decline: "Decline",
        update: "Update",
        state: "State",
        zip: "Zip Code",
        upgrade: "Upgrade",
        processing: "Processing",
        all: "All",
        here: "here",

        owner: "Owner",
        remove: "Remove",
        contributor: "Contributor",
        viewer: "Viewer",
        moderator: "Moderator",
        voter: "Voter",
        inviteByEmail: "Invite by Email",
        inviteToPool: "Invite to pool",
        sendInvite: "Send Invite",
        inviteToPoolDynamic: "Invite to {name}",
        participant: "Participant",
        votingParticipant: "Voting Participant",
        viewerWithInvites: "Viewer with invites",
        cannotInviteSelf: "Unable to invite your email",

        free: "Free",
        standard: "Standard",
        plan: "Plan",

        cancel: "Cancel",
        delete: "Delete",
        edit: "Edit",
        next: "Next",
        loading: "Please wait...",

        contacts: "Contacts",
        timeline: "Timeline",
        cluster: "Cluster",
        chat: "Chat",
        chats: "Chats",

        okay: "Okay",
        storage: "Storage",

        selected: "Selected",
        options: "Options",
        help: "Help",

        backToHomepage: "Back to pools",

        appearance: "Appearance",

        learnMore: "Learn more",

        checkout: "Checkout",

        preferences: "Preferences",
        "user.settings": "User Settings",
        "user.preferences": "User Preferences",
        "user.currentPlan": "Your workspace is currently on Group Photo's Standard Plan. {link}.",

        "theme.toggle": "Toggle {mode} theme",
        "theme.light": "Light",
        "theme.dark": "Dark",
        "theme.systemDefault": "System Default",

        "loader.fetching": "Fetching your pools and data",
        "loader.redirect": "Preparing your photos",

        "email.required": "Email is required",
        "email.invalid": "Email  address is not valid.",
        "email.invited": "{email} has been invited",
        "phone.required": "Phone is required",
        "phone.invalid": "Phone number is not valid.",
        "phone.min": "Phone must have at least 10 characters",
        "phone.number": "Phone Number",
        "phone.unavailable": "You haven't added a phone number yet.",

        "login.welcome": "Sharing what matters.",
        "login.google": "Log in with Google",
        "login.facebook": "Log in with Facebook",
        "login.email": "Email me a link",
        "login.apple": "Log in with Apple",
        "login.password": "Log in with Email",
        "login.phone": "Log in with Phone",
        "login.redirectText": "Don't have an account? {link}",
        "login.enroll": "Enter number for 2FA",

        "auth.forgot": "Forgot Password?",
        "auth.modal.checkYourInbox": "Check your inbox",
        "auth.modal.verification": "An email with a verification link has",
        "auth.enterCode": "Enter Code",
        "auth.modal.emailAddress": "been sent to {address}",
        "auth.footer": "We care about your privacy. {link}",
        "auth.footerProtect": "How we protect your data",
        "auth.resend": "Resend Email",
        "auth.signOut": "Sign out of Group Photo",

        "register.welcome": "Sharing what matters.",
        "register.google": "Sign up with Google",
        "register.facebook": "Sign up with Facebook",
        "register.email": "Email me a link",
        "register.apple": "Sign up with Apple",
        "register.password": "Sign up with Email",
        "register.phone": "Sign up with Phone",
        "register.redirectText": "Already have an account? {link}",
        "register.enroll": "Enroll number for 2FA",

        "nav.pools": "Pools",
        "nav.members": "Members",
        "nav.inbox": "Inbox",
        "nav.me": "Me",

        "filters.search": "Search Filters",
        "filters.sort": "Sort By",

        "activities.activities": "Activity Feeds",

        "contacts.contacts": "Contacts",
        "contacts.invite": "Invite Contacts",

        "pools.empty": "Your search did not match any pools",
        "pools.mine": "Your pools",
        "pools.pools": "Pools",
        "pools.new": "New pool",
        "pools.create": "Create New Pool",
        "pools.createAPool": "Create a Pool",
        "pools.home": "Home",
        "pools.select_pools": "Select pools",
        "pools.num_selected_pools": "{count} pools selected",
        "pools.invite_contacts": "Invite Contacts",
        "pools.invite_from_contacts": "Invite from Contacts",
        "pools.frequent_contacts": "Frequent Contacts",
        "pools.poolChat": "Pool chat",
        "pools.galleryActions": "Pool Actions",
        "pools.shareToRings": "Share to Rings",
        "pools.addMedia": "Add Media",
        "pools.poolSettings": "Pool Settings",
        "pools.managePool": "Manage pool",
        "pools.sharing": "Sharing",
        "pools.descriptionOptional": "Description (Optional)",
        "pools.archive": "Archive pool",
        "pools.updated": "Pool Updated",
        "pools.viewModeClustered": "Clustering Gallery",
        "pools.viewModeTimeline": "Timeline Gallery",
        "pools.addToPool": "Add to Pool",
        "pools.updateTakenAt": "Update Taken At",
        "pools.editDateTime": "Edit date & time",
        "pools.addPhotos": "Add {count} photos",
        "pools.keyboardShortcuts": "Keyboard shortcuts",
        "pools.removeFromPool": "Remove from {poolName} pool?",
        "pools.reprocessAssets": "Reprocess Assets",

        "pools.notFoundMessage": "The pool you're trying to access can't be found. Click {link} to go to your pools.",

        "assets.empty":
            "There are no photos in this pool yet. Please {link} one of the Group Photo apps to get started.",
        "assets.downloadsOne": "Download",

        rings: "Rings",

        invite: "Invite",
        "invite.emailPhone": "Email",
        "invite.copyShare": "Copy & Share link",
        "invite.invited": "You have been invited to {title}",
        "invite.invitedByUser": "{user} have invited you to {title}",
        "invite.invalidInvitation": "Expired or Invalid Invitation Code",
        "invite.invitePeople": "Invite people to Group Photo",

        members: "Members",
        "members.members": "Members",
        "members.invite": "Invite Family and Friends",
        "members.set_permissions": "Set Permissions",

        "inbox.inbox": "Inbox",
        "inbox.delete": "{user} deleted photo from {pool}",
        "inbox.added_single": "{user} added a photo to {pool}",
        "inbox.added_bulk": "{user} added {number} photos to {pool}",

        "account.account": "My Group Photo Account",
        "account.myAccount": "My Account",
        "account.yourProfile": "Profile",
        "account.archive": "Archive",
        "account.refer": "Refer Friends",
        "account.help": "Help & Support",
        "account.feedback": "Leave Feedback",
        "account.type": "Account type",
        "account.spaceUsed": "Space used",
        "account.expandedSpaceUsed": "{used} / {total} used",
        "account.upgrade": "Upgrade to Standard Plan",
        "account.archivedPools": "Archived Pools",
        "account.confirm-delete-account": "Yes, Delete.",
        "account.req-delete-account": "Delete My Account",
        "account.req-account-deletion": "Request Deletion",
        "account.req-account-deletion-success": "Request Submitted Successfully",
        "account.req-account-deletion-failed": "Error Submitting Request",
        "account.req-account-deletion-desc":
            "Are you sure you want to delete your account?  We will notify you via email once your request has been reviewed.",
        "account.req-account-deletion-desc-success": "We've sent a confirmation request to your email.",
        "account.cancel-delete-account": "No, Cancel.",
        "account.deletedMedia": "Deleted Media",
        "account.unarchive": "Unarchive",
        "account.media": "Media",
        "account.backup": "Backup",
        "account.backupStorage": "Backup & Storage",
        "account.backupSettings": "Backup Settings",
        "account.backupDigest": "Backup Digest",
        "account.backupDigestSettings": "Backup Digest Settings",
        "account.backupHistory": "Backup History",
        "account.upload_avatar": "Upload new picture",
        "account.storage": "{used} of {total}",
        "account.viewBackupHistory": "View backup history",
        "account.contactSupport": "Contact Support",
        "account.deleteDescription": "Please confirm through the link sent to your email.",
        "account.deleteConfirm": "Yes, proceed",
        "account.cancelDelete": "Cancel Deletion",
        "account.requestDelete": "Request Deletion",
        "account.deleteAccountDesc":
            "After your deletion request has been processed (in 7 business days), all data will be permanently destroyed.",
        "account.cancelDeleteDesc":
            "All of your account data will be permanently deleted, starting in 7 business days.",
        "account.deleteCancel": "No, go back",
        "account.restoreDays":
            "You have up to 7 days to restore and use your Group Photo account. After that period, deletion is irreversible.",
        "account.confirmDeletion": "Confirm Deletion",
        "account.deleteAccount": "Delete Account",
        "account.accountDetails": "Account Details",
        "account.yourInfo": "Your Info",
        "account.deleteAccountReqConf": "Account Deletion Request",

        "account.deletionCancelled": "Account Deletion Cancelled",
        "account.accountRestored": "You may continue using your Group Photo account.",

        "upload.profilePhoto": "Upload Profile Photo",
        "upload.choose": "Choose From Library",
        "upload.take_photo": "Take photo or video",

        "backup.backupAll": "Backup my entire library now.",
        "backup.backup100": "Only backup 100 photos, for now, so I can play.",
        "backup.backupNow": "Only backup photos that I take from here on out, for now.",
        "backup.dontBackup": "Don't backup from this device.",

        "digest.description":
            "Please let us know how often you'd like to receive a quick summary of your backup progress.",
        "digest.emailSend": "We'll email you updates at {email}",
        "digest.totalSize": "Total Size",
        "digest.photosUploaded": "Photos Uploaded",
        "digest.frequency.daily": "Daily",
        "digest.frequency.weekly": "Every Week",
        "digest.frequency.every3Days": "Every 3 days",
        "digest.frequency.noUpdates": "No updates",
        "digest.yourStorage": "Your Storage",

        subscription: "Subscription",
        "subscription.cancelledDesc": "Your subscription has been cancelled.",
        "subscription.changePlan": "Change Plan",
        "subscription.save25": "Save 25%",
        "subscription.saveYearly": "save {price} switching to yearly",
        "subscription.priceYearly": "{price} annually",
        "subscription.cardNumber": "Card Number",
        "subscription.expiryDate": "Expiry Date",
        "subscription.securityCode": "Security Code",
        "subscription.yourPlan": "Your Plan",
        "subscription.payment": "Payment",
        "subscription.updatePayment": "Update Payment",
        "subscription.nextBilling": "Your plan will renew on {date} for {amount}.",
        "subscription.card": "{brand} ending in {last4}",
        "subscription.cardExpiry": "Expires: {month}/{year}",
        "subscription.cancel": "Cancel Subscription",
        "subscription.cancelHeadline": "We're sad to see you go :(",
        "subscription.requestCancellation": "Request Cancellation",
        "subscription.confirmCancellation": "Are you sure you want to cancel your subscription?",
        "subscription.terms":
            "By purchasing and switching your plan to Group Photo Standard, you authorize Group Photo to automatically charge you each month until you cancel. If the price changes, we’ll notify you beforehand. You can check your renewal date or cancel anytime via your Account page. No partial refunds. {url} terms apply.",

        "memberRoles.description.contributor": "Can add/remove media. Cannot manage the pool",
        "memberRoles.description.viewer": "Can only view media and chat. Cannot edit the pool",
        "memberRoles.description.owner":
            "Can assign a role to members. Has the privilege to create, add, delete and rate photos. They also can remove other members",
        "memberRoles.description.moderator":
            "Remove other members, manage members, photos, cannot modify pool settings",
        "memberRoles.description.votingParticipant": "Can chat and rate",
        "memberRoles.description.participant": "Can only chat",
        "memberRoles.description.voter": "Can only rate",
        "memberRoles.description.viewerWithInvites": "View only but can invite people",
        "memberRoles.description9": "Show advanced permissions",
        "memberRoles.description10": "Can add photos, chat",
        "memberRoles.description11": "View only",

        "pricing.groupphoto": "Standard Plan Pricing",
        "pricing.fullFunctionality": "Full Functionality",
        "pricing.noAds": "No Ads",
        "pricing.twoGB": "2 GB Storage",
        "pricing.1TB": "1 TB Storage",
        "pricing.2TB": "2 TB Storage",
        "pricing.3TB": "3 TB Storage",
        "pricing.4TB": "4 TB Storage",
        "pricing.5TB": "5 TB Storage",
        "pricing.6TB": "6 TB Storage",
        "pricing.7TB": "7 TB Storage",
        "pricing.8TB": "8 TB Storage",
        "pricing.9TB": "9 TB Storage",
        "pricing.10TB": "10 TB Storage",
        "pricing.additionalTB": "Additional TB Storage",

        monthly: "Monthly",
        yearly: "Yearly",
        "monthly.short": "mo",
        "yearly.short": "yr",

        "placeholder.birthday": "Ex. My happiest birthday",
        "placeholder.poolName": "Pool Name",
        "placeholder.poolDescription": "Pool Description",
        "placeholder.searchMembers": "Search Members",
        "placeholder.streamTitle": "Stream Title",

        streams: "Streams",
        stream: "Stream",
        "stream.ratings": "Ratings",
        "stream.fromPools": "From pools",
        "stream.withRatings": "With Ratings",
        "stream.mediaAddedBy": "Media Added By",

        "keyboard.description.up": "Goes up one level",
        "keyboard.description.right": "Move to the next set of photos",
        "keyboard.description.left": "Move to the previous set of photos",
        "keyboard.description.esc": "Closes the media viewer",
        "keyboard.description.ctrlClick": "Open the cluster's keystone photo directly in the media viewer",

        storybook: "Storybook",
        storybooks: "Storybooks",
        "storybook.create": "Create a Storybook",
        "storybook.start": "Start",
        "storybook.end": "End",
        "storybook.monthly": "Create storybook by each month",
        "storybook.yearly": "Create storybook by each year",

        "firebaseError.auth/wrong-password": "Invalid email address or password.",
        "firebaseError.auth/user-not-found": "Invalid account.",
        "firebaseError.auth/invalid-email": "Invalid email address.",
        "firebaseError.auth/weak-password": "Password should be at least 6 characters.",
        "firebaseError.auth/email-already-in-use": "Account already exists.",
        "firebaseError.auth/popup-closed-by-user": "Authentication popup has been interrupted.",
        "firebaseError.auth/too-many-requests": "Too many failed attempts. Please try again later.",
    },
};

export default TRANSLATIONS;
