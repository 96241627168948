import { Link } from "react-router-dom";

import { AppBar, Avatar, Box, IconButton, Toolbar, Typography } from "@mui/material";

import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import SettingsIcon from "@mui/icons-material/Settings";
import { ROUTES } from "../../../routes";

const MainSidebarFooter = ({ mode, displayName, initials, onToggleTheme }) => (
    <AppBar
        position="static"
        color="transparent"
        sx={{
            height: "52px",
            backgroundImage: "none",
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            boxShadow: "none",
        }}
    >
        <Toolbar variant="dense" disableGutters>
            <Box
                component={Link}
                to={ROUTES.ACCOUNT}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    pl: 2,
                    color: (theme) =>
                        theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,

                    textDecoration: "none",
                }}
            >
                <Avatar
                    sx={{
                        width: "32px",
                        height: "32px",
                        fontSize: 14,
                        textDecoration: "none",
                    }}
                    children={initials}
                />

                <Typography ml={1} fontSize={14}>
                    {displayName}
                </Typography>
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={onToggleTheme}>{mode === "dark" ? <DarkModeIcon /> : <LightModeIcon />}</IconButton>
            <IconButton component={Link} to={ROUTES.ACCOUNT}>
                <SettingsIcon sx={{ width: "20px", height: "20px" }} />
            </IconButton>
        </Toolbar>
    </AppBar>
);

export default MainSidebarFooter;
