import {
    ArrowBack as ArrowBackIcon,
    ArrowForward as ArrowForwardIcon,
    ArrowUpward as ArrowUpwardIcon,
    Home as HomeIcon,
} from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";

const GalleryBottomNavigation = ({
    handleFetchPrev,
    handleFetchRoot,
    handleFetchReturn,
    handleFetchNext,
    isTimeline,
    title,
    galleryHasLoaded,
}) => {
    return (
        <BottomNavigation showLabels sx={{ flex: "0 0 40px", justifyContent: "space-evenly" }}>
            <BottomNavigationAction
                label="Previous"
                icon={<ArrowBackIcon />}
                disabled={!galleryHasLoaded}
                onClick={handleFetchPrev}
                sx={{ py: 2 }}
            />

            {!isTimeline && (
                <BottomNavigationAction
                    label={title}
                    icon={<HomeIcon />}
                    disabled={!galleryHasLoaded}
                    onClick={handleFetchRoot}
                    sx={{ py: 2 }}
                />
            )}

            {!isTimeline && (
                <BottomNavigationAction
                    label="Up"
                    icon={<ArrowUpwardIcon />}
                    disabled={!galleryHasLoaded}
                    onClick={handleFetchReturn}
                    sx={{ py: 2 }}
                />
            )}

            <BottomNavigationAction
                label="Next"
                icon={<ArrowForwardIcon />}
                disabled={!galleryHasLoaded}
                onClick={handleFetchNext}
                sx={{ py: 2 }}
            />
        </BottomNavigation>
    );
};

export default GalleryBottomNavigation;
