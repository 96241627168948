import { memo } from "react";

import { List, ListItem } from "@mui/material";
import { ArrowBack, ArrowForward, ArrowUpward } from "@mui/icons-material";

import KeyboardListSubheader from "./KeyboardListSubheader";
import KeyboardListItemText from "./KeyboardListItemText";
import KeyboardListPrimaryText from "./KeyboardListPrimaryText";
import KeyboardButtonStack from "./KeyboardButtonStack";
import KeyboardTile from "./KeyboardTile";

const KeyboardClusterList = () => (
    <List subheader={<KeyboardListSubheader>Cluster Gallery</KeyboardListSubheader>}>
        <ListItem disableGutters>
            <KeyboardListItemText
                primary={<KeyboardListPrimaryText>Navigate to more recent media</KeyboardListPrimaryText>}
                secondary={
                    <KeyboardButtonStack>
                        <KeyboardTile>
                            <ArrowForward sx={{ width: "15px" }} />
                        </KeyboardTile>
                    </KeyboardButtonStack>
                }
            />
        </ListItem>
        <ListItem disableGutters>
            <KeyboardListItemText
                primary={<KeyboardListPrimaryText>Navigate to older media</KeyboardListPrimaryText>}
                secondary={
                    <KeyboardButtonStack>
                        <KeyboardTile>
                            <ArrowBack sx={{ width: "15px" }} />
                        </KeyboardTile>
                    </KeyboardButtonStack>
                }
            />
        </ListItem>
        <ListItem disableGutters>
            <KeyboardListItemText
                primary={<KeyboardListPrimaryText>Return to parent cluster</KeyboardListPrimaryText>}
                secondary={
                    <KeyboardButtonStack>
                        <KeyboardTile>
                            <ArrowUpward sx={{ width: "15px" }} />
                        </KeyboardTile>
                    </KeyboardButtonStack>
                }
            />
        </ListItem>
        <ListItem disableGutters>
            <KeyboardListItemText
                primary={<KeyboardListPrimaryText>Return to beginning</KeyboardListPrimaryText>}
                secondary={
                    <KeyboardButtonStack>
                        <KeyboardTile>ESC</KeyboardTile>
                    </KeyboardButtonStack>
                }
            />
        </ListItem>
    </List>
);

export default memo(KeyboardClusterList);
