import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Check } from "@mui/icons-material";
import { translator } from "../i18n/translator";

const colors = [
    { color: "#1d92ff", name: "dodger-blue" },
    { color: "#64C9e9", name: "sky-blue" },
    { color: "#64e9c9", name: "teal" },
    { color: "#8e64e9", name: "purple" },
    { color: "#cab3fb", name: "lavender" },
    { color: "#f27620", name: "tango" },
    { color: "#faab34", name: "tango-light" },
    { color: "#fab907", name: "yellow" },
    { color: "#f2acae", name: "pink" },
    { color: "#e94d52", name: "red" },
    { color: "#64e999", name: "light-green" },
    { color: "#15cc69", name: "green" },
    { color: "#727272", name: "grey" },
    { color: "#424242", name: "dark-grey" },
];

interface Props {
    value: string;
    disabled?: boolean;
    onSelect: any;
}

const ColorSwatch: React.FC<Props> = ({ value, disabled, onSelect }) => {
    const onColorClick = (value: string) => {
        return () => onSelect(value);
    };

    return (
        <Box>
            <Typography>{translator("choose_color")}</Typography>
            <Stack direction="row" flexWrap="wrap">
                {colors.map((content) => (
                    <IconButton
                        key={content.name}
                        disabled={disabled}
                        onClick={onColorClick(content.color)}
                    >
                        <Box
                            component="span"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "32px",
                                height: "32px",
                                borderRadius: "16px",
                                bgcolor: content.color,
                            }}
                            children={
                                value === content.color ? (
                                    <Check sx={{ color: "#fff" }} />
                                ) : null
                            }
                        />
                    </IconButton>
                ))}
            </Stack>
        </Box>
    );
};

export default ColorSwatch;
