import { Box, List, ListItem, ListItemButton, ListItemText } from "@mui/material";

import { generatePath, NavLink } from "react-router-dom";
import { translator } from "../../../i18n/translator";
import { ROUTES } from "../../../routes";
import { useAppDispatch } from "../../../store";
import { closeMainSidebar } from "../../../store/sidebarSlice";

const StorybooksList = ({ user, storybooks }) => {
    const dispatch = useAppDispatch();

    const closeSidebar = () => {
        dispatch(closeMainSidebar());
    };

    return (
        <List component="nav" disablePadding>
            <Box sx={{ pb: 2 }}>
                <ListItem
                    alignItems="flex-start"
                    sx={{
                        pl: 2,
                        pr: "6px",
                    }}
                >
                    <ListItemText primary={translator("storybooks")} />
                </ListItem>

                {storybooks.length > 0 &&
                    user &&
                    storybooks.map((storybook) => (
                        <ListItemButton
                            key={storybook.id}
                            component={NavLink}
                            title={storybook.title}
                            to={generatePath(ROUTES.STORYBOOK, { id: storybook.id })}
                            sx={{
                                py: 0,
                                minHeight: 32,
                                color: (theme) =>
                                    theme.palette.mode === "dark"
                                        ? "rgba(255,255,255,0.4)"
                                        : theme.palette.common.black,
                                "& .MuiTypography-root": {
                                    fontSize: 14,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                },
                                "&.active .MuiTypography-root": {
                                    color: (theme) =>
                                        theme.palette.mode === "dark"
                                            ? theme.palette.common.white
                                            : theme.palette.common.black,
                                    fontWeight: "900",
                                },
                            }}
                            onClick={closeSidebar}
                        >
                            <ListItemText primary={storybook.title} />
                        </ListItemButton>
                    ))}
            </Box>
        </List>
    );
};

export default StorybooksList;
