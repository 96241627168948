import {
    Box,
    Paper,
    Skeleton,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";

const SkeletonLoader = () => {
    const theme = useTheme();

    return (
        <Paper
            elevation={theme.palette.mode === "light" ? 0 : undefined}
            sx={{ mt: 5, mb: 4, p: 2 }}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box>
                    <Typography variant="h5" color="primary">
                        <Skeleton variant="text" width={50} />
                    </Typography>
                    <Typography display="block" variant="body2">
                        <Skeleton variant="text" width={100} />
                    </Typography>
                </Box>
                <Stack direction="row" spacing={2}>
                    <Skeleton
                        variant="rectangular"
                        width={100}
                        height={36}
                        sx={{
                            borderRadius: "4px",
                        }}
                    />
                </Stack>
            </Box>
        </Paper>
    );
};

export default SkeletonLoader;
