import { useState } from "react";
import { useIntl } from "react-intl";
import { PoolMemberInvitationState, PoolRole } from "groupphoto-models";

import { Box, Button, FormControl, InputAdornment, InputLabel, Menu, OutlinedInput } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useMemberService } from "../../../services";
import { auth } from "../../../firebase";
import { translator } from "../../../i18n/translator";
import RolePicker from "../../RolePicker";

interface Props {
    poolId: string;
    onSubmit?: any;
    onSuccess?: any;
    onError?: any;
}

const InviteMembersForm: React.FC<Props> = ({ poolId, onSubmit: onProcess, onSuccess, onError }) => {
    const [loading, setLoading] = useState(false);
    const [selfInvite, setSelfInvite] = useState(false);
    const [showMoreRoles, setShowMoreRoles] = useState(false);
    const [role, setRole] = useState<PoolRole>(PoolRole.Contributor);

    const [values, setValues] = useState({
        identity: "",
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setValues((v) => ({ ...v, [name]: value }));
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const showRolePicker = Boolean(anchorEl);

    const intl = useIntl();
    const MemberService = useMemberService();

    const toggleRolePicker = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeRolePicker = () => {
        setAnchorEl(null);
    };

    const toggleMoreRoles = () => {
        setShowMoreRoles((moreRoles) => !moreRoles);
    };

    const onRoleChange = (event) => {
        const { value } = event.currentTarget;
        setRole(value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();

            if (loading || !values.identity) return;

            setSelfInvite(false);

            if (values.identity === auth.currentUser?.email) {
                setSelfInvite(true);
                setLoading(false);
                return;
            }

            if (onProcess) onProcess({ ...values, role });
            const identity = values.identity;
            const assignedRole = role;

            setRole(PoolRole.Contributor);
            setValues({ identity: "" });

            const result = await MemberService.inviteUsersToPool({
                state: PoolMemberInvitationState.New,
                invitee: {
                    identity: identity,
                    role: assignedRole,
                },
                poolId,
            });

            alert(result.message);
            console.log(result);

            if (onSuccess) onSuccess();
        } catch (error) {
            setLoading(false);
            console.error(error);

            if (onError) onError();
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit}>
            <Box sx={{ display: "flex" }}>
                <FormControl sx={{ mr: 1, flexGrow: 1 }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">{intl.formatMessage({ id: "email" })}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type="email"
                        name="identity"
                        value={values.identity}
                        onChange={handleChange}
                        required
                        endAdornment={
                            <InputAdornment position="end">
                                <Button onClick={toggleRolePicker} endIcon={<ExpandMoreIcon />}>
                                    {translator(role)}
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={showRolePicker}
                                    onClose={closeRolePicker}
                                    anchorOrigin={{
                                        horizontal: "right",
                                        vertical: "bottom",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    PaperProps={{
                                        sx: {
                                            width: "100%",
                                            maxWidth: "335px",
                                            minHeight: "350px",
                                        },
                                    }}
                                >
                                    <RolePicker
                                        value={role}
                                        showMoreRoles={showMoreRoles}
                                        handleChange={onRoleChange}
                                        handleShowMoreRoles={toggleMoreRoles}
                                    />
                                </Menu>
                            </InputAdornment>
                        }
                        label={intl.formatMessage({ id: "email" })}
                    />
                </FormControl>
                <Button variant="contained" color="primary" type="submit" disableElevation disabled={loading}>
                    {translator("sendInvite")}
                </Button>
            </Box>
            {selfInvite && <span className="invalid-message">{translator("cannotInviteSelf")}</span>}
        </Box>
    );
};

export default InviteMembersForm;
