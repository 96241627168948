import { Navigate, useLocation } from "react-router-dom";
import { ROUTES } from "./routes";
import { useAuth } from "./services";

export const PrivateRoute: React.FC<{ children: JSX.Element }> = ({
    children,
}) => {
    const authHook = useAuth();
    const location = useLocation();

    if (!authHook.isFetching && !authHook.user) {
        return (
            <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />
        );
    }

    return children;
};
