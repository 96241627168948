import { createSlice } from "@reduxjs/toolkit";
import { CustomFileItem } from "../lib/interfaces";

interface UploadSliceState {
    showDrawer: boolean;
    files: CustomFileItem[];
}

const initialState: UploadSliceState = {
    showDrawer: false,
    files: [],
};

export const uploadSlice = createSlice({
    name: "fileUpload",
    initialState,
    reducers: {
        addFiles: (state, { payload }) => {
            if (state.files.find((file) => file.id === payload.id))
                return state;

            return { ...state, files: [...state.files, ...payload] };
        },
        updateItemProgress: (state, { payload }) => {
            const updatedFiles = state.files.map((file) => {
                if (file.id !== payload.id) {
                    return file;
                }

                return {
                    ...file,
                    progress: payload.progress,
                };
            });

            return {
                ...state,
                files: updatedFiles,
            };
        },
        openUploadDrawer: (state) => {
            return { ...state, showDrawer: true };
        },
        closeUploadDrawer: (state) => {
            return { ...state, showDrawer: false };
        },
        clearFiles: () => initialState,
    },
});

export const {
    addFiles,
    clearFiles,
    updateItemProgress,
    openUploadDrawer,
    closeUploadDrawer,
} = uploadSlice.actions;

export default uploadSlice.reducer;
