import {
    DocumentData,
    QueryDocumentSnapshot,
    SnapshotOptions,
} from "firebase/firestore";
import { ModifiedUserPool } from "../lib/interfaces";

const userPoolConverter = {
    toFirestore(pool: any): DocumentData {
        return {
            id: pool.id,
            userId: pool.userId,
            createdByUserId: pool.createdByUserId,
            title: pool.title,
            colorTag: pool.colorTag,
            description: pool.description,
            tags: pool.tags,
            type: pool.type,
            defaultRole: pool.defaultRole,
            isArchived: pool.isArchived,
        };
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions) {
        const data = snapshot.data(options);

        if (data) {
            return {
                id: data.id || snapshot.id,
                poolId: data.poolId,
                userId: data.userId,
                createdByUserId: data.createdByUserId,
                title: data.title,
                colorTag: data.colorTag,
                description: data.description,
                myTitle: data.myTitle,
                myColorTag: data.myColorTag,
                myDescription: data.myDescription ?? null,
                tags: data.tags,
                type: data.type,
                defaultRole: data.defaultRole,
                isArchived: data.isArchived,
                createdAt: data.createdAt.toDate().getTime(),
                updatedAt: data.updatedAt.toDate().getTime(),
                timelineYearMonths: data.timelineYearMonths,
            } as ModifiedUserPool;
        }

        return null;
    },
};

export default userPoolConverter;
