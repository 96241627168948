import { Box } from "@mui/material";

const GalleryGrid: React.FC<React.PropsWithChildren> = ({ children }) => (
    <Box
        display="grid"
        gridTemplateColumns={{
            xs: "repeat(2, 1fr)",
            md: "repeat(4, 1fr)",
        }}
        gridTemplateRows={{
            xs: "repeat(4, 1fr)",
            md: "repeat(2, 1fr)",
        }}
        gap={1}
        sx={{
            width: "100%",
            padding: 1,
            overflowY: "auto",
            "@media (orientation: landscape)": {
                gridTemplateColumns: "repeat(4, 1fr) !important",
                gridTemplateRows: "repeat(2, 1fr) !important",
            },
        }}
    >
        {children}
    </Box>
);

export default GalleryGrid;
