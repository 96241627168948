import {
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useIntl } from "react-intl";

interface Props {
    showPassword: boolean;
    onToggleVisibility: VoidFunction;
}

const AuthPasswordInput: React.FC<Props> = ({
    showPassword,
    onToggleVisibility,
}) => {
    const intl = useIntl();
    const passwordText = intl.formatMessage({ id: "password" });

    return (
        <FormControl
            variant="outlined"
            sx={{ maxWidth: "298px", width: "100%" }}
        >
            <InputLabel>{passwordText} *</InputLabel>
            <OutlinedInput
                required
                name="password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton onClick={onToggleVisibility} edge="end">
                            {showPassword ? (
                                <VisibilityOffIcon />
                            ) : (
                                <VisibilityIcon />
                            )}
                        </IconButton>
                    </InputAdornment>
                }
                label={`${passwordText} *`}
            />
        </FormControl>
    );
};

export default AuthPasswordInput;
