import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import breadcrumbReducer from "./breadcrumbSlice";
import storybookReducer from "./storybookSlice";
import poolReducer from "./poolSlice";
import prefetchReducer from "./prefetchSlice";
import selectionReducer from "./selectionSlice";
import sidebarReducer from "./sidebarSlice";
import themeModeReducer from "./themeModeSlice";
import uploadReducer from "./uploadSlice";
import userReducer from "./userSlice";

export const store = configureStore({
    reducer: {
        breadcrumb: breadcrumbReducer,
        files: uploadReducer,
        user: userReducer,
        storybook: storybookReducer,
        pools: poolReducer,
        prefetch: prefetchReducer,
        selection: selectionReducer,
        sidebars: sidebarReducer,
        themeMode: themeModeReducer,
    },
});

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
