import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@mui/material";
import { useState } from "react";
import { translator } from "../../../i18n/translator";
import { useUserService } from "../../../services";
import CustomDialogTitle from "../CustomDialogTitle";

interface Props {
    open: boolean;
    onClose: VoidFunction;
}

const ConfirmDeleteAccountDialog: React.FC<Props> = ({ open, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState("pend");

    const { requestAccountDeletion } = useUserService();

    const handleToggle = async () => {
        try {
            if (loading) return;

            setLoading(true);
            await requestAccountDeletion();

            setDeleteStatus("success");
        } catch (e: any) {
            setDeleteStatus("failed");
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const description = () => {
        let text = "account.deleteDescription";

        if (deleteStatus === "success") {
            text = "account.req-account-deletion-desc-success";
        } else if (deleteStatus === "failed") {
            text = "account.req-account-deletion";
        }

        return text;
    };

    const deleteAccountButtonCancel = (status: string) => {
        const message =
            deleteStatus === "success" ? "okay" : "account.deleteCancel";

        return (
            <Button disabled={loading} onClick={onClose}>
                {translator(message)}
            </Button>
        );
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <CustomDialogTitle onClose={onClose}>
                {translator("account.deleteAccount")}
            </CustomDialogTitle>
            <DialogContent>
                <DialogContentText>
                    {translator(description())}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {deleteStatus !== "success" && (
                    <LoadingButton
                        color="error"
                        disabled={loading}
                        loading={loading}
                        onClick={handleToggle}
                    >
                        {translator("account.deleteConfirm")}
                    </LoadingButton>
                )}

                {deleteAccountButtonCancel(deleteStatus)}
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDeleteAccountDialog;
