/**
 *
 * Use this file to do some global things when starting up the app
 *
 * Fetch Pools
 * Fetch user data
 * Set some cookies
 *
 */

import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { Collections } from "groupphoto-models";

import { collection, doc, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { getIdToken, onAuthStateChanged, onIdTokenChanged, signInWithCustomToken } from "firebase/auth";

import config from "../configs";
import { analytics, auth, db } from "../firebase";
import { http } from "../http";
import { useAuth, useMemberService } from "../services";

import { useAppDispatch } from "../store";
import { insertMemberRole, insertOwnerData, insertPoolData } from "../store/poolSlice";
import { fetchUserSlice } from "../store/userSlice";

import { PoolConverter } from "../converters";
import { getSearchParams } from "../lib/helpers";
import { logEvent } from "firebase/analytics";
import { insertStorybooks } from "../store/storybookSlice";
import PhotobookConverter from "../converters/PhotobookConverter";
import { insertPrefetchedPool } from "../store/prefetchSlice";

const useSearchParams = <T extends object = any>(): Partial<T> => {
    const [searchParams, setSearchParams] = useState(getSearchParams());

    const condition = typeof window === "undefined" ? "once" : window.location.search;

    useEffect(() => {
        setSearchParams(getSearchParams());

        // WARNING: Complex expression in dependency array
        // Linter suggests to extract to a variable so it can be statically checked.
    }, [condition]);

    return searchParams;
};

const useApp = () => {
    const [, setCookie, removeCookie] = useCookies(["groupphoto", "__session"]);
    const firstTimeUserFetch = useRef<boolean>(true);
    const isResumingSession = useRef<boolean>(false);
    const transferToken = useRef<string | undefined>();

    const { transferToken: ttParam } = useSearchParams();
    transferToken.current = ttParam;

    const authHook = useAuth();
    const { fetchMemberById } = useMemberService();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                logEvent(analytics, "login");
            } else {
                logEvent(analytics, "logout");
            }
        });

        const loginWithTransferToken = async (token) => {
            try {
                isResumingSession.current = true;

                await auth.signOut();

                await signInWithCustomToken(auth, token);
            } catch (e) {
                console.error(`Could not resume session: ${e}`);
            } finally {
                window.location.href = `${window.location.origin}/`;
            }
        };

        if (!isResumingSession.current && transferToken.current) {
            loginWithTransferToken(transferToken.current);
        }

        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        const unsub = onIdTokenChanged(
            auth,
            (user) => {
                if (!user) {
                    removeCookie("__session");
                } else {
                    getIdToken(user, true)
                        .then(async (token) => {
                            http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                            setCookie("__session", token, { path: "/" });

                            if (window.location.hostname !== "localhost") {
                                fetch(`https://${config.domains.cdn}/auth`, {
                                    method: "POST",
                                    mode: "cors",
                                    redirect: "follow",
                                    credentials: "include", // Don't forget to specify this if you need cookies
                                    body: "",
                                    headers: {
                                        authorization: `Bearer ${token}`,
                                    },
                                }).catch((error) => console.error(error));
                            }
                        })
                        .catch((error) => {
                            console.log("waduhek");
                            console.error(error.message);
                        });
                }
            },
            (error) => {
                console.log("something something");
                console.error(error);
            }
        );

        return unsub;
    }, [removeCookie, setCookie]);

    // Fetch and listen to user changes
    useEffect(() => {
        if (!authHook.user) return;

        const q = doc(db, Collections.User, authHook.user.uid);
        const q2 = doc(db, Collections.UserServicePlan, authHook.user.uid);

        const subscription = onSnapshot(q, async (snapshot) => {
            if (!snapshot.exists()) return;
            await dispatch(fetchUserSlice());

            if (firstTimeUserFetch.current) {
                firstTimeUserFetch.current = false;
            }
        });

        const servicePlanSubscription = onSnapshot(q2, (snapshot) => {
            if (!snapshot.exists()) return;
            if (firstTimeUserFetch.current) return;
            dispatch(fetchUserSlice());
        });

        return () => {
            subscription();
            servicePlanSubscription();
        };
    }, [authHook.user, dispatch]);

    // Fetch and listen to user pool
    useEffect(() => {
        if (!authHook.user) return;

        const q = query(
            collection(db, Collections.UserPool),
            where("userId", "==", authHook.user.uid),
            where("isArchived", "==", false),
            orderBy("createdAt", "asc")
        ).withConverter(PoolConverter);

        const unsub = onSnapshot(q, (snapshot) => {
            const fetchedPools = snapshot.docs.map((doc) => doc.data());
            dispatch(insertPoolData({ pools: fetchedPools }));

            // Prefetching
            // Initial snapshot always have "added" type for all docs
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    const pool = change.doc.data();
                    const id = pool?.poolId || pool?.id || "";

                    http.get(`/api/prefetch/pool/${id}`).finally(() => {
                        dispatch(insertPrefetchedPool(id));
                    });
                }
            });

            // Get ownership data
            if (!snapshot.empty) {
                const filteredPools = fetchedPools.filter((p) => {
                    if (!authHook.user) return false;
                    return p?.createdByUserId !== authHook.user.uid;
                });

                const mappedPools = filteredPools.map((p) => {
                    const poolId = p!.poolId || p!.id;
                    return fetchMemberById(poolId, p!.createdByUserId);
                });

                Promise.all(mappedPools).then((results) => {
                    dispatch(insertOwnerData({ owners: results }));
                });

                Promise.all(
                    fetchedPools.map((pool) => {
                        const poolId = pool!.poolId || pool!.id;
                        return fetchMemberById(poolId, pool!.userId)
                            .then((response) => response)
                            .catch((error) => console.error(error));
                    })
                ).then((results) => {
                    dispatch(insertMemberRole({ roles: results }));
                });
            }
        });

        return unsub;
    }, [authHook.user, dispatch, fetchMemberById]);

    // Fetch and listen to photobooks
    useEffect(() => {
        if (!authHook.user) return;

        const q = query(
            collection(db, "photobooks"),
            where("userId", "==", authHook.user.uid),
            orderBy("createdAt", "asc")
        ).withConverter(PhotobookConverter);

        const subscribe = onSnapshot(
            q,
            (snapshot) => {
                if (snapshot.empty) {
                    dispatch(insertStorybooks([]));
                }

                const fetchedPhotobooks = snapshot.docs.map((d) => d.data());
                dispatch(insertStorybooks(fetchedPhotobooks));
            },
            (error) => {
                console.error(error.message);
            }
        );

        return subscribe;
    }, [authHook.user, dispatch]);

    return {};
};

export default useApp;
