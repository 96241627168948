interface Props {
    visible: boolean;
    page: {
        template: {
            slots: number;
            name: string;
        };
        photos: {
            assetId: string;
            sha512Hash: string;
            slot: number;
            renderingUrl: string;
        }[];
    };
}

const Layout: React.FC<Props> = ({ page, visible }) => {
    if (!visible) return null;

    return (
        <div className={`storybook__grid storybook--layout${page.template.name}`}>
            {page.photos.map((photo) => (
                <div key={photo.assetId} className={`slot p${photo.slot}`}>
                    <img src={photo.renderingUrl} alt="Render" />
                </div>
            ))}
        </div>
    );
};

export default Layout;
