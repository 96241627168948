import { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { toggleMainSidebar } from "../../store/sidebarSlice";
import { useAppDispatch, useAppSelector } from "../../store";
import { useCluster, usePool, useTimeline } from "../../hooks";
import { ModifiedAssetSummary, SubCluster } from "../../lib/interfaces";
import { generateAssetSummary } from "../../converters/AssetConverter";

import { AppBar, Box, IconButton, Skeleton, Slider, Toolbar, Typography } from "@mui/material";

import { Close as CloseIcon, Menu as MenuIcon } from "@mui/icons-material";

import GalleryToolbarActions from "./GalleryToolbarActions";
import GalleryToolbarTitle from "./GalleryToolbarTitle";
import GalleryGridItem from "./GalleryGridItem";
import GalleryGridItemCluster from "./GalleryGridItemCluster";
import GalleryBottomNavigation from "./GalleryBottomNavigation";
import GalleryEmptyAssets from "./GalleryEmptyAssets";
import GalleryGridSkeleton from "./GalleryGridSkeleton";
import GalleryToolbarSkeleton from "./GalleryToolbarSkeleton";
import GalleryBreadcrumbs from "./GalleryBreadcrumbs";
import GalleryGrid from "./GalleryGrid";

// import MediaViewer from "../../components/MediaViewer";
import { resetSelection, toggleAssetStack, toggleClusterStack } from "../../store/selectionSlice";
import { ROUTES } from "../../routes";

const GalleryContent: React.FC = () => {
    // Plugin Hooks
    const intl = useIntl();
    const [search] = useSearchParams();

    const selectedAssets = useAppSelector((state) => state.selection.selectedAssets);

    // View Mode Controls
    const gMode = search.get("mode");
    const cond = gMode && gMode === "timeline" ? true : false;
    const [isTimeline, setIsTimeline] = useState(cond);
    const [isRateMode, setIsRateMode] = useState(false);

    const navigate = useNavigate();

    // Selection Controls
    const [isSelectMode, setIsSelectMode] = useState(false);

    // Custom Hooks
    const { pool, updateQueryParams } = usePool();
    const TimelineControls = useTimeline();
    const ClusterControls = useCluster();
    const { onFetchNext: t_onFetchNext, onFetchPrev: t_onFetchPrev } = TimelineControls;
    const { onFetchNext: c_onFetchNext, onFetchPrev: c_onFetchPrev, onFetchRoot, onReturn } = ClusterControls;

    const dispatch = useAppDispatch();

    /**
     * Extract pool title
     *
     * @returns string
     */
    const title = useMemo(() => {
        if (!pool) return <Skeleton variant="text" width={100} />;
        return pool.myTitle || pool.title;
    }, [pool]);

    useEffect(() => {
        if (typeof title !== "string") return;
        document.title = `${title} - Group Photo`;
    }, [title]);

    const isGalleryLoading = useMemo(() => {
        if (isTimeline && TimelineControls.isDoneFetching) {
            return false;
        } else if (!isTimeline && ClusterControls.isDoneFetching) {
            return false;
        } else {
            return true;
        }
    }, [TimelineControls.isDoneFetching, ClusterControls.isDoneFetching, isTimeline]);

    const activeAssets = useMemo(() => {
        if (isTimeline && TimelineControls.assets.length > 0) {
            return TimelineControls.assets;
        } else if (!isTimeline && ClusterControls.cluster) {
            if (ClusterControls.cluster.subclusters.length > 0) {
                const keystoneAssets = ClusterControls.cluster.subclusters.map((subCluster) => ({
                    ...subCluster.keystone_asset,
                }));

                return keystoneAssets.map((asset) => {
                    return generateAssetSummary(asset);
                });
            } else {
                const singularClusterAsset = ClusterControls.cluster.keystone_asset;
                const summary = generateAssetSummary(singularClusterAsset);
                summary.description = ClusterControls.cluster.description;
                return [summary];
            }
        } else {
            return [];
        }
    }, [TimelineControls.assets, ClusterControls.cluster, isTimeline]);

    const handleFetchNext = useCallback(async () => {
        try {
            if (isTimeline) {
                await t_onFetchNext();
            } else {
                await c_onFetchNext();
            }
        } catch (e) {
            console.error(e);
        }
    }, [t_onFetchNext, c_onFetchNext, isTimeline]);

    const handleFetchPrev = useCallback(async () => {
        try {
            if (isTimeline) {
                await t_onFetchPrev();
            } else {
                await c_onFetchPrev();
            }
        } catch (e) {
            console.error(e);
        }
    }, [t_onFetchPrev, c_onFetchPrev, isTimeline]);

    const handleFetchRoot = useCallback(async () => {
        await onFetchRoot();
    }, [onFetchRoot]);

    const handleFetchReturn = useCallback(async () => {
        await onReturn();
    }, [onReturn]);

    // Simple keyboard keys
    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === "ArrowLeft") {
                handleFetchPrev();
            } else if (event.key === "ArrowRight") {
                handleFetchNext();
            } else if (event.key === "ArrowUp" && !isTimeline) {
                handleFetchReturn();
            } else if (event.key === "Escape" && !isTimeline) {
                handleFetchRoot();
            }
        };

        document.addEventListener("keydown", onKeyDown);

        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [activeAssets, isTimeline, handleFetchNext, handleFetchPrev, handleFetchRoot, handleFetchReturn]);

    const handleModeClick = () => {
        updateQueryParams({ mode: !isTimeline ? "timeline" : "cluster" }, true);
        setIsTimeline((t) => !t);
    };

    const activeSubClusters = () => {
        if (!ClusterControls.cluster) return [];

        return ClusterControls.cluster.subclusters;
    };

    const handleDeactivateRateMode = () => {
        setIsRateMode(false);
    };

    const handleDeactivateSelectMode = () => {
        setIsSelectMode(false);
        dispatch(resetSelection());
    };

    const handleToggleRateMode = () => {
        setIsSelectMode(false);
        setIsRateMode((s) => !s);
    };

    const handleToggleSelectMode = () => {
        setIsRateMode(false);
        setIsSelectMode((s) => !s);
        dispatch(resetSelection());
    };

    const handleSliderCommit = async (_, sliderValue) => {
        try {
            await TimelineControls.onSliderCommit(_, sliderValue);
        } catch (e) {
            console.error(e);
        }
    };

    const handleTimelineAssetClick = (event, asset: ModifiedAssetSummary) => {
        if (isSelectMode) {
            event.preventDefault();
            dispatch(toggleAssetStack(asset.id));
        }
    };

    const handleClusterClick = async (event, subCluster: SubCluster, index: number) => {
        if (event.ctrlKey || event.metaKey) {
            event.preventDefault();
            navigate(generatePath(ROUTES.MEDIA_VIEWER_CLUSTER, { id: pool?.id, x: subCluster.x, y: subCluster.y }));
        } else if (subCluster.size === 1 && !isSelectMode) {
            event.preventDefault();
            navigate(generatePath(ROUTES.MEDIA_VIEWER_CLUSTER, { id: pool?.id, x: subCluster.x, y: subCluster.y }));
        } else if (isSelectMode) {
            event.preventDefault();
            dispatch(toggleClusterStack({ name: subCluster.name, x: subCluster.x, y: subCluster.y }));
        }
    };

    const canShowAssets = () => {
        if (isTimeline) {
            return true;
        } else {
            if (ClusterControls.cluster && ClusterControls.cluster.subclusters.length < 1) {
                return true;
            } else {
                return false;
            }
        }
    };

    const canShowClusters = () => {
        if (isTimeline) {
            return false;
        } else {
            if (ClusterControls.cluster && ClusterControls.cluster.subclusters.length > 0) {
                return true;
            } else {
                return false;
            }
        }
    };

    const onToggleMainSidebar = () => {
        dispatch(toggleMainSidebar());
    };

    const galleryHasLoaded = (() => {
        if (pool && !isGalleryLoading && activeAssets.length > 0) return true;
        return false;
    })();

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <AppBar
                position="static"
                color="transparent"
                sx={{
                    boxShadow: "none",
                    borderWidth: "0 0 thin",
                    borderStyle: "solid",
                    borderColor: "rgba(0,0,0,0.2)",
                }}
            >
                <Toolbar variant="dense" disableGutters>
                    {isSelectMode && pool && (
                        <IconButton onClick={handleDeactivateSelectMode} sx={{ ml: 2 }}>
                            <CloseIcon />
                        </IconButton>
                    )}

                    {isRateMode && (
                        <IconButton onClick={handleDeactivateRateMode} sx={{ ml: 2 }}>
                            <CloseIcon />
                        </IconButton>
                    )}

                    {!isSelectMode && !isRateMode && (
                        <IconButton onClick={onToggleMainSidebar} sx={{ display: "inline-flex", ml: 2 }}>
                            <MenuIcon />
                        </IconButton>
                    )}

                    <Box
                        flexGrow={1}
                        px={2}
                        sx={{
                            overflowX: "auto",
                            "&::webkit-scrollbar-track": {
                                boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                                backgroundColor: "#F5F5F5",
                            },
                            "&::-webkit-scrollbar": {
                                width: "2px",
                                backgroundColor: "transparent",
                                height: 0,
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#000000",
                            },
                        }}
                    >
                        {isSelectMode && (
                            <GalleryToolbarTitle>
                                <Typography component="span">
                                    {selectedAssets.length} {intl.formatMessage({ id: "selected" })}
                                </Typography>
                            </GalleryToolbarTitle>
                        )}
                        {!isSelectMode && <GalleryBreadcrumbs pool={pool} isTimeline={isTimeline} />}
                    </Box>

                    {pool && (
                        <GalleryToolbarActions
                            pool={pool}
                            cluster={ClusterControls.cluster}
                            isTimeline={isTimeline}
                            isRateMode={isRateMode}
                            isSelectMode={isSelectMode}
                            onModeClick={handleModeClick}
                            onRateClick={handleToggleRateMode}
                            onSelectClick={handleToggleSelectMode}
                        />
                    )}

                    {!pool && <GalleryToolbarSkeleton />}
                </Toolbar>
            </AppBar>

            {(!pool || isGalleryLoading) && <GalleryGridSkeleton />}

            {!isGalleryLoading && activeAssets.length === 0 && <GalleryEmptyAssets />}

            {galleryHasLoaded && (
                <>
                    <Box sx={{ display: "flex", height: "100%" }}>
                        <GalleryGrid>
                            {canShowAssets() &&
                                activeAssets.map((asset, i) => (
                                    <GalleryGridItem
                                        key={asset.id + `-${i}`}
                                        asset={asset}
                                        memberRole={pool?.role}
                                        isRateMode={isRateMode}
                                        onAssetClick={(event) => handleTimelineAssetClick(event, asset)}
                                    />
                                ))}
                            {canShowClusters() &&
                                activeSubClusters().map((subCluster, i) => (
                                    <GalleryGridItemCluster
                                        key={subCluster.keystone_asset.id + `-${i}`}
                                        memberRole={pool?.role}
                                        isRateMode={isRateMode}
                                        subCluster={subCluster}
                                        onClusterClick={(event) => handleClusterClick(event, subCluster, i)}
                                    />
                                ))}
                        </GalleryGrid>

                        {isTimeline && (
                            <Box sx={{ py: 3 }}>
                                <Slider
                                    step={1}
                                    min={1}
                                    track={false}
                                    orientation="vertical"
                                    valueLabelDisplay="auto"
                                    value={TimelineControls.slider.page}
                                    defaultValue={TimelineControls.slider.totalMonths}
                                    max={TimelineControls.slider.totalMonths}
                                    marks={TimelineControls.slider.marks}
                                    onChange={TimelineControls.onSliderDrag}
                                    onChangeCommitted={handleSliderCommit}
                                    valueLabelFormat={TimelineControls.slider.valueLabelFormat}
                                    getAriaValueText={TimelineControls.slider.valueLabelFormat}
                                    aria-labelledby="vertical-slider"
                                    sx={{
                                        "& .MuiSlider-markLabel": {
                                            fontSize: "12px",
                                        },
                                    }}
                                />
                            </Box>
                        )}
                    </Box>

                    {/* {openMediaViewer && (
                        <MediaViewer
                            assets={activeAssets}
                            subCluster={activeSubCluster()}
                            assetId={galleryState.currentlyViewedAsset}
                            isTimeline={isTimeline}
                            closePreview={handleCloseMediaViewer}
                            onArchiveAsset={handleArchiveAsset}
                            memberRole={pool?.role}
                        />
                    )} */}
                </>
            )}

            <GalleryBottomNavigation
                handleFetchPrev={handleFetchPrev}
                handleFetchRoot={handleFetchRoot}
                handleFetchReturn={handleFetchReturn}
                handleFetchNext={handleFetchNext}
                isTimeline={isTimeline}
                galleryHasLoaded={galleryHasLoaded}
                title={pool?.myTitle || pool?.title}
            />
        </Box>
    );
};

export default GalleryContent;
