import { MediaType } from "groupphoto-models";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { AppBar, Box, IconButton, Stack, Toolbar, Tooltip, Typography } from "@mui/material";

const MediaViewerHeader: React.FC<any> = ({ title, mediaType, onClosePreview, onZoomIn, onZoomOut, children }) => {
    return (
        <AppBar
            sx={{
                backgroundImage: "none",
                backgroundColor: "transparent",
                boxShadow: "none",
            }}
        >
            <Toolbar variant="dense">
                <Tooltip title="Up">
                    <IconButton onClick={onClosePreview}>
                        <ArrowBackIcon sx={{ color: "#fff" }} />
                    </IconButton>
                </Tooltip>

                <Typography>{title}</Typography>

                <Box flexGrow={1} />

                <Stack direction="row" spacing={1}>
                    {mediaType !== MediaType.Video && (
                        <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                            {onZoomIn && (
                                <Tooltip title="Zoom In">
                                    <IconButton onClick={onZoomIn}>
                                        <ZoomInIcon sx={{ color: "#fff" }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {onZoomOut && (
                                <Tooltip title="Zoom Out">
                                    <IconButton onClick={onZoomOut}>
                                        <ZoomOutIcon sx={{ color: "#fff" }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    )}
                    {children}
                </Stack>
            </Toolbar>
        </AppBar>
    );
};

export default MediaViewerHeader;
