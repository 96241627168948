import { styled } from "@mui/material/styles";
import { Button, ButtonGroup, ButtonProps } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { useAssetRating } from "../../hooks";

interface Props {
    id: string;
    rating: number;
}

const RatingButton = styled(Button)<ButtonProps>(() => ({
    color: "#fff",
    borderColor: "transparent !important",
}));

const MediaViewerRating: React.FC<Props> = ({ id, rating }) => {
    const [localRating, setLocalRating] = useState<number>(rating);
    const [loading, setLoading] = useState<boolean>(false);

    const { incrementCounter, decrementCounter } = useAssetRating();

    const onUpvote = async (e: React.MouseEvent<HTMLButtonElement>) => {
        try {
            e.stopPropagation();
            if (!id) throw new Error("Keystone ID not found");

            if (loading) return;
            setLocalRating((r) => ++r);
            setLoading(true);

            await incrementCounter(id);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const onDownvote = async (e: React.MouseEvent<HTMLButtonElement>) => {
        try {
            e.stopPropagation();
            if (!id) throw new Error("Keystone ID not found");

            if (loading) return;
            setLocalRating((r) => --r);
            setLoading(true);

            await decrementCounter(id);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ButtonGroup variant="text" disableElevation sx={{ bgcolor: "transparent" }}>
            <RatingButton onClick={onUpvote}>{<KeyboardArrowUpIcon />}</RatingButton>
            <RatingButton>{localRating}</RatingButton>
            <RatingButton onClick={onDownvote}>{<KeyboardArrowDownIcon />}</RatingButton>
        </ButtonGroup>
    );
};

export default MediaViewerRating;
