import { useEffect, useMemo, useState } from "react";
import { Box, Divider, FormControl, FormLabel, Paper, Slider, Stack, Typography } from "@mui/material";
import { prices } from "../../../lib/constants";
import UpgradeFooter from "./UpgradeFooter";
import { translator } from "../../../i18n/translator";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import { useSubscriptionService } from "../../../services";
import { useAppSelector } from "../../../store";
import SkeletonLoader from "../Subscription/SkeletonLoader";

import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";

import { STRIPE_PK } from "../../../lib/constants";
import { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { blue, grey } from "@mui/material/colors";
import { LogoIcon } from "../../../icons";

const Tab = styled(TabUnstyled)`
    font-family: Lato, IBM Plex Sans, sans-serif;
    color: #fff;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 600;
    background-color: transparent;
    width: 100%;
    padding: 10px 12px;
    margin: 6px 6px;
    border: none;
    border-radius: 7px;
    display: flex;
    justify-content: center;

    &:hover {
        background-color: ${blue[400]};
    }

    &:focus {
        color: #fff;
        outline: 3px solid ${blue[200]};
    }

    &.${tabUnstyledClasses.selected} {
        background-color: #fff;
        color: ${blue[600]};
    }

    &.${buttonUnstyledClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

const TabsList = styled(TabsListUnstyled)(
    ({ theme }) => `
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
    background-color: ${blue[500]};
    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    box-shadow: 0px 4px 30px ${theme.palette.mode === "dark" ? grey[900] : grey[200]};
    `
);

const Subscribe = () => {
    const [loading, setLoading] = useState(true);
    const [frequency, setFrequency] = useState("monthly");
    const [type, setType] = useState("1TB");
    const [stripePromise, setStripePromise] = useState<Stripe | null>(null);

    const { changeSubscriptionPlan, createSubscriptionSession } = useSubscriptionService();
    const user = useAppSelector((state) => state.user);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Plans - Group Photo";
    }, []);

    useEffect(() => {
        if (user) {
            setLoading(false);
        }
    }, [user]);

    useEffect(() => {
        let shouldContinue = true;
        if (!STRIPE_PK) return;

        loadStripe(STRIPE_PK)
            .then((stripe) => {
                if (!shouldContinue) return;
                setStripePromise(stripe);
            })
            .catch((error) => console.error(error));

        return () => {
            shouldContinue = false;
        };
    }, []);

    const price = useMemo(() => {
        return prices.find((p) => p.type === type) || prices[0];
    }, [type]);

    const hasActivePlan = (() => {
        if (
            user &&
            user.plan.stripe &&
            user.plan.stripe?.subscription &&
            ["active"].includes(user.plan.stripe?.subscription?.status)
        ) {
            return true;
        } else {
            return false;
        }
    })();

    const plan = useMemo(() => {
        if (frequency === "monthly") {
            return price.mo;
        } else {
            return price.yr;
        }
    }, [frequency, price]);

    const onFrequencyChange = (event: any, newFrequency: any) => {
        if (newFrequency === 0) {
            setFrequency("monthly");
        } else {
            setFrequency("yearly");
        }
    };

    const onPlanChange = (event: any, value: number | number[]) => {
        setType(`${value}TB`);
    };

    const onPlanSelect = async () => {
        try {
            setLoading(true);

            if (hasActivePlan) {
                await changeSubscriptionPlan(plan.plan);

                navigate(ROUTES.ACCOUNT_SUBSCRIPTION, { replace: true });
                return;
            }

            const session = await createSubscriptionSession(plan.plan);

            if (session && stripePromise) {
                const { error } = await stripePromise.redirectToCheckout({ sessionId: session.id });

                if (error) {
                    throw new Error(error.message);
                }
            }
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    const tabValue = (() => {
        if (frequency === "monthly") return 0;
        else return 1;
    })();

    if (!stripePromise || !user) return <SkeletonLoader />;

    const displayMonthlyPrice = frequency === "monthly" ? price.mo.mo : price.yr.mo;
    const displayYearlyPrice = frequency === "monthly" ? price.mo.yr : price.yr.yr;
    const save = price.mo.save;

    return (
        <Box>
            <TabsUnstyled onChange={onFrequencyChange} value={tabValue}>
                <TabsList>
                    <Tab>{translator("monthly")}</Tab>
                    <Tab>{translator("yearly")} (Save 20%)</Tab>
                </TabsList>
            </TabsUnstyled>

            <Paper variant="outlined" elevation={0}>
                <Stack sx={{ flexDirection: { xs: "column", sm: "row" }, alignItems: "center", gap: 1, p: "12px" }}>
                    <Stack
                        alignItems="center"
                        sx={{
                            flexDirection: { xs: "column", sm: "row" },
                            gap: 1,
                            flex: "1 1 auto",
                        }}
                    >
                        <LogoIcon />
                        <Box sx={{ textAlign: { xs: "center", sm: "left" } }}>
                            <Typography fontWeight={900}>Group Photo Standard</Typography>
                            <Typography>{translator(price.label)}</Typography>
                        </Box>
                    </Stack>
                    <Box sx={{ mt: { xs: 2, sm: 0 }, textAlign: { xs: "center", sm: "right" } }}>
                        <Typography fontWeight={900}>${displayMonthlyPrice} / month</Typography>

                        {frequency === "yearly" && (
                            <Typography variant="subtitle2" color="grey.500">
                                ${displayYearlyPrice} / year
                            </Typography>
                        )}
                        {frequency === "monthly" && (
                            <Typography variant="subtitle2" color="grey.500">
                                Save ${save} by switching
                            </Typography>
                        )}
                    </Box>
                </Stack>
                <Divider />
                <Box sx={{ px: 4, py: 2 }}>
                    <FormControl fullWidth>
                        <FormLabel>Storage</FormLabel>
                        <Slider
                            onChange={onPlanChange}
                            min={1}
                            max={10}
                            marks={[
                                { value: 1, label: "1TB" },
                                { value: 10, label: "10TB" },
                            ]}
                        />
                    </FormControl>
                </Box>
                <UpgradeFooter hasActivePlan={hasActivePlan} loading={loading} onPlanSelect={onPlanSelect} />
            </Paper>
        </Box>
    );
};

export default Subscribe;
