import { MediaRenderingProfile, MediaType, PoolMember, PoolMemberInvitationState } from "groupphoto-models";
import dayjs from "dayjs";
import {
    Coordinate,
    ModifiedAsset,
    ModifiedAssetSummary,
    ModifiedCluster,
    ModifiedUserPool,
    PendingMember,
} from "../lib/interfaces";
import config from "../configs";

export function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringInitials(name: string) {
    const matches = name.match(/\b(\w)/g);
    if (!matches) return "";

    if (matches.length > 2) {
        const [a, b] = matches;
        return a + b;
    }

    return matches.join("");
}

export function sortByTitle(a, b) {
    const a_title = a.title.toLowerCase();
    const b_title = b.title.toLowerCase();

    if (a_title < b_title) return -1;
    if (a_title > b_title) return 1;
    return 0;
}

/**
 * Determines the lowest class to serve as thumbnails
 *
 * @param asset
 */
export const getAssetThumbnail = (asset: ModifiedAsset) => {
    if (!asset.renderings) return "";
    const { renderings } = asset;
    if (asset.mediaType === MediaType.Photo) {
        return renderings.find((r) => r.profile === MediaRenderingProfile.Small);
    }
    return renderings.find((r) => r.profile === MediaRenderingProfile.Thumbnail);
};

/**
 * Determines the lowest class to serve as thumbnails
 *
 * @param asset
 */
export const getAssetPreview = (asset: ModifiedAsset) => {
    if (!asset.renderings) return "";

    const { renderings } = asset;

    const large = renderings.find((r) => r.profile === MediaRenderingProfile.Large);
    const small = renderings.find((r) => r.profile === MediaRenderingProfile.Small);

    if (large) return large;
    if (small) return small;

    return "";
};

export function formatDateRange(startDate: Date, endDate: Date): JSX.Element | string {
    const LOCALE = "en-US";

    const ordinalSuffix = (n: number): string => {
        if (n === 1 || n === 21 || n === 31) {
            return "st";
        }
        if (n === 2 || n === 22) {
            return "nd";
        }
        if (n === 3 || n === 23) {
            return "rd";
        }
        return "th";
    };

    const ordinalDay = (day: number): JSX.Element => {
        return (
            <>
                {day}
                <sup>{ordinalSuffix(day)}</sup>
            </>
        );
    };

    const formatYear = (year: number): string => {
        // if (year > 2000) {
        //   return `'` + `${year}`.substring(2);
        // }
        return `${year}`;
    };

    const formatMonth = (month: Date): string => {
        return month.toLocaleString(LOCALE, { month: "short" });
    };

    const startDay = startDate.getDate();
    const startMonth = startDate.getMonth() + 1;
    const startYear = startDate.getFullYear();

    const endDay = endDate.getDate();
    const endMonth = endDate.getMonth() + 1;
    const endYear = endDate.getFullYear();

    if (startDay === endDay && startMonth === endMonth && startYear === endYear) {
        return (
            <>
                {formatMonth(startDate)} {ordinalDay(startDay)}, {formatYear(startYear)}
            </>
        );
    }

    if (startMonth === endMonth && startYear === endYear) {
        return (
            <>
                {formatMonth(startDate)} {ordinalDay(startDay)} - {ordinalDay(endDay)}, {formatYear(startYear)}
            </>
        );
    }

    if (startYear === endYear) {
        return (
            <>
                {formatMonth(startDate)} {ordinalDay(startDay)} - {formatMonth(endDate)} {ordinalDay(endDay)},{" "}
                {formatYear(startYear)}
            </>
        );
    }

    return (
        <>
            {formatMonth(startDate)} {ordinalDay(startDay)}, {formatYear(startYear)} - {formatMonth(endDate)}{" "}
            {ordinalDay(endDay)}, {formatYear(endYear)}
        </>
    );
}

export function splitFormattedRange(dateString: string) {
    const [month, day, year] = dateString.split(" ");
    const dayNum = day.match(/[0-9]/g)?.join("");
    const ordinal = day.match(/[a-zA-Z]/g)?.join("");

    return {
        month,
        dayNum,
        ordinal,
        year,
    };
}

/**
 * Get the first characters of 1 or 2 names separated by a space.
 * Maximum of 2 characters
 *
 * @param name
 */
export const getInitials = (name: string) => {
    if (!name) return "";

    const noEmojiName = removeEmoji(name);
    const trimmedName = noEmojiName.trim();
    const splitted_name = trimmedName.split(" ", 2);

    if (splitted_name.length < 2) return splitted_name[0][0];
    return `${splitted_name[0][0]}${splitted_name[1][0]}`;
};

/**
 * Extracted From:
 * @link https://www.qvera.com/kb/index.php/2025/how-can-i-remove-emojis-from-the-content-of-a-message
 *
 * @param text
 */
export const removeEmoji = (text: string) => {
    return text.replace(
        /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
        ""
    );
};

export function createGalleryMarks(pool: ModifiedUserPool) {
    if (!pool.timelineYearMonths) {
        return {
            monthsCollection: [],
            marks: [],
            counter: 0,
        };
    }

    let counter = 0;
    const dates: any = [];

    for (const key in pool.timelineYearMonths) {
        const year = Number(key);
        const monthIndices = pool.timelineYearMonths[key];

        counter += monthIndices.length;

        const copy = monthIndices.slice();
        copy.sort((a, b) => a - b);

        copy.forEach((i, index) => {
            const date = new Date(year, i);
            const dayjsDate = dayjs(date).endOf("month");
            const isLast = index === copy.length - 1 ? true : false;

            dates.push({
                label: dayjsDate.format("MMM YYYY"),
                date: dayjsDate.toDate(),
                isLast,
            });
        });
    }

    //////////////////////////////////////////////
    // Reversing dates
    //
    // Data set is in correct order since we're now
    // displaying the oldest asset first so reversing
    // is actually the incorrect method now.
    //////////////////////////////////////////////
    dates.reverse();

    const indexedMonths = dates.map((month, index) => ({ ...month, index }));
    const filteredMonths = indexedMonths.filter((month) => month.isLast);

    let marks = filteredMonths.map((month) => {
        const targetMonth = indexedMonths[counter - (month.index + 1)];

        return {
            label: month.label.split(" ")[1],
            value: targetMonth.index + 1,
        };
    });

    if (marks.length < 15) {
        return {
            monthsCollection: dates,
            marks,
            counter,
        };
    }

    const divisor = 8;
    const dividend = marks.length;

    const quotient = dividend / divisor;
    const markIndices: number[] = [0];
    let currentIndex = quotient;
    let loopTotal = 0;

    while (loopTotal < 8) {
        markIndices.push(Math.round(currentIndex) - 1);
        currentIndex += quotient;
        loopTotal++;
    }

    marks = marks.filter((_, i) => {
        if (markIndices.includes(i)) return true;
        return false;
    });

    return {
        monthsCollection: dates,
        marks,
        counter,
    };
}

export function filterPendingMembers(members: PoolMember[], pendingMembers: PendingMember[]) {
    return pendingMembers.filter((p) => {
        let flag = true;

        for (let i = 0; i < members.length; i++) {
            const fMember = members[i];

            if (fMember.invitation) {
                if (fMember.invitation.email === p.identity) {
                    flag = false;
                    break;
                }
            } else if (fMember.userSummary?.displayName === p.identity) {
                flag = false;
                break;
            }
        }

        return flag;
    });
}

/**
 * Returns existing and newly invited users
 */
export function filterMembers(members: PoolMember[]) {
    return members.filter((m) => {
        if (!m.invitation) return true;

        const { Accepted, Declined } = PoolMemberInvitationState;

        switch (m.invitation.state) {
            case Accepted:
            case Declined:
                return false;
            default:
                return true;
        }
    });
}

export function getSearchParams<T extends object>(): Partial<T> {
    // server side rendering
    if (typeof window === "undefined") {
        return {};
    }

    const params = new URLSearchParams(window.location.search);

    return new Proxy(params, {
        get(target, prop, receiver) {
            return target.get(prop as string) || undefined;
        },
    }) as T;
}

export function assetURLHelper(url: string) {
    return `https://${config.domains.cdn}/${url}`;
}

export function getAdjacents(
    array: any[],
    { current, count }: { current: number; count: number }
): {
    current: number;
    next: ModifiedAssetSummary[];
    prev: ModifiedAssetSummary[];
} {
    if (array.length === 0) {
        return {
            current: current,
            next: [],
            prev: [],
        };
    }

    const targetPrevIndex = current - count;
    const targetNextIndex = current + count;

    const prevSet = array
        .filter((_, i) => {
            if (i < current && i >= targetPrevIndex) {
                return true;
            } else {
                return false;
            }
        })
        .reverse();

    const nextSet = array.filter((_, i) => {
        if (i > current && i <= targetNextIndex) {
            return true;
        } else {
            return false;
        }
    });

    return {
        prev: prevSet,
        current: current,
        next: nextSet,
    };
}

export function getClusterCoordinates(cluster: ModifiedCluster): Coordinate {
    return {
        x: cluster.x,
        y: cluster.y,
        description: cluster.description,
    };
}

export function remainingChars(content: string) {
    const maxChars = 35;
    const remaining = maxChars - content.length;
    return `${remaining} characters remaining.`;
}

export function preloadImage(path: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = path;
        image.onload = () => {
            resolve(image);
        };
        image.onerror = () => {
            reject(image);
        };
    });
}

export const currencyFormatter = new Intl.NumberFormat(undefined, { style: "currency", currency: "USD" });
