import { Box, Skeleton, Stack } from "@mui/material";

const GalleryToolbarSkeleton = () => (
    <Box>
        <Stack direction="row" spacing={1} sx={{ pr: 2 }}>
            {[...Array(2)].map((_, i) => (
                <Skeleton key={i} variant="circular" width={30} height={30} />
            ))}
        </Stack>
    </Box>
);

export default GalleryToolbarSkeleton;
