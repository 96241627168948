import { Collections } from "groupphoto-models";
import { logEvent } from "firebase/analytics";
import {
    doc,
    collection,
    getDoc,
    getDocs,
    orderBy,
    query,
    startAfter,
    where,
} from "firebase/firestore";
import { analytics, auth, db } from "../firebase";
import { PoolConverter } from "../converters";
import { http } from "../http";
import { ANALYTIC_EVENTS } from "../lib/constants";

const usePoolService = () => {
    const fetchPoolSubscription = async (id: string) => {
        try {
            const q = query(
                collection(db, Collections.UserPool),
                where("userId", "==", id),
                where("isArchived", "==", false),
                orderBy("createdAt", "asc")
            ).withConverter(PoolConverter);

            return q;
        } catch (error) {
            throw error;
        }
    };

    /**
     * Gets all pools for the currently logged in user
     *
     * @param doc [Optional] Required for continuous scrolling
     * @returns
     */
    const fetchAll = async (doc?: any) => {
        try {
            if (!auth.currentUser) throw new Error("Not logged in");

            if (!doc) {
                const q = query(
                    collection(db, Collections.UserPool),
                    where("userId", "==", auth.currentUser.uid),
                    where("isArchived", "==", false),
                    orderBy("createdAt", "asc")
                ).withConverter(PoolConverter);

                const snapshot = await getDocs(q);
                return snapshot;
            }

            const q = query(
                collection(db, Collections.UserPool),
                where("userId", "==", auth.currentUser.uid),
                where("isArchived", "==", false),
                orderBy("createdAt", "asc"),
                startAfter(doc ?? [])
            ).withConverter(PoolConverter);

            const snapshot = await getDocs(q);
            return snapshot;
        } catch (error) {
            throw error;
        }
    };

    /**
     * Fetches a single pool
     *
     * @param id - Document ID of the pool
     * @returns
     */
    const fetchOne = async (id: string) => {
        try {
            const ref = doc(db, Collections.UserPool, id).withConverter(
                PoolConverter
            );
            const snapshot = await getDoc(ref);

            if (!snapshot.exists()) return null;

            const poolRecord = snapshot.data();
            return poolRecord;
        } catch (error) {
            throw error;
        }
    };

    /**
     * Creates a pool
     *
     * @param payload - Title, color, and or description of the pool
     * @returns Document ID of the pool
     */
    const createPool = async (payload: {
        title: string;
        description?: string;
        color: string;
    }) => {
        try {
            if (!auth.currentUser) throw new Error("Not logged in");

            const formData = {
                title: payload.title,
                description: payload.description ?? "",
                colorTag: payload.color,
                myTitle: payload.title,
                myDescription: payload.description ?? "",
                myColorTag: payload.color,
            };

            const { data } = await http.post("/api/pools", formData);
            logEvent(analytics, ANALYTIC_EVENTS.CREATED_POOL);
            return data.poolId;
        } catch (error) {
            throw error;
        }
    };

    /**
     * Updates the myTitle, myColorTag and / or myDescription
     *
     * @param values - New values for the pool to be updated
     * @param id - Document ID of the pool
     * @returns UserPool || null
     */
    const updatePool = async (
        values: { title: string; color: string; description?: string },
        id: string
    ) => {
        try {
            await http.put(`/api/pools/${id}`, {
                myTitle: values.title,
                myDescription: values.description || null,
                myColorTag: values.color,
            });
        } catch (error) {
            throw error;
        }
    };

    /**
     * Soft deletes a pool in the record
     *
     * @param id - Document ID of the pool
     * @returns Status of the process
     */
    const archivePool = async (id: string) => {
        try {
            const { data } = await http.delete(`/api/pools/${id}`);
            return data;
        } catch (error) {
            throw error;
        }
    };

    const onCopyAssetsToPool = async (
        poolId: string,
        assetIds: string[],
        clusterIds: string[]
    ) => {
        try {
            const { data } = await http.put(`/api/pools/${poolId}/assets`, {
                assetIds: assetIds,
                clusterIds: clusterIds,
            });

            return data;
        } catch (e) {
            throw e;
        }
    };

    return {
        fetchAll,
        fetchOne,
        fetchPoolSubscription,
        createPool,
        updatePool,
        archivePool,
        onCopyAssetsToPool,
    };
};

export default usePoolService;
