import { Breadcrumbs, Link, Typography } from "@mui/material";
import { generatePath, Link as RouterLink, useLocation, useParams, useSearchParams } from "react-router-dom";
import { Coordinate, ModifiedUserPool } from "../../lib/interfaces";
import { useAppSelector } from "../../store";
import { ROUTES } from "../../routes";
import GalleryToolbarTitle from "./GalleryToolbarTitle";
import { useEffect, useRef, useState } from "react";

interface Props {
    pool: ModifiedUserPool | null;
    isTimeline: boolean;
}

const generateKey = (coordinate: Coordinate, index: number) => {
    return `${coordinate.x}-${coordinate.y}-${index}`;
};

const GalleryBreadcrumbItem = ({ isLast, crumb }) => {
    const location = useLocation();
    const [search] = useSearchParams();

    search.set("x", crumb.x);
    search.set("y", crumb.y);

    if (isLast) {
        return (
            <Typography color="text.primary" fontSize={12}>
                {crumb.description}
            </Typography>
        );
    }

    return (
        <Link
            component={RouterLink}
            to={{
                pathname: location.pathname,
                search: search.toString(),
            }}
            underline="hover"
            sx={{
                display: "block",
                color: "inherit",
                fontSize: "12px",
            }}
        >
            {crumb.description}
        </Link>
    );
};

const GalleryBreadcrumbs: React.FC<Props> = ({ pool, isTimeline }) => {
    const breadcrumbRef = useRef<HTMLElement>(null);
    const [maxItems, setMaxItems] = useState<number | undefined>();
    const breadcrumb = useAppSelector((state) => state.breadcrumb);

    const { orgId, id } = useParams();

    const isLast = (index) => {
        return index === breadcrumb.length - 1 ? true : false;
    };

    useEffect(() => {
        const target = breadcrumbRef.current;

        if (target && target.clientWidth < target.scrollWidth) {
            setMaxItems(3);
        }
    }, [breadcrumb]);

    return (
        <Breadcrumbs
            ref={breadcrumbRef}
            maxItems={maxItems}
            aria-label="breadcrumb"
            sx={{
                ".MuiBreadcrumbs-ol": {
                    whiteSpace: maxItems === undefined ? "nowrap" : undefined,
                    flexWrap: maxItems === undefined ? "nowrap" : undefined,
                },
            }}
        >
            <Link
                component={RouterLink}
                to={generatePath(ROUTES.POOL, {
                    orgId: orgId,
                    id: id,
                })}
                underline="none"
                sx={{ color: (theme) => (theme.palette.mode === "dark" ? "#fff" : "rgba(0, 0, 0, 0.8)") }}
            >
                <GalleryToolbarTitle>{pool?.myTitle || pool?.title}</GalleryToolbarTitle>
            </Link>
            {!isTimeline &&
                breadcrumb.map((b, index) => (
                    <GalleryBreadcrumbItem key={generateKey(b, index)} isLast={isLast(index)} crumb={b} />
                ))}
        </Breadcrumbs>
    );
};

export default GalleryBreadcrumbs;
