import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
    selectedAssets: string[];
    selectedClusters: { x: number; y: number; name: string }[];
}

const initialState: State = {
    selectedAssets: [],
    selectedClusters: [],
};

export const selectionSlice = createSlice({
    name: "selection",
    initialState,
    reducers: {
        toggleAssetStack: (state, action: PayloadAction<string>) => {
            const { selectedAssets } = state;

            if (selectedAssets.includes(action.payload)) {
                return {
                    ...state,
                    selectedAssets: state.selectedAssets.filter((s) => s !== action.payload),
                };
            } else {
                return {
                    ...state,
                    selectedAssets: [...state.selectedAssets, action.payload],
                };
            }
        },
        toggleClusterStack: (state, action: PayloadAction<{ x: number; y: number; name: string }>) => {
            const foundIndex = state.selectedClusters.findIndex((c) => {
                if (c.name === action.payload.name && c.x === action.payload.x && c.y === action.payload.y) {
                    return true;
                }
                return false;
            });

            if (foundIndex !== -1) {
                return {
                    ...state,
                    selectedClusters: state.selectedClusters.filter((c, i) => i !== foundIndex),
                };
            } else {
                return {
                    ...state,
                    selectedClusters: [...state.selectedClusters, action.payload],
                };
            }
        },
        resetSelection: () => {
            return initialState;
        },
    },
});

export const { resetSelection, toggleAssetStack, toggleClusterStack } = selectionSlice.actions;

export default selectionSlice.reducer;
