const config = {
    environment: "dev",
    firebase: {
        apiKey: "AIzaSyANzoFcz9vSqvC4Mysvyho9MhQvYVVrlIs",
        authDomain: "group-photo-dev.firebaseapp.com",
        databaseURL: "https://group-photo-dev.firebaseio.com",
        projectId: "group-photo-dev",
        storageBucket: "group-photo-dev.appspot.com",

        messagingSenderId: "601223379971",
        appId: "1:601223379971:web:f5db7f9b0141ef14ed2236",
        measurementId: "G-CPQ8LW9E94",
    },
    domains: {
        app: "app.gpdev.co",
        marketing: "gpdev.co",
        cdn: "media-cdn.gpdev.co",
    },
    appCheckSiteKey: "6LdQF6gcAAAAABTqLXACnpSq6VNn95QGEENr3rFe",
    stripe: {
        publishable_key: "pk_test_Ycjm7RzkwVhmPld3OAVUL7ti",
    },
    sentry: {
        dsn: "https://285fde17a19c401280639efa2ec8803d@o1050740.ingest.sentry.io/6256502",
        transactionSampleRate: 1.0, // percentage of transactions reported to sentry
    },
};

export default config;
