import { LOCALES } from "../locales";

const TRANSLATIONS = {
    [LOCALES.MANDARIN]: {
        signup: "注册",
        login: "登录",
        logout: "Logout",
        email: "Email",
        phone: "Phone",
        terms_of_service: "服务条款",
        privacy_policy: "隐私政策",
        continue: "继续",
        back: "背部",
        you: "你",
        done: "完成了",
        modalTitle: "“集體照”想訪問您的聯繫人",
        modalDescription:
            "Group Photo僅將電話簿中的電話號碼同步到Group Photo服務器，以幫助您與其他Group Photo用戶建立聯繫",
        suggested: "建議的",
        choose_color: "選擇顏色",
        choose_color_instruction: "通過在圓環周圍拖動手柄來選擇自定義顏色",
        activity: "活動",
        rate: "等级",
        select: "选择",
        color: "颜色",
        archive: "封存",
        save: "保存",
        no: "没有",
        invite: "邀请",
        owner: "所有者",
        remove: "去掉",
        contributor: "贡献者",
        viewer: "观看者",
        moderator: "主持人",
        voter: "选民",
        inviteByEmail: "通过电子邮件邀请",
        participant: "参加者",
        votingParticipant: "投票参加者",
        viewerWithInvites: "查看者与邀请",
        next: "Next",

        "email.required": "电子邮件地址为必填项",
        "email.invalid": "电子邮件是无效的",
        "phone.required": "需要电话",
        "phone.invalid": "电话无效",
        "phone.min": "电话必须至少有10个字符",

        "login.welcome": "欢迎回来",
        "login.google": "用谷歌登录",
        "login.facebook": "用Facebook登录",
        "login.email": "用电子邮件登录",
        "login.apple": "用苹果登录",
        "login.to_signup": "还没有帐号？{signup}",

        "auth.footer":
            "使用集体照，即表示您同意我们的服务条款和隐私政策 {terms} 和 {privacy}",

        "register.welcome": "Sharing What Matters",
        "register.google": "使用Google注册",
        "register.facebook": "用Facebook注册",
        "register.email": "用电子邮件注册",
        "register.apple": "用苹果注册",
        "register.to_login": "已经有帐号了？ {login}",

        "nav.pools": "泳池",
        "nav.members": "会员",
        "nav.inbox": "收件箱",
        "nav.me": "我",

        "pools.pools": "Pools",
        "pools.new": "New Pool",
        "pools.home": "Home",
        "pools.select_pools": "Select Pools",
        "pools.invite_contact": "邀請聯繫人",
        "pools.copy_share": "複製並分享鏈接",
        "pools.frequent_contacts": "經常聯繫",
        "pools.poolChat": "泳池聊天",
        "pools.shareToRings": "分享到戒指",
        "pools.addMedia": "添加媒体",
        "pools.poolSettings": "泳池设置",
        "pools.managePool": "管理池",
        "pools.descriptionOptional": "说明（可选）",

        "members.members": "会员",
        "members.invite": "邀请家人和朋友",

        "inbox.inbox": "收件箱",
        "inbox.delete": "{user} 您从中删除了照片 {pool}",
        "inbox.added_single": "{user} 已将照片添加到 {pool}",
        "inbox.added_bulk": "{user} 附加 {number} 的照片，以 {pool}",

        "account.account": "我的集体照帐户",
        "account.archive": "封存",
        "account.backup": "备份设定",
        "account.refer": "推荐朋友",
        "account.help": "帮助支持",
        "account.feedback": "留下反馈",
        "account.type": "帳戶類型",
        "account.space_used": "使用空間",
        "account.upgrade": "升級到集體照標準",
        "account.archived_pools": "存檔池",
        "account.unarchive": "取消存檔",
        "account.media": "媒體",
        "account.backup_history": "備份記錄",

        "upload.choose": "从图书馆挑选",
        "upload.take_photo": "拍照或录像",
        "upload.cancel": "取消",

        "memberRoles.description1": "可以添加/删除媒体。无法管理池",
        "memberRoles.description2": "只能查看媒体和聊天。无法编辑池",
        "memberRoles.description3":
            "可以分配角色并管理成员。有权创建，添加，删除和评估资产",
        "memberRoles.description4":
            "删除其他成员，管理成员，内容/资产，无法修改池设置",
        "memberRoles.description5": "可以聊天并评分",
        "memberRoles.description6": "只能聊天",
        "memberRoles.description7": "只能评分",
        "memberRoles.description8": "仅查看，但可以邀请其他人",
        "memberRoles.description9": "显示高级权限",
        "memberRoles.description10": "Can add assets, chat",
        "memberRoles.description11": "View only",
    },
};

export default TRANSLATIONS;
