import { useCallback } from "react";
import { Collections } from "groupphoto-models";
import { ModifiedAssetSummary, ModifiedCluster } from "../lib/interfaces";
import { AssetConverter } from "../converters";
import { http } from "../http";
import { db } from "../firebase";
import {
    collection,
    doc,
    DocumentSnapshot,
    endBefore,
    getDoc,
    limit,
    limitToLast,
    orderBy,
    Query,
    query,
    startAfter,
    startAt,
    where,
} from "firebase/firestore";

const useAssetService = () => {
    const ASSET_LIMIT = 8;

    const getAssetByIdRef = useCallback((id: string) => {
        return doc(db, Collections.Asset, id).withConverter(AssetConverter);
    }, []);

    const fetchAssetById = async (id: string) => {
        try {
            const ref = getAssetByIdRef(id);
            const snapshot = await getDoc(ref);

            if (!snapshot.exists()) return null;

            return snapshot.data();
        } catch (error) {
            throw error;
        }
    };

    const fetchInitialAssets = useCallback(
        async (id?: string, assetId?: string | null): Promise<Query<ModifiedAssetSummary>> => {
            try {
                if (!id) throw new Error("Pool ID missing");

                let q;

                if (assetId) {
                    const document = await getDoc(doc(db, Collections.Asset, assetId));

                    q = query(
                        collection(db, Collections.Asset),
                        where("poolId", "==", id),
                        where("isArchived", "==", false),
                        orderBy("takenAt", "desc"),
                        startAt(document),
                        limit(ASSET_LIMIT)
                    ).withConverter(AssetConverter);
                } else {
                    q = query(
                        collection(db, Collections.Asset),
                        where("poolId", "==", id),
                        where("isArchived", "==", false),
                        orderBy("takenAt", "desc"),
                        limit(ASSET_LIMIT)
                    ).withConverter(AssetConverter);
                }

                return q;
            } catch (error) {
                throw error;
            }
        },
        []
    );

    const fetchNextAssets = (id: string, document: DocumentSnapshot) => {
        try {
            if (!document) return null;

            const q = query(
                collection(db, Collections.Asset),
                where("poolId", "==", id),
                where("isArchived", "==", false),
                orderBy("takenAt", "desc"),
                startAfter(document),
                limit(ASSET_LIMIT)
            ).withConverter(AssetConverter);

            return q;
        } catch (error) {
            throw error;
        }
    };

    const fetchPreviousAssets = (id: string, document: DocumentSnapshot) => {
        try {
            if (!document) return null;

            const q = query(
                collection(db, Collections.Asset),
                where("poolId", "==", id),
                where("isArchived", "==", false),
                orderBy("takenAt", "desc"),
                endBefore(document),
                limitToLast(ASSET_LIMIT)
            ).withConverter(AssetConverter);

            return q;
        } catch (error) {
            console.warn(error);
        }
    };

    const fetchAssetByDate = (id: string, date: Date) => {
        try {
            const q = query(
                collection(db, Collections.Asset),
                where("poolId", "==", id),
                where("isArchived", "==", false),
                where("takenAt", "<=", date),
                orderBy("takenAt", "desc"),
                limit(ASSET_LIMIT)
            ).withConverter(AssetConverter);

            return q;
        } catch (error) {
            throw error;
        }
    };

    const fetchClusters = useCallback(async (id: string, x: number, y: number): Promise<ModifiedCluster | null> => {
        try {
            const { data } = await http.get("/api/livecluster/v1/cluster", {
                params: {
                    name: id,
                    k: 8,
                    x: x,
                    y: y,
                    min_taken_at: 1,
                },
            });
            if (!data) return null; // We're expecting an object
            if (typeof data !== "object") return null;
            if (Object.keys(data.cluster).length === 0) return null; // The returned data could be an empty object

            const cluster = data.cluster;

            const formatted = {
                ...data.cluster,
                dateStart: cluster.first_point ? new Date(cluster.first_point) : cluster.first_point,
                dateEnd: cluster.last_point ? new Date(cluster.last_point) : cluster.last_point,
            };

            return formatted as ModifiedCluster;
        } catch (error) {
            throw error;
        }
    }, []);

    const archiveAsset = async (id: string, poolId: string) => {
        try {
            console.log(`Archiving ${id}`);
            const { data } = await http.put(`/api/media/${id}`, {
                poolId,
            });
            return data;
        } catch (e) {
            throw e;
        }
    };

    const updateTakenAt = async (ids: string[], date: Date) => {
        try {
            const { data } = await http.put(`/api/assets`, {
                assetIds: ids,
                date: date,
            });

            return data;
        } catch (e) {
            throw e;
        }
    };

    return {
        getAssetByIdRef,
        fetchAssetById,
        fetchAssetByDate,
        fetchInitialAssets,
        fetchNextAssets,
        fetchPreviousAssets,
        fetchClusters,
        updateTakenAt,
        archiveAsset,
    };
};

export default useAssetService;
