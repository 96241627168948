import { createSlice } from "@reduxjs/toolkit";
import { Coordinate } from "../lib/interfaces";

let initialState: Coordinate[] = [];

const storedCrumbs = window.localStorage.getItem("breadcrumb");
if (storedCrumbs) {
    initialState = JSON.parse(storedCrumbs);
}

export const breadcrumbSlice = createSlice({
    name: "breadcrumb",
    initialState,
    reducers: {
        insertCrumb: (state, { payload }: { payload: Coordinate }) => {
            if (payload.y === 0) return state;

            const found = state.find((b) => b.x === payload.x && b.y === payload.y);

            if (found) {
                window.localStorage.setItem("breadcrumb", JSON.stringify(state));
                return state;
            }

            const updated = [
                ...state,
                {
                    x: payload.x,
                    y: payload.y,
                    description: payload.description,
                },
            ];

            window.localStorage.setItem("breadcrumb", JSON.stringify(updated));

            return updated;
        },
        removeCrumb: (state, { payload }: { payload: Coordinate }) => {
            const crumbIndex = state.findIndex((s) => {
                if (s.x === payload.x && s.y === payload.y) return true;
                return false;
            });

            const updated = state.filter((_, index) => {
                if (index > crumbIndex) {
                    return false;
                } else {
                    return true;
                }
            });

            window.localStorage.setItem("breadcrumb", JSON.stringify(updated));

            return updated;
        },
        removeLastCrumb: (state) => {
            const updated = state.filter((s, i) => i !== state.length - 1);
            window.localStorage.setItem("breadcrumb", JSON.stringify(updated));
            return updated;
        },
        replaceCrumb: (state, { payload }: { payload: Coordinate }) => {
            const copy = state.slice();

            copy.splice(-1, 1, payload);

            window.localStorage.setItem("breadcrumb", JSON.stringify(copy));

            return copy;
        },
        resetBreadcrumbs: () => {
            window.localStorage.removeItem("breadcrumb");
            return [];
        },
    },
});

export const { insertCrumb, removeCrumb, removeLastCrumb, replaceCrumb, resetBreadcrumbs } = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
