import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AccountSidebar from "../../components/Sidebars/Account";
import { translator } from "../../i18n/translator";
import { useAppDispatch } from "../../store";
import { toggleAccountSidebar } from "../../store/sidebarSlice";

const routeTitles = {
    "/account": "account.myAccount",
    "/account/digest": "account.backupDigest",
    "/account/subscription": "subscription",
    "/account/subscribe": "subscription",
    "/account/checkout": "checkout",
};

const AccountLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useAppDispatch();

    const theme = useTheme();
    const darkTheme = theme.palette.mode === "dark" ? true : false;
    const matchesMD = useMediaQuery(theme.breakpoints.up("md"));

    useEffect(() => {
        document.title = "Account - Group Photo";
    }, []);

    useEffect(() => {
        const handleClose = (event) => {
            if (event.code === "Escape") {
                navigate(-1);
            }
        };

        document.addEventListener("keydown", handleClose);

        return () => {
            document.removeEventListener("keydown", handleClose);
        };
    }, [navigate]);

    const onToggleAccountSidebar = () => {
        dispatch(toggleAccountSidebar());
    };

    const onNavigateBack = () => {
        navigate(-1);
    };

    const currentTitle = (() => {
        return routeTitles[location.pathname];
    })();

    return (
        <Box display="flex" width="100%" height="100vh">
            <AccountSidebar />

            <Box flex="1 1 800px" overflow="auto">
                <Box mt={{ xs: 0, md: 5 }} mb={5} px={{ xs: 0, md: 5 }} maxWidth="740px">
                    <AppBar
                        position={matchesMD ? "static" : "sticky"}
                        color={darkTheme && !matchesMD ? "primary" : "transparent"}
                        sx={{
                            bgcolor: {
                                xs: darkTheme ? "undefined" : "#fff",
                                md: "transparent",
                            },
                            borderBottom: {
                                xs: "thin solid rgba(0,0,0,0.2)",
                                md: "none",
                            },
                            boxShadow: "none",
                        }}
                    >
                        <Toolbar variant="dense" disableGutters={!matchesMD ? false : true}>
                            <IconButton
                                onClick={onToggleAccountSidebar}
                                sx={{
                                    display: { xs: "inline-flex", md: "none" },
                                    mr: 1,
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6">
                                {currentTitle ? translator(currentTitle) : translator("account.myAccount")}
                            </Typography>

                            <Box flexGrow={1} />

                            <IconButton onClick={onNavigateBack}>
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    <Box px={{ xs: 2, md: 0 }}>
                        <Outlet />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default AccountLayout;
