import { LOCALES } from "../locales";

const TRANSLATIONS = {
    [LOCALES.PORTUGUESE]: {
        signup: "Inscrever-se",
        login: "Conecte-se",
        logout: "Sair",
        email: "Email",
        phone: "Phone",
        terms_of_service: "Termos de serviços",
        privacy_policy: "Política de Privacidade",
        continue: "Continuar",
        back: "Costas",
        you: "Vocês",
        done: "Topo",
        modalTitle: '"Foto de grupo" gostaria de acessar seus contatos',
        modalDescription:
            "O Group Photo sincroniza apenas números de telefone do seu catálogo de endereços com os servidores do Group Photo para ajudar você a se conectar com outros usuários do Group Photo",
        suggested: "Proposto",
        choose_color: "Escolha a cor",
        choose_color_instruction:
            "Selecione uma cor personalizada, arrastando a alça ao redor do anel",
        activity: "Atividade",
        rate: "Avaliações",
        select: "Selecione",
        color: "Cor",
        archive: "Arquivo",
        save: "Salve",
        no: "Não",
        invite: "Convite",
        owner: "o proprietário",
        remove: "Remover",
        contributor: "Contribuinte",
        viewer: "Visualizador",
        moderator: "Moderador",
        voter: "o eleitor",
        inviteByEmail: "Convidar por email",
        participant: "Participant",
        votingParticipant: "Participante da votação",
        viewerWithInvites: "Visualizador com convites",
        next: "Next",

        "email.required": "E-mail é obrigatório",
        "email.invalid": "Email inválido",
        "phone.required": "Telefone é obrigatório",
        "phone.invalid": "O telefone é inválido",
        "phone.min": "O telefone deve ter pelo menos 10 caracteres",

        "login.welcome": "Bem vindo de volta",
        "login.google": "Entre com o Google",
        "login.facebook": "Entrar com o Facebook",
        "login.email": "Entrar com Email",
        "login.apple": "Entre com a Apple",
        "login.to_signup": "Não possui uma conta? {signup}",

        "auth.footer":
            "Ao usar a Foto de grupo, você concorda com nossos {terms} e {privacy}",

        "register.welcome": "Sharing What Matters",
        "register.google": "Inscreva-se no Google",
        "register.facebook": "Inscreva-se com o Facebook",
        "register.email": "Inscreva-se com e-mail",
        "register.apple": "Inscreva-se com a Apple",
        "register.to_login": "já tem uma conta? {login}",

        "nav.pools": "Piscinas",
        "nav.members": "Membros",
        "nav.inbox": "Caixa de entrada",
        "nav.me": "Eu",

        "pools.pools": "Piscinas",
        "pools.new": "Nova piscina",
        "pools.home": "Casa",
        "pools.select_pools": "Selecionar Piscinas",
        "pools.invite_contact": "Convidar de contatos",
        "pools.copy_share": "Copiar e compartilhar link",
        "pools.frequent_contacts": "Contatos frequentes",
        "pools.poolChat": "Pool Chat",
        "pools.shareToRings": "Compartilhar em Anéis",
        "pools.addMedia": "Adicionar mídias",
        "pools.poolSettings": "Configurações da piscina",
        "pools.managePool": "Gerenciar pool",
        "pools.descriptionOptional": "Descrição (opcional)",

        "members.members": "Membros",
        "members.invite": "Convide família e amigos",

        "inbox.inbox": "Caixa de entrada",
        "inbox.delete": "{user} foto excluída de {pool}",
        "inbox.added_single": "{user} adicionou uma foto ao {pool}",
        "inbox.added_bulk": "{user} adicionou {number} fotos ao {pool}",

        "account.account": "Minha conta do Group Photo",
        "account.archive": "Arquivo",
        "account.backup": "Configurações de backup",
        "account.refer": "Indique Amigos",
        "account.help": "Ajuda e Suporte",
        "account.feedback": "Deixe um feedback",
        "account.type": "Tipo de conta",
        "account.space_used": "Espaço usado",
        "account.upgrade": "Atualizar para o Group Photo Standard",
        "account.archived_pools": "Pools arquivados",
        "account.unarchive": "Desarquivar",
        "account.media": "meios de comunicação",
        "account.backup_history": "Histórico de backup",

        "upload.choose": "Escolha da Biblioteca",
        "upload.take_photo": "Tirar foto ou vídeo",
        "upload.cancel": "Cancelar",

        "memberRoles.description1":
            "Pode adicionar / remover mídia. Não é possível gerenciar o pool",
        "memberRoles.description2":
            "Só pode ver mídia e bate-papo. Não é possível editar o pool",
        "memberRoles.description3":
            "Pode atribuir uma função e gerenciar membros. Tem o privilégio de criar, adicionar, excluir e classificar ativos",
        "memberRoles.description4":
            "Remover outros membros, gerenciar membros, conteúdo / ativos, não pode modificar as configurações do pool",
        "memberRoles.description5": "Pode conversar e avaliar",
        "memberRoles.description6": "Só pode conversar",
        "memberRoles.description7": "Só pode avaliar",
        "memberRoles.description8":
            "Somente visualização, mas pode convidar pessoas",
        "memberRoles.description9": "Mostrar permissões avançadas",
        "memberRoles.description10": "Can add assets, chat",
        "memberRoles.description11": "View only",
    },
};

export default TRANSLATIONS;
