import { memo } from "react";
import { Typography } from "@mui/material";

const KeyboardListSubheader: React.FC<React.PropsWithChildren> = ({ children }) => (
    <Typography variant="h6" fontWeight={900} gutterBottom>
        {children}
    </Typography>
);

export default memo(KeyboardListSubheader);
