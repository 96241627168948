import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";
import worker from "../../../worker";
import { WorkerBuilder } from "../../../workerBuilder";
import { FileItem } from "../../../lib/interfaces";
import { useDialog, useFileUpload, usePool } from "../../../hooks";
import { auth, storage } from "../../../firebase";
import { ref, uploadBytesResumable } from "firebase/storage";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Paper,
    Tooltip,
    Typography,
} from "@mui/material";
import CustomDialogTitle from "../CustomDialogTitle";
import { translator } from "../../../i18n/translator";
import { addFiles, openUploadDrawer, updateItemProgress } from "../../../store/uploadSlice";
import { ControlPoint, PlayCircle } from "@mui/icons-material";
import { useAppSelector } from "../../../store";
import { ROUTES } from "../../../routes";

const WebWorker = new WorkerBuilder(worker);

const AddMediaDialog: React.FC = () => {
    const [selectedFiles, setSelectedFiles] = useState<FileItem[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const dragWrapper = useRef<HTMLDivElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const { fullScreen, onClose } = useDialog();

    const { referenceId } = usePool();
    const { determineMediaType, extractExtension, generateMetadata, validateFile } = useFileUpload();

    const user = useAppSelector((state) => state.user);

    const dispatch = useDispatch();

    useEffect(() => {
        WebWorker.onmessage = (message: MessageEvent) => {
            if (message && message.data) {
                setSelectedFiles((oldState) => {
                    const existingFile = oldState.find((f) => f.sha === message.data.result.hash);

                    if (existingFile) {
                        return oldState.filter((f) => f.id !== message.data.result.id);
                    }

                    return oldState.map((f) => {
                        if (f.id === message.data.result.id) {
                            return {
                                ...f,
                                previewURL: message.data.result.previewURL,
                                processed: true,
                                sha: message.data.result.hash,
                            };
                        }

                        return f;
                    });
                });
            }
        };
    }, []);

    const hasStorageToUpload = useMemo(() => {
        if (!user) return false;

        if (user.usedBytes) {
            if (user.usedBytes < user.planCapacityBytes) {
                return true;
            } else {
                return false;
            }
        } else {
            // If usedBytes isn't present then
            // that means the user haven't uploaded anything yet.
            return true;
        }
    }, [user]);

    const onLocalClose = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
        setSelectedFiles([]);
        onClose();
    };

    const handleFiles = async (files: FileList) => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (!validateFile(file)) {
                setErrors((e) => [...e, `${file.name}: Extension is not supported`]);
                continue;
            }

            const id = uuidv4();
            WebWorker.postMessage({ function: "processFile", id, file: file });

            setSelectedFiles((prevFiles) => {
                const extension = extractExtension(file.name);
                const type = determineMediaType(extension, file.type);

                return [
                    ...prevFiles,
                    {
                        id: id,
                        item: file,
                        sha: "",
                        previewURL: "",
                        processed: false,
                        progress: 0,
                        extension,
                        type,
                    },
                ];
            });
        }
    };

    const upload = async (fileItem: FileItem) => {
        try {
            if (!auth.currentUser) return;
            if (!hasStorageToUpload) return;

            if (!referenceId) {
                throw new Error("Pool ID is required");
            }

            if (!fileItem.sha) {
                throw new Error("Unable to upload item. Missing SHA512 hash...");
            }

            const file = fileItem.item;
            const hash = fileItem.sha;

            const metadata = generateMetadata({
                poolId: referenceId,
                file,
                userId: auth.currentUser.uid,
                hash,
            });

            const storageRef = ref(storage, "uploads/" + hash);
            const task = uploadBytesResumable(storageRef, file, metadata);

            task.on(
                "state_changed",
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    dispatch(
                        updateItemProgress({
                            id: fileItem.id,
                            progress,
                        })
                    );
                },
                (error) => {
                    console.error(error);
                }
            );

            return task;
        } catch (e) {
            console.error(e);
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();

            if (!auth.currentUser) return;
            if (!hasStorageToUpload) return;
            if (selectedFiles.length === 0) return;

            setErrors([]);

            const copy = selectedFiles.map((f) => ({
                id: f.id,
                progress: f.progress,
                name: f.item.name,
                extension: f.extension,
                type: f.type,
            }));

            dispatch(addFiles(copy));
            dispatch(openUploadDrawer());

            selectedFiles.map((fileItem) => upload(fileItem));

            onLocalClose();
        } catch (e) {
            console.error(e);
        }
    };

    const onDragWrapperClick = () => {
        if (!fileInputRef.current) return;

        fileInputRef.current.click();
    };

    const onFilesSelect = async () => {
        if (!fileInputRef.current) return;

        if (!fileInputRef.current.files || fileInputRef.current.files.length < 1) {
            return;
        }

        const files = fileInputRef.current.files;

        await handleFiles(files);

        // @ts-ignore
        fileInputRef.current.value = null;
    };

    const onDragOver = (event: React.DragEvent) => {
        event.preventDefault();
    };

    const onDragEnter = (event: React.DragEvent) => {
        event.preventDefault();
        if (!dragWrapper.current) return;
        dragWrapper.current.classList.add("addMedia--dragActive");
    };

    const onDragLeave = (event: React.DragEvent) => {
        event.preventDefault();
        if (!dragWrapper.current) return;
        dragWrapper.current.classList.remove("addMedia--dragActive");
    };

    const onDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        const { files } = event.dataTransfer;

        if (files.length === 0) return;

        await handleFiles(files);
    };

    const onRemove = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
        event.stopPropagation();
        setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    };

    return (
        <Dialog open maxWidth="sm" fullScreen={fullScreen} fullWidth onClose={onLocalClose}>
            <CustomDialogTitle onClose={onLocalClose}>{translator("pools.addMedia")}</CustomDialogTitle>

            {hasStorageToUpload && (
                <>
                    <DialogContent>
                        {errors.map((e, index) => (
                            <Typography key={`error-${index}`}>{e}</Typography>
                        ))}
                        <Box id="addMediaForm" component="form" onSubmit={handleSubmit}>
                            <input
                                className="addMedia__input"
                                ref={fileInputRef}
                                type="file"
                                multiple
                                onChange={onFilesSelect}
                            />
                            <Box
                                className={clsx("addMedia", {
                                    "addMedia--grid": selectedFiles.length > 0 ? true : false,
                                    "addMedia--centered": selectedFiles.length === 0 ? true : false,
                                })}
                                ref={dragWrapper}
                                onDragEnter={onDragEnter}
                                onDragLeave={onDragLeave}
                                onDragOver={onDragOver}
                                onDrop={onDrop}
                                onClick={onDragWrapperClick}
                            >
                                {selectedFiles.length > 0 && (
                                    <>
                                        {selectedFiles.map((file) => (
                                            <Box key={file.id}>
                                                <Tooltip title={file.item.name}>
                                                    <Paper
                                                        className={clsx("addMedia__item", {
                                                            "addMedia__item--loading": !file.processed,
                                                            "addMedia__item--noPreview":
                                                                file.processed && !file.previewURL,
                                                        })}
                                                        onClick={(e) => e.stopPropagation()}
                                                        sx={{ position: "relative", mb: 1 }}
                                                    >
                                                        {!file.processed && (
                                                            <CircularProgress variant="indeterminate" />
                                                        )}

                                                        {file.processed &&
                                                            !file.previewURL &&
                                                            file.type === "video" && <PlayCircle color="primary" />}

                                                        {file.type === "image" && (
                                                            <Box
                                                                className="addMedia__item__image"
                                                                style={{
                                                                    backgroundImage: `url(${file.previewURL})`,
                                                                }}
                                                            />
                                                        )}
                                                    </Paper>
                                                </Tooltip>
                                                <Button
                                                    onClick={(e) => onRemove(e, file.id)}
                                                    variant="text"
                                                    color="error"
                                                    size="small"
                                                >
                                                    Remove
                                                </Button>
                                            </Box>
                                        ))}

                                        <Box className="addMedia__item addMedia__item--more">
                                            <ControlPoint />
                                        </Box>
                                    </>
                                )}

                                {selectedFiles.length === 0 && (
                                    <Typography>Drag files here (or click to select)</Typography>
                                )}
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button form="addMediaForm" type="button" onClick={onLocalClose}>
                            {translator("cancel")}
                        </Button>
                        <Button form="addMediaForm" type="submit">
                            Upload {selectedFiles.filter((p) => p.progress === 0).length} items
                        </Button>
                    </DialogActions>
                </>
            )}

            {!hasStorageToUpload && (
                <DialogContent>
                    <DialogContentText>
                        You don't have any spaces left to upload more media.
                        <Link to={ROUTES.ACCOUNT_SUBSCRIPTION}>&nbsp;Upgrade to Group Photo Standard&nbsp;</Link>
                        to store more!
                    </DialogContentText>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default AddMediaDialog;
