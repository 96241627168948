import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
    status: "sending" | "done";
    prefetchedPools: string[];
}

const initialState: State = {
    status: "sending",
    prefetchedPools: [],
};

export const prefetchSlice = createSlice({
    name: "prefetch",
    initialState,
    reducers: {
        updatePrefetchStatus: (state, action: PayloadAction<"sending" | "done">) => {
            return {
                ...state,
                status: action.payload,
            };
        },
        insertPrefetchedPool: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                prefetchedPools: [...state.prefetchedPools, action.payload],
            };
        },
    },
});

export const { insertPrefetchedPool, updatePrefetchStatus } = prefetchSlice.actions;

export default prefetchSlice.reducer;
