import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    openMain: false,
    openAccount: false,
};

export const sidebarSlice = createSlice({
    name: "sidebars",
    initialState,
    reducers: {
        showMainSidebar: (state) => {
            return { ...state, openMain: true };
        },
        closeMainSidebar: (state) => {
            return { ...state, openMain: false };
        },
        toggleMainSidebar: (state) => {
            return { ...state, openMain: !state.openMain };
        },
        showAccountSidebar: (state) => {
            return { ...state, openAccount: true };
        },
        closeAccountSidebar: (state) => {
            return { ...state, openAccount: false };
        },
        toggleAccountSidebar: (state) => {
            return { ...state, openAccount: !state.openAccount };
        },
        resetSidebars: () => {
            return initialState;
        },
    },
});

export const {
    showMainSidebar,
    closeMainSidebar,
    toggleMainSidebar,
    showAccountSidebar,
    closeAccountSidebar,
    toggleAccountSidebar,
    resetSidebars,
} = sidebarSlice.actions;

export default sidebarSlice.reducer;
