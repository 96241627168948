import { Link, useLocation } from "react-router-dom";

import {
    AppBar,
    Box,
    Divider,
    IconButton,
    Link as UILink,
    ListItem,
    Menu,
    MenuItem,
    Stack,
    Toolbar,
    Typography,
    TypographyProps,
} from "@mui/material";

import DarkModeIcon from "@mui/icons-material/DarkMode";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LightModeIcon from "@mui/icons-material/LightMode";

import clsx from "clsx";

import { LogoHorizontal } from "../../../icons";
import { ROUTES } from "../../../routes";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { translator } from "../../../i18n/translator";

const AdaptiveTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: theme.palette.mode === "dark" ? grey[500] : theme.palette.common.black,
}));

const MainSidebarHeader = ({ mode, open, anchorEl, handleClose, handleClick, onToggleTheme, signOut }) => {
    const location = useLocation();

    return (
        <AppBar
            position="static"
            color="transparent"
            sx={{
                boxShadow: "none",
                borderWidth: "0 0 thin",
                borderStyle: "solid",
                borderColor: "rgba(0,0,0,0.2)",
            }}
        >
            <Toolbar variant="dense" disableGutters>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        pl: 2,
                    }}
                >
                    <LogoHorizontal className={clsx("header-logo", { dark: mode === "dark" })} />

                    <IconButton onClick={handleClick}>
                        <ExpandMoreIcon />
                    </IconButton>
                </Box>

                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <ListItem sx={{ width: "300px", whiteSpace: "break-spaces" }}>
                        <AdaptiveTypography variant="body2">
                            {translator("user.currentPlan", {
                                link: <UILink>{translator("learnMore")}</UILink>,
                            })}
                        </AdaptiveTypography>
                    </ListItem>
                    <Divider />

                    <MenuItem component={Link} to={ROUTES.HOME}>
                        <AdaptiveTypography variant="body2">{translator("pools.home")}</AdaptiveTypography>
                    </MenuItem>
                    <MenuItem component={Link} to={ROUTES.CREATE_POOL} state={{ dialogLocation: location }}>
                        <AdaptiveTypography variant="body2">{translator("pools.createAPool")}</AdaptiveTypography>
                    </MenuItem>
                    <MenuItem>
                        <AdaptiveTypography variant="body2">{translator("invite.invitePeople")}</AdaptiveTypography>
                    </MenuItem>

                    <Divider />
                    <MenuItem onClick={onToggleTheme}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ width: "100%" }}
                        >
                            <AdaptiveTypography variant="body2">
                                {translator("theme.toggle", {
                                    mode: mode === "dark" ? translator("theme.light") : translator("theme.dark"),
                                })}
                            </AdaptiveTypography>
                            {mode === "dark" ? (
                                <LightModeIcon sx={{ color: "grey.500" }} />
                            ) : (
                                <DarkModeIcon sx={{ color: "grey.500" }} />
                            )}
                        </Stack>
                    </MenuItem>
                    <MenuItem component={Link} to={ROUTES.ACCOUNT}>
                        <AdaptiveTypography variant="body2">{translator("preferences")}</AdaptiveTypography>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={signOut}>
                        <AdaptiveTypography variant="body2">{translator("auth.signOut")}</AdaptiveTypography>
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

export default MainSidebarHeader;
