import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControlLabel,
    FormGroup,
    Typography,
} from "@mui/material";
import CustomDialogTitle from "../CustomDialogTitle";
import { translator } from "../../../i18n/translator";
import { useAppSelector } from "../../../store";
import { PoolRole } from "groupphoto-models";
import { useState } from "react";
import { stringInitials } from "../../../lib/helpers";
import { usePoolService } from "../../../services";
import { LoadingButton } from "@mui/lab";
import { useDialog, usePool } from "../../../hooks";
import { useSearchParams } from "react-router-dom";

const AddToPoolDialog: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [selectedPools, setSelectedPools] = useState<string[]>([]);

    const { referenceId: poolId } = usePool();
    const pools = useAppSelector((state) => state.pools);
    const selectedAssets = useAppSelector((state) => state.selection.selectedAssets);
    const selectedClusters = useAppSelector((state) => state.selection.selectedClusters);

    const [search] = useSearchParams();
    const viewMode = search.get("mode");
    const isTimeline = viewMode && viewMode === "timeline" ? true : false;

    const { onCopyAssetsToPool } = usePoolService();
    const { fullScreen, onClose } = useDialog();

    const onPoolSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        setSelectedPools((prevSelectedPools) => {
            if (prevSelectedPools.includes(value)) {
                return prevSelectedPools.filter((p) => p !== value);
            }
            return [...prevSelectedPools, value];
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();

            if (selectedAssets.length === 0 && selectedClusters.length === 0) {
                throw new Error("No assets to copy");
            }

            if (selectedPools.length === 0) {
                throw new Error("No pools selected");
            }

            if (loading) return;

            setLoading(true);
            // await Promise.all(
            //     selectedPools.map((poolId) => onCopyAssetsToPool(poolId, selectedAssets, selectedClusters))
            // );
            await Promise.all(selectedPools.map((poolId) => onCopyAssetsToPool(poolId, selectedAssets, [])));

            setLoading(false);
            setSelectedPools([]);
            onClose();
        } catch (e) {
            console.error(e);
        }
    };

    const filteredPools = pools
        .filter((p) => p.poolId !== poolId && p.id !== poolId)
        .filter((p) => p.role && p.role !== PoolRole.Viewer);

    if (!poolId) return null;

    return (
        <Dialog open fullWidth fullScreen={fullScreen} maxWidth="sm" onClose={onClose}>
            <CustomDialogTitle onClose={onClose}>{translator("pools.addToPool")}</CustomDialogTitle>
            <DialogContent>
                {isTimeline && selectedAssets.length > 0 && (
                    <Box id="addToPoolForm" component="form" onSubmit={handleSubmit}>
                        <FormGroup sx={{ marginBottom: "10px" }}>
                            {filteredPools.map((p) => (
                                <FormControlLabel
                                    key={p.id}
                                    control={
                                        <Checkbox
                                            checked={selectedPools.includes(p.poolId || p.id)}
                                            onChange={onPoolSelectionChange}
                                            value={p.poolId || p.id}
                                        />
                                    }
                                    label={
                                        <Box display="flex" alignItems="center">
                                            <Avatar
                                                sx={{
                                                    bgcolor: p.myColorTag || p.colorTag,
                                                }}
                                            >
                                                {stringInitials(p.myTitle || p.title)}
                                            </Avatar>
                                            <Typography sx={{ marginLeft: "10px" }}>
                                                {p.myTitle || p.title}{" "}
                                                {p.owner && <span>(from {p.owner.displayName})</span>}
                                            </Typography>
                                        </Box>
                                    }
                                    labelPlacement="start"
                                    sx={{
                                        justifyContent: "space-between",
                                        marginTop: "10px",
                                        marginLeft: 0,
                                    }}
                                />
                            ))}
                        </FormGroup>
                    </Box>
                )}
                {isTimeline && selectedAssets.length === 0 && <DialogContentText>No assets to copy</DialogContentText>}
                {!isTimeline && <DialogContentText>Feature coming soon for clusters</DialogContentText>}
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={onClose}>
                    {translator("cancel")}
                </Button>
                {isTimeline && (
                    <LoadingButton
                        form="addToPoolForm"
                        loading={loading}
                        disabled={loading || selectedAssets.length === 0}
                        type="submit"
                    >
                        {translator("confirm")}
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default AddToPoolDialog;
