import { useMemo } from "react";
import { themeConfig } from "./theme";
import { Routes, Route, useLocation } from "react-router-dom";
import { useApp } from "./hooks";
import { useAppSelector } from "./store";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { PrivateRoute } from "./PrivateRoute";
import { ROUTES } from "./routes";
import Account from "./screens/Account";
import AccountDigest from "./screens/Account/Digest";
import AccountUpgrade from "./screens/Account/Upgrade";
import AccountSubscription from "./screens/Account/Subscription";
import Authentication from "./screens/Authentication";
import DeleteAccount from "./screens/DeleteAccount";
import DeleteAccountCancelled from "./screens/DeleteAccountCancelled";
import ForgotPassword from "./screens/ForgotPassword";
import Home from "./screens/Home";
import Gallery from "./screens/Gallery";
import Invite from "./screens/Invite";
import LoginPassword from "./screens/LoginPassword";
import RegisterPassword from "./screens/RegisterPassword";
import Storybooks from "./screens/Storybooks";
import MainLayout from "./components/Layouts/MainLayout";
import AccountLayout from "./components/Layouts/AccountLayout";

import CreatePoolDialog from "./components/Dialog/CreatePoolDialog";
import EditNameDialog from "./components/Dialog/EditNameDialog";
import EditPhoneDialog from "./components/Dialog/EditPhoneDialog";
import EditUsernameDialog from "./components/Dialog/EditUsernameDialog";
import CreateStorybookDialog from "./components/Dialog/CreateStorybookDialog";
import KeyboardHelpDialog from "./components/Dialog/KeyboardHelpDialog";
import SharingDialog from "./components/Dialog/SharingDialog";
import PoolSettingsDialog from "./components/Dialog/PoolSettings";
import AddMediaDialog from "./components/Dialog/AddMediaDialog";
import UpdateTakenAtDialog from "./components/Dialog/UpdateTakenAtDialog";
import AddToPoolDialog from "./components/Dialog/AddToPoolDialog";
import MediaViewer from "./screens/MediaViewer";

function App() {
    useApp();

    const location = useLocation();
    const state = location.state as { dialogLocation?: Location };

    const themeMode = useAppSelector((state) => state.themeMode.mode);

    const theme = useMemo(() => {
        return createTheme({
            ...themeConfig,
            palette: { ...themeConfig.palette, mode: themeMode },
        });
    }, [themeMode]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes location={state?.dialogLocation || location}>
                <Route path={ROUTES.LOGIN} element={<Authentication />} />
                <Route path={ROUTES.LOGIN_PASSWORD} element={<LoginPassword />} />
                <Route path={ROUTES.REGISTER} element={<Authentication />} />
                <Route path={ROUTES.REGISTER_PASSWORD} element={<RegisterPassword />} />
                <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />

                <Route element={<PrivateRoute children={<MainLayout />} />}>
                    <Route path={ROUTES.HOME} element={<Home />} />
                    <Route path={ROUTES.POOL} element={<Gallery />} />
                    <Route path={ROUTES.STORYBOOK} element={<Storybooks />} />
                </Route>

                <Route path={ROUTES.MEDIA_VIEWER_TIMELINE} element={<PrivateRoute children={<MediaViewer />} />} />
                <Route path={ROUTES.MEDIA_VIEWER_CLUSTER} element={<PrivateRoute children={<MediaViewer />} />} />

                <Route element={<PrivateRoute children={<AccountLayout />} />}>
                    <Route path={ROUTES.ACCOUNT} element={<Account />} />
                    <Route path={ROUTES.ACCOUNT_DIGEST} element={<AccountDigest />} />
                    <Route path={ROUTES.ACCOUNT_SUBSCRIPTION} element={<AccountSubscription />} />
                    <Route path={ROUTES.ACCOUNT_UPGRADE} element={<AccountUpgrade />} />
                </Route>

                <Route path={ROUTES.ACCOUNT_DELETE_CONFIRM} element={<PrivateRoute children={<DeleteAccount />} />} />
                <Route
                    path={ROUTES.ACCOUNT_CANCELLED_DELETE}
                    element={<PrivateRoute children={<DeleteAccountCancelled />} />}
                />
                <Route path={ROUTES.INVITE} element={<Invite />} />
            </Routes>

            {state?.dialogLocation && (
                <Routes>
                    <Route path={ROUTES.CREATE_POOL} element={<CreatePoolDialog />} />
                    <Route path={ROUTES.ACCOUNT_EDIT_NAME} element={<EditNameDialog />} />
                    <Route path={ROUTES.ACCOUNT_EDIT_PHONE} element={<EditPhoneDialog />} />
                    <Route path={ROUTES.ACCOUNT_EDIT_USERNAME} element={<EditUsernameDialog />} />
                    <Route path={ROUTES.CREATE_STORYBOOK} element={<CreateStorybookDialog />} />

                    <Route path={ROUTES.POOL_ADD_MEDIA} element={<AddMediaDialog />} />
                    <Route path={ROUTES.INVITE_TO_POOL} element={<SharingDialog />} />
                    <Route path={ROUTES.POOL_SETTINGS} element={<PoolSettingsDialog />} />
                    <Route path={ROUTES.KEYBOARD_SHORTCUTS} element={<KeyboardHelpDialog />} />
                    <Route path={ROUTES.POOL_ADD} element={<AddToPoolDialog />} />
                    <Route path={ROUTES.POOL_ASSET_UPDATE} element={<UpdateTakenAtDialog />} />
                </Routes>
            )}
        </ThemeProvider>
    );
}

export default App;
