import { QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { getAssetPreview, getAssetThumbnail } from "../lib/helpers";
import { ModifiedAsset, ModifiedAssetSummary } from "../lib/interfaces";
// import * as Sentry from '@sentry/react';

export const generateAssetSummary = (
    asset: ModifiedAsset
): ModifiedAssetSummary => {
    const thumbnail = getAssetThumbnail(asset) as any;
    const highres = getAssetPreview(asset) as any;

    const thumbnailURL = thumbnail?.path;
    const highResURL = highres?.path;

    return {
        id: asset.id ?? "",

        // @ts-ignore
        poolId: asset.poolId,
        assetId: asset.fileId,
        thumbnailURL,
        highResURL,
        mediaType: asset.mediaType,
        rating: asset.rating,
        score: asset.score,
        views: asset.views,
        takenAt:
            typeof asset.takenAt === "number"
                ? new Date(asset.takenAt)
                : asset.takenAt.toDate(),
        isArchived: false,
    };
};

const AssetConverter = {
    toFirestore: (asset) => asset,
    fromFirestore: (
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ) => {
        const asset: any = snapshot.data(options);

        // if (!thumbnailURL) {
        //     Sentry.captureException(`Asset ${asset.id} doesn't have a thumbnail.`)
        // }

        // if (!highResURL) {
        //     Sentry.captureException(`Asset ${asset.id} doesn't have a high-res rendering.`)
        // }

        return generateAssetSummary(asset);
    },
};

export default AssetConverter;
