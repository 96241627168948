import { Box, Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDialog } from "../../../hooks";
import { translator } from "../../../i18n/translator";
import { useProfileService } from "../../../services";
import { useAppSelector } from "../../../store";
import CustomDialogTitle from "../CustomDialogTitle";

const EditUsernameDialog: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");

    const ProfileService = useProfileService();
    const { fullScreen, onClose } = useDialog();

    const user = useAppSelector((state) => state.user);

    useEffect(() => {
        if (user) {
            setUsername(user.details.username ?? "");
        }
    }, [user]);

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setUsername(value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();

            if (loading || !username) return;

            setLoading(true);

            await ProfileService.updateProfile({
                name: user.details.fullName,
                username: username,
                phone: user.details.phone,
            });

            setLoading(false);
            onClose();
        } catch (e) {
            setLoading(false);
            console.error(e);
        }
    };

    return (
        <Dialog fullScreen={fullScreen} open onClose={onClose}>
            <CustomDialogTitle onClose={onClose}>Edit Username</CustomDialogTitle>
            <DialogContent>
                <Box id="editUsernameForm" component="form" mt={3} onSubmit={handleSubmit}>
                    <TextField fullWidth required value={username} onChange={handleTextChange} label="Username" />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} type="button" onClick={onClose}>
                    {translator("cancel")}
                </Button>
                <Button form="editUsernameForm" disabled={loading} type="submit">
                    {translator("update")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditUsernameDialog;
