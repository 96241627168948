import {
    AppBar,
    Box,
    Button,
    Divider,
    Link,
    Paper,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useAuthenticationStyles } from "../hooks";
import { translator } from "../i18n/translator";
import { LogoHorizontal } from "../icons";
import { ROUTES } from "../routes";

const DeleteAccountCancelled: React.FC = () => {
    const styles = useAuthenticationStyles();

    return (
        <Box sx={styles.wrapperStyle()}>
            <AppBar position="static" sx={styles.appBarStyle()}>
                <Toolbar>
                    <RouterLink to={ROUTES.ACCOUNT} style={{ lineHeight: 1 }}>
                        <LogoHorizontal style={{ width: "150px" }} />
                    </RouterLink>

                    <Box sx={{ flexGrow: 1 }}></Box>

                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        <Link
                            href="https://groupphoto.com/legal/terms-of-service"
                            target="_blank"
                            rel="nofollow"
                        >
                            {translator("terms_of_service")}
                        </Link>
                        <Link
                            href="https://groupphoto.com/legal/privacy-policy"
                            target="_blank"
                            rel="nofollow"
                        >
                            {translator("privacy_policy")}
                        </Link>
                    </Stack>
                </Toolbar>
            </AppBar>

            <Paper
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    margin: "60px auto 0",
                    padding: "40px",
                    width: "100%",
                    maxWidth: "1000px",
                    borderRadius: "12px",
                }}
            >
                <Typography
                    variant="h4"
                    component="h4"
                    sx={{
                        color: (theme) =>
                            theme.palette.mode === "light"
                                ? "#062f5d"
                                : undefined,
                    }}
                >
                    {translator("account.deletionCancelled")}
                </Typography>

                <Typography sx={{ color: "#57585e" }}>
                    {translator("account.accountRestored")}
                </Typography>

                <Button
                    disableElevation
                    variant="contained"
                    component={RouterLink}
                    to={ROUTES.ACCOUNT}
                    replace
                    sx={{
                        marginTop: "66px",
                        maxWidth: "327px",
                        width: "100%",
                        textTransform: "none",
                    }}
                >
                    {translator("okay")}
                </Button>
            </Paper>
        </Box>
    );
};

export default DeleteAccountCancelled;
