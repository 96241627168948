import { Token } from "@stripe/stripe-js";
import { logEvent } from "firebase/analytics";
import { useCallback } from "react";
import { analytics } from "../firebase";
import { http } from "../http";
import { ANALYTIC_EVENTS } from "../lib/constants";
import { Plan } from "../lib/interfaces";

const useSubscriptionService = () => {
    const createCustomer = async (payload: {
        name: string;
        email: string;
        country: string;
        state: string;
        zip: string;
    }) => {
        try {
            const { data } = await http.post("/api/user/subscription/customer", payload);

            return data;
        } catch (error) {
            throw error;
        }
    };

    const createSubscription = async (price: Plan) => {
        try {
            const { data } = await http.post("/api/user/subscription/create", {
                plan: price.plan,
            });

            return data;
        } catch (e: any) {
            throw e;
        }
    };

    const startSubscription = async (token: Token) => {
        try {
            const { data } = await http.post("/api/user/subscription/start", {
                source: token.id,
            });
            return data;
        } catch (error) {
            throw error;
        }
    };

    const cancelSubscription = async () => {
        try {
            const { data } = await http.delete("/api/user/subscription");

            return data;
        } catch (error) {
            throw error;
        }
    };

    const changeSubscriptionPlan = async (plan: string) => {
        try {
            const { data } = await http.put("/api/user/subscription", { plan });

            return data;
        } catch (error) {
            throw error;
        }
    };

    const fetchSubscription = useCallback(async () => {
        try {
            const { data } = await http.get("/api/user/subscription");

            return data;
        } catch (error) {
            throw error;
        }
    }, []);

    const updatePaymentMethod = async (paymentMethodId: string) => {
        try {
            const { data } = await http.put("/api/user/subscription/payment", {
                paymentMethodId,
            });

            return data;
        } catch (error) {
            throw error;
        }
    };

    const updateUser = async (values) => {
        try {
            const { data } = await http.put("/api/user/subscription/customer", values);

            logEvent(analytics, ANALYTIC_EVENTS.PURCHASED_SUBSCRIPTION);

            return data;
        } catch (error) {
            throw error;
        }
    };

    const createSubscriptionSession = async (plan: string) => {
        try {
            const { data } = await http.post("/api/user/subscription/create-session", { plan: plan });

            return data;
        } catch (e) {
            throw e;
        }
    };

    const createCustomerPortal = async () => {
        try {
            const { data } = await http.post("/api/user/subscription/create-portal");

            return data.url;
        } catch (e) {
            throw e;
        }
    };

    const reactivateSubscription = async () => {
        try {
            const { data } = await http.post("/api/user/subscription/reactivate");

            return data;
        } catch (e) {
            throw e;
        }
    };

    return {
        createCustomer,
        createSubscription,
        createSubscriptionSession,
        createCustomerPortal,
        changeSubscriptionPlan,
        cancelSubscription,
        fetchSubscription,
        reactivateSubscription,
        startSubscription,
        updatePaymentMethod,
        updateUser,
    };
};

export default useSubscriptionService;
