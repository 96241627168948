import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Paper, Stack, Typography, useTheme } from "@mui/material";
import { translator } from "../../../i18n/translator";
import { useAppSelector } from "../../../store";
import dayjs from "dayjs";
import { ROUTES } from "../../../routes";
import { LoadingButton } from "@mui/lab";
import { useSubscriptionService } from "../../../services";
import SkeletonLoader from "./SkeletonLoader";

const AccountSubscription: React.FC = () => {
    const [loading, setLoading] = useState(false);

    const user = useAppSelector((state) => state.user);
    const theme = useTheme();

    const { createCustomerPortal, cancelSubscription, reactivateSubscription } = useSubscriptionService();

    const onCancel = async () => {
        try {
            if (loading) return;

            setLoading(true);

            await cancelSubscription();
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const showSubscription = useMemo(() => {
        if (!user) return false;
        if (!user.plan.stripe) return false;
        if (!user.plan.stripe.subscription) return false;

        return true;
    }, [user]);

    const showCardDetails = useMemo(() => {
        if (!user) return false;
        if (!user.plan.stripe) return false;
        if (!user.plan.stripe.card) return false;

        return true;
    }, [user]);

    const onRedirectToPortal = async () => {
        try {
            if (loading) return;
            setLoading(true);
            const url = await createCustomerPortal();
            if (url) {
                window.location.replace(url);
            } else {
                throw new Error("No URL to redirect");
            }
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    const onReactivate = async () => {
        try {
            if (loading) return;

            setLoading(true);
            await reactivateSubscription();
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    if (!user) {
        return <SkeletonLoader />;
    }

    let periodEnd = user.plan.stripe?.subscription?.periodEnd ?? null;
    let cancelAtPeriodEnd = user.plan.stripe?.subscription?.cancelAtPeriodEnd ?? false;
    let status = user.plan.stripe?.subscription?.status ?? "incomplete";

    return (
        <Box>
            <Paper elevation={theme.palette.mode === "light" ? 3 : undefined} sx={{ mt: 5, mb: 2, p: 2 }}>
                {!showSubscription && (
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Box>
                            <Typography variant="h5" color="primary">
                                {user.plan.storage.total}
                            </Typography>
                            <Typography display="block" variant="body2">
                                Group Photo {translator(user.plan.type)}
                            </Typography>
                        </Box>

                        {!showCardDetails && (
                            <Button component={Link} to={ROUTES.ACCOUNT_UPGRADE} disabled={loading} variant="contained">
                                {translator("upgrade")}
                            </Button>
                        )}
                    </Box>
                )}

                {showSubscription && (
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Box>
                            <Typography variant="h5" color="primary">
                                {user.plan.storage.total}
                            </Typography>
                            <Typography display="block" variant="body2">
                                Group Photo {translator(user.plan.type)}
                            </Typography>
                            {periodEnd && (
                                <Typography variant="subtitle2" color="grey.500">
                                    {cancelAtPeriodEnd ? "Expiring" : "Renewing"} on{" "}
                                    {dayjs(periodEnd).format("DD/MM/YYYY")}
                                </Typography>
                            )}
                        </Box>
                        <Stack direction="row" spacing={2}>
                            {status !== "canceled" && !cancelAtPeriodEnd && (
                                <LoadingButton disabled={loading} loading={loading} onClick={onCancel}>
                                    {translator("cancel")}
                                </LoadingButton>
                            )}

                            {!cancelAtPeriodEnd && (
                                <Button
                                    component={Link}
                                    disabled={loading}
                                    variant="contained"
                                    to={ROUTES.ACCOUNT_UPGRADE}
                                    replace
                                >
                                    {translator("subscription.changePlan")}
                                </Button>
                            )}

                            {cancelAtPeriodEnd && (
                                <Button variant="contained" onClick={onReactivate}>
                                    Reactivate
                                </Button>
                            )}
                        </Stack>
                    </Box>
                )}
            </Paper>

            {showSubscription && (
                <LoadingButton loading={loading} variant="text" onClick={onRedirectToPortal}>
                    Manage billing details
                </LoadingButton>
            )}
        </Box>
    );
};

export default AccountSubscription;
