import { generatePath, Link, NavLink, useLocation } from "react-router-dom";

import { Box, IconButton, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { ROUTES } from "../../../routes";
import { translator } from "../../../i18n/translator";
import SkeletonList from "./SkeletonList";
import { useAppDispatch } from "../../../store";
import { closeMainSidebar } from "../../../store/sidebarSlice";

const PoolsList = ({ user, pools }) => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const closeSidebar = () => {
        dispatch(closeMainSidebar());
    };

    return (
        <List component="nav" disablePadding>
            <Box sx={{ pb: 2 }}>
                <ListItem
                    alignItems="flex-start"
                    sx={{
                        pl: 2,
                        pr: "6px",
                    }}
                >
                    <ListItemText primary={translator("pools.pools")} />
                    <IconButton
                        component={Link}
                        to={ROUTES.CREATE_POOL}
                        state={{ dialogLocation: location }}
                        size="small"
                    >
                        <AddIcon fontSize="inherit" />
                    </IconButton>
                </ListItem>
                {pools.length === 0 && <SkeletonList />}
                {pools.length > 0 &&
                    user &&
                    pools.map((pool) => (
                        <ListItemButton
                            key={pool.id}
                            component={NavLink}
                            title={pool.myTitle || pool.title}
                            to={generatePath(ROUTES.POOL, {
                                orgId: user.uid,
                                id: pool.id,
                            })}
                            sx={{
                                py: 0,
                                minHeight: 32,
                                color: (theme) =>
                                    theme.palette.mode === "dark"
                                        ? "rgba(255,255,255,0.4)"
                                        : theme.palette.common.black,
                                "& .MuiTypography-root": {
                                    fontSize: 14,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                },
                                "&.active .MuiTypography-root": {
                                    color: (theme) =>
                                        theme.palette.mode === "dark"
                                            ? theme.palette.common.white
                                            : theme.palette.common.black,
                                    fontWeight: "900",
                                },
                            }}
                            onClick={closeSidebar}
                        >
                            <ListItemText primary={pool.myTitle || pool.title} />
                        </ListItemButton>
                    ))}
            </Box>
        </List>
    );
};

export default PoolsList;
