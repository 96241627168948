import { createSlice } from "@reduxjs/toolkit";
import { PoolType } from "groupphoto-models";
import { sortByTitle } from "../lib/helpers";
import { ModifiedUserPool } from "../lib/interfaces";

const initialState: ModifiedUserPool[] = [];

export const poolSlice = createSlice({
    name: "pool",
    initialState,
    reducers: {
        insertPoolData: (_, { payload }) => {
            if (payload.pools.length === 0) {
                return [];
            }

            const home_pool = payload.pools.find(
                (pool) => pool.type === PoolType.Home
            );
            const pools_without_home = payload.pools.filter(
                (pools) => pools.type !== PoolType.Home
            );
            const sorted_pools = pools_without_home.sort(sortByTitle);
            const pools = [home_pool].concat(sorted_pools);

            return pools;
        },
        insertOwnerData: (state, { payload }) => {
            if (payload.owners.length === 0) {
                return;
            }

            const { owners } = payload;

            const updatedPools: ModifiedUserPool[] = [];

            for (let i = 0; i < state.length; i++) {
                const pool = state[i];
                const copy = { ...pool };
                const poolId = pool?.poolId || pool?.id;

                if (!poolId) return;

                const owner = owners.find(
                    (o) =>
                        o.poolId === poolId &&
                        o.userId === pool!.createdByUserId
                );

                if (!owner) {
                    updatedPools.push(copy);
                    continue;
                }

                copy.owner = owner.userSummary;
                updatedPools.push(copy);
            }

            return updatedPools;
        },
        insertMemberRole: (state, { payload }) => {
            const roles = payload.roles.filter((r) => r !== undefined);
            if (roles.length === 0) return;

            const updatedPools: ModifiedUserPool[] = [];

            for (let i = 0; i < state.length; i++) {
                const pool = state[i];

                const copy = { ...pool };
                const poolId = pool?.poolId ?? pool?.id;

                if (!poolId) return;

                const role = roles.find((o) => o.poolId === poolId);

                if (!role) {
                    updatedPools.push(copy);
                    continue;
                }

                copy.role = role.role;
                updatedPools.push(copy);
            }

            return updatedPools;
        },
        resetPoolData: () => initialState,
    },
});

export const {
    insertPoolData,
    insertOwnerData,
    insertMemberRole,
    resetPoolData,
} = poolSlice.actions;

export default poolSlice.reducer;
