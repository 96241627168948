import { Box, Link, Typography } from "@mui/material";
import { translator } from "../../i18n/translator";
import { ROUTES } from "../../routes";

const GalleryEmptyAssets = () => (
    <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ p: 2, height: "calc(100% - 49px)" }}
    >
        <Typography>
            {translator("assets.empty", {
                link: (
                    <Link
                        href={`//${ROUTES.MARKETING_DOWNLOADS}`}
                        target="_blank"
                        rel="nofollow"
                    >
                        {translator("assets.downloadsOne")}
                    </Link>
                ),
            })}
        </Typography>
    </Box>
);

export default GalleryEmptyAssets;
