import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import config from "./configs";

const app = initializeApp(config.firebase);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);

if (window.location.hostname === "localhost" && process.env.REACT_APP_DATA_SOURCE === "local") {
    connectAuthEmulator(auth, "http://localhost:9099", {
        disableWarnings: true,
    });
    connectFirestoreEmulator(db, "localhost", 9090);
    connectFunctionsEmulator(functions, "localhost", 5001);
    connectStorageEmulator(storage, "localhost", 9199);
}

export { app, analytics, auth, db, storage };
