import { logEvent } from "firebase/analytics";
import { doc } from "firebase/firestore";
import { Collections } from "groupphoto-models";
import { useCallback } from "react";
import { analytics, db } from "../firebase";
import { http } from "../http";
import { ANALYTIC_EVENTS } from "../lib/constants";

const useAssetRating = () => {
    const incrementCounter = async (id: string) => {
        try {
            logEvent(analytics, ANALYTIC_EVENTS.RATED_ASSET);

            const { data } = await http.post("/api/media/rate", {
                assetId: id,
                rating: 1,
            });

            return data;
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    const decrementCounter = async (id: string) => {
        try {
            logEvent(analytics, ANALYTIC_EVENTS.RATED_ASSET);

            const { data } = await http.post("/api/media/rate", {
                assetId: id,
                rating: -1,
            });

            return data;
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    const getRatingsQuery = useCallback((id: string) => {
        const q = doc(db, Collections.Asset, id);
        return q;
    }, []);

    return {
        incrementCounter,
        decrementCounter,
        getRatingsQuery,
    };
};

export default useAssetRating;
