import {
    useCallback,
    // useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { getClusterCoordinates } from "../lib/helpers";
import { Coordinate, ModifiedCluster, SubCluster } from "../lib/interfaces";
import { useAssetService } from "../services";
import { useAppDispatch } from "../store";
import { insertCrumb, removeCrumb, replaceCrumb, resetBreadcrumbs } from "../store/breadcrumbSlice";
import usePool from "./usePool";

const useCluster = () => {
    const prevClusterRef = useRef<ModifiedCluster | null>(null);
    const [cluster, setCluster] = useState<ModifiedCluster | null>(null);
    const [isDoneFetching, setIsDoneFetching] = useState(false);
    const isLoading = useRef<boolean>(false);

    const dispatch = useAppDispatch();

    // Plugin Hooks
    const [search] = useSearchParams();

    // Custom Hooks
    const { pool, referenceId, updateQueryParams } = usePool();

    // Services
    const { fetchClusters } = useAssetService();

    useEffect(() => {
        if (referenceId && !isLoading.current) {
            const x = search.get("x") ?? "0";
            const y = search.get("y") ?? "0";

            const parsedX = parseInt(x);
            const parsedY = parseInt(y);

            const _cluster = prevClusterRef.current;

            if (!_cluster || _cluster.x !== parsedX || _cluster.y !== parsedY) {
                isLoading.current = true;
                reset();

                fetchClusters(referenceId, parsedX, parsedY)
                    .then((fetchedCluster) => {
                        isLoading.current = false;

                        if (fetchedCluster) {
                            if (fetchedCluster.y === 0 && fetchedCluster.x === 0) {
                                dispatch(resetBreadcrumbs());
                            } else if (_cluster && _cluster.y === fetchedCluster.y) {
                                dispatch(replaceCrumb(getClusterCoordinates(fetchedCluster)));
                            } else if (_cluster && _cluster.y > fetchedCluster.y) {
                                dispatch(removeCrumb(getClusterCoordinates(fetchedCluster)));
                            } else {
                                dispatch(insertCrumb(getClusterCoordinates(fetchedCluster)));
                            }
                        }

                        prevClusterRef.current = fetchedCluster;
                        setCluster(fetchedCluster);
                        setIsDoneFetching(true);
                    })
                    .catch((e) => {
                        console.error(e);
                        isLoading.current = false;

                        setIsDoneFetching(true);
                    });
            }
        }
    }, [fetchClusters, dispatch, updateQueryParams, referenceId, search]);

    const onReturn = useCallback(async () => {
        try {
            if (!pool) throw new Error("Cannot fetch cluster when pool is undefined");
            const poolId = pool.poolId || pool.id;

            if (!poolId) throw new Error("Pool ID is undefined");
            if (!cluster) return;

            const x = Math.floor(cluster.x / 8);
            const y = cluster.y - 1;

            // Prevent users from fetching negative values
            if (y < 0) return;

            updateQueryParams({ x, y }, true);
        } catch (error) {
            console.error(error);
        }
    }, [pool, cluster, updateQueryParams]);

    const onFetchByCoordinate = async (coordinate: Coordinate) => {
        try {
            updateQueryParams({ x: coordinate.x, y: coordinate.y }, true);
        } catch (e) {
            throw e;
        }
    };

    const onEnterSubCluster = async (subCluster: SubCluster) => {
        try {
            if (!pool) throw new Error("Cannot fetch cluster when pool is undefined");
            const poolId = pool.poolId || pool.id;

            if (!poolId) throw new Error("Pool ID is undefined");

            if (!subCluster || !cluster) return;

            updateQueryParams({ x: subCluster.x, y: subCluster.y }, true);
        } catch (error) {
            console.error(error);
        }
    };

    const onFetchRoot = useCallback(async () => {
        try {
            if (!pool) throw new Error("Cannot fetch cluster when pool is undefined");
            const poolId = pool.poolId || pool.id;

            if (!poolId) throw new Error("Pool ID is undefined");

            updateQueryParams({ x: 0, y: 0 }, true);
        } catch (error) {
            console.error(error);
        }
    }, [pool, updateQueryParams]);

    const onFetchNext = async () => {
        try {
            if (!pool) throw new Error("Cannot fetch cluster when pool is undefined");
            const poolId = pool.poolId || pool.id;

            if (!poolId) throw new Error("Pool ID is undefined");

            if (!cluster) return;
            if (cluster.y === 0) return;

            updateQueryParams({ x: cluster.x + 1, y: cluster.y }, true);
        } catch (e) {
            console.error(e);
        }
    };

    const onFetchPrev = async () => {
        try {
            if (!pool) throw new Error("Cannot fetch cluster when pool is undefined");
            const poolId = pool.poolId || pool.id;

            if (!poolId) throw new Error("Pool ID is undefined");

            if (!cluster || cluster.x === 0) return;
            if (cluster.y === 0) return;

            updateQueryParams({ x: cluster.x - 1, y: cluster.y }, true);
        } catch (e) {
            console.error(e);
        }
    };

    const refetchCluster = async () => {
        try {
            if (!cluster) throw new Error("Cluster is not found, cannot refetch");
            if (!pool) throw new Error("Cannot fetch cluster when pool is undefined");
            const poolId = pool.poolId || pool.id;

            if (!poolId) throw new Error("Pool ID is undefined");

            const fetchedCluster = await fetchClusters(poolId, cluster.x, cluster.y);

            if (!fetchedCluster) throw new Error("No cluster was found");

            setCluster(fetchedCluster);
            return true;
        } catch (e) {
            console.error(e);
        }
    };

    const reset = () => {
        setCluster(null);
        setIsDoneFetching(false);
    };

    return {
        cluster,
        isDoneFetching,
        onFetchByCoordinate,
        onFetchNext,
        onFetchPrev,
        onFetchRoot,
        onReturn,
        onEnterSubCluster,
        refetchCluster,
        reset,
    };
};

export default useCluster;
