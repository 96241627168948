import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { PoolMemberInvitationState } from "groupphoto-models";
import { ROUTES } from "../routes";
import { translator } from "../i18n/translator";
import { auth } from "../firebase";
import { useAuth, useMemberService } from "../services";
import {
    Box,
    Button,
    Container,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    OutlinedInput,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { AppleIcon, FacebookIcon, GoogleIcon, LogoHorizontal, MailIcon } from "../icons";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import AuthButton from "../components/AuthButton";
import { LoadingButton } from "@mui/lab";

const AcceptOrDecline: React.FC<any> = ({ loading, onAccept, onDecline }) => (
    <Stack alignItems="center" spacing={1} sx={{ mt: 2 }}>
        <LoadingButton
            disableElevation
            variant="contained"
            disabled={loading}
            loading={loading}
            onClick={onAccept}
            sx={{ width: "100%", maxWidth: "327px", textTransform: "none" }}
        >
            {translator("accept")}
        </LoadingButton>
        <Button disabled={loading} onClick={onDecline} sx={{ width: "100%", maxWidth: "327px", textTransform: "none" }}>
            {translator("decline")}
        </Button>
    </Stack>
);

const SignUpForm = ({ showPassword, loading, handleShowPassword, values, authType, onChange, onSubmit, onBack }) => {
    const intl = useIntl();

    return (
        <Box component="form" onSubmit={onSubmit} sx={{ my: 4 }}>
            <Typography variant="h6" sx={{ my: 2, textAlign: "center" }}>
                {translator(`${authType}.password`)}
            </Typography>

            <Stack alignItems="center" spacing={2}>
                <TextField
                    type="email"
                    name="email"
                    value={values.identity}
                    onChange={onChange}
                    label={intl.formatMessage({ id: "email" })}
                    disabled
                    sx={{ width: "100%", maxWidth: "297px" }}
                />
                <FormControl
                    variant="outlined"
                    sx={{
                        maxWidth: "298px",
                        width: "100%",
                        background: "#fff",
                    }}
                >
                    <InputLabel>{intl.formatMessage({ id: "password" })} *</InputLabel>
                    <OutlinedInput
                        required
                        name="password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={handleShowPassword} edge="end">
                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label={`${intl.formatMessage({ id: "password" })} *`}
                    />
                </FormControl>
            </Stack>
            <Stack alignItems="center" spacing={1} sx={{ mt: 4 }}>
                <LoadingButton
                    disableElevation
                    variant="contained"
                    disabled={loading}
                    loading={loading}
                    type="submit"
                    sx={{
                        width: "100%",
                        maxWidth: "297px",
                        textTransform: "none",
                    }}
                >
                    {translator("continue")}
                </LoadingButton>
                <Button
                    disabled={loading}
                    type="button"
                    onClick={onBack}
                    sx={{
                        width: "100%",
                        maxWidth: "297px",
                        textTransform: "none",
                    }}
                >
                    {translator("back")}
                </Button>
            </Stack>
        </Box>
    );
};

const Invite: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [isLogin, setIsLogin] = useState(true);
    const [invitation, setInvitation] = useState<any>(null);
    const [valid, setValidity] = useState(false);
    const [message, setMessage] = useState("");
    const [inviteMessage, setInviteMessage] = useState("");

    const [values, setValues] = useState({
        identity: "",
        password: "",
    });

    const [user, setUser] = useState<any>(null);

    const [showDecision, setShowDecisions] = useState(false);
    const [showPasswordForm, setShowPasswordForm] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const { registerWithPassword, loginWithPassword, authSocial } = useAuth();
    const { checkTokenValidity, updateInvitation } = useMemberService();

    const intl = useIntl();
    const navigate = useNavigate();
    const [search] = useSearchParams();

    const token = search.get("token");

    useEffect(() => {
        document.title = "Invite - Group Photo";
    }, []);

    /**
     * Check for token validity and display
     * the appropriate message based on validity result
     */
    useEffect(() => {
        let shouldContinue = true;

        if (!token) return;
        if (!shouldContinue) return;

        checkTokenValidity(token)
            .then((response: any) => {
                if (!shouldContinue) return;

                let formattedMessage: string = "";
                if (response.valid && response.userName) {
                    formattedMessage = intl.formatMessage(
                        { id: "invite.invitedByUser" },
                        { user: response.userName, title: response.poolTitle }
                    );
                } else if (response.valid && !response.userName) {
                    formattedMessage = intl.formatMessage({ id: "invite.invited" }, { title: response.poolTitle });
                }

                setMessage(formattedMessage);
                setInviteMessage(formattedMessage);
                setLoading(false);
                setValidity(response.valid);
                setInvitation({
                    id: response.result.id,
                    identity: response.result.invitation.email,
                });
                setValues((v) => ({
                    ...v,
                    identity: response.result.invitation.email,
                }));
            })
            .catch((e) => {
                setMessage(intl.formatMessage({ id: "invite.invalidInvitation" }));
                setInviteMessage(intl.formatMessage({ id: "invite.invalidInvitation" }));
                setLoading(false);
                console.error(e);
            });

        return () => {
            shouldContinue = false;
        };
    }, [checkTokenValidity, token, intl]);

    /**
     * Subscribe for user authentication
     * Duplicate the pool
     */
    useEffect(() => {
        let shouldContinue = true;

        const subscription = auth.onAuthStateChanged((firebaseUser) => {
            if (!firebaseUser || !shouldContinue) return;
            if (!invitation || !invitation.identity) return;

            // Block off acceptance if user email is not equal
            // to the invited identity
            if (firebaseUser.email !== invitation.identity) {
                setMessage(intl.formatMessage({ id: "invite.invalidInvitation" }));
                setValidity(false);
                setLoading(false);
                return;
            }

            setMessage(inviteMessage);
            setUser(firebaseUser);
            setShowDecisions(true);
            setLoading(false);
        });

        return () => {
            shouldContinue = false;
            subscription();
        };
    }, [valid, invitation, inviteMessage, intl]);

    const authType = () => {
        if (isLogin) return "login";
        return "register";
    };

    const redirectText = authType() === "register" ? "register.redirectText" : "login.redirectText";
    const redirectLinkText = authType() === "register" ? "login" : "signup";

    const handleSubmit = async () => {
        try {
            if (loading) return;

            setLoading(true);

            if (authType() === "register") {
                await registerWithPassword(invitation.identity, values.password);
                return;
            }

            await loginWithPassword(invitation.identity, values.password);
        } catch (error: any) {
            setLoading(false);

            if (error.response) {
                setMessage(error.response.data.message);
            } else {
                setMessage(error.message);
            }

            console.error(error);
        }
    };

    const onAccept = async () => {
        try {
            if (loading || !token) return;

            setLoading(true);

            const result = await updateInvitation(token, PoolMemberInvitationState.Accepted);

            if (!result.success) {
                setLoading(false);
            }

            if (result.success) {
                const path = generatePath(ROUTES.POOL, {
                    orgId: user.uid,
                    id: result.poolId,
                });

                navigate(path);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    const onDecline = async () => {
        try {
            if (loading || !token) return;

            setLoading(true);

            const result = await updateInvitation(token, PoolMemberInvitationState.Declined);

            if (result.success) navigate(ROUTES.HOME);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setValues((v) => ({ ...v, [name]: value }));
    };

    const onSocialButtonClick = async (platform) => {
        try {
            if (loading) return;
            setLoading(true);

            await authSocial(platform);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    const onChangeType = (e) => {
        e.preventDefault();
        setIsLogin((status) => !status);
        setValues((v) => ({ ...v, password: "" }));
    };

    const onFormBack = () => {
        setShowPasswordForm(false);
        setMessage(inviteMessage);
    };

    const showAuthOptions = () => {
        return !loading && !showPasswordForm && !showDecision && !user && valid;
    };

    const onTogglePasswordVisibility = () => {
        setShowPassword((s) => !s);
    };

    const onShowPasswordForm = () => {
        setShowPasswordForm(true);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100vw",
                height: "100vh",
            }}
        >
            <Container maxWidth="sm">
                <LogoHorizontal style={{ display: "block", margin: "0 auto" }} />

                <Typography variant="h5" component="h5" sx={{ display: "block", mt: 2, textAlign: "center" }}>
                    {message}
                </Typography>

                {showDecision && user && valid && (
                    <AcceptOrDecline loading={loading} onAccept={onAccept} onDecline={onDecline} />
                )}

                {showAuthOptions() && (
                    <>
                        <Stack alignItems="center" spacing={1} sx={{ my: 4 }}>
                            <AuthButton startIcon={<GoogleIcon />} onClick={() => onSocialButtonClick("google")}>
                                {translator(`${authType()}.google`)}
                            </AuthButton>
                            <AuthButton startIcon={<FacebookIcon />} onClick={() => onSocialButtonClick("facebook")}>
                                {translator(`${authType()}.facebook`)}
                            </AuthButton>
                            <AuthButton startIcon={<AppleIcon />} onClick={() => onSocialButtonClick("apple")}>
                                {translator(`${authType()}.apple`)}
                            </AuthButton>
                            <AuthButton startIcon={<MailIcon />} onClick={onShowPasswordForm}>
                                {translator(`${authType()}.password`)}
                            </AuthButton>
                        </Stack>

                        <Typography sx={{ textAlign: "center" }}>
                            {translator(redirectText, {
                                link: (
                                    <Link href="/" onClick={onChangeType}>
                                        {translator(redirectLinkText)}
                                    </Link>
                                ),
                            })}
                        </Typography>
                    </>
                )}

                {showPasswordForm && !user && !loading && !showDecision && (
                    <SignUpForm
                        authType={authType()}
                        loading={loading}
                        showPassword={showPassword}
                        handleShowPassword={onTogglePasswordVisibility}
                        values={values}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        onBack={onFormBack}
                    />
                )}

                <Typography
                    sx={{
                        mt: 5,
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#627796",
                    }}
                >
                    {translator("auth.footer", {
                        link: (
                            <Box component="span" sx={{ display: "block" }}>
                                <Link
                                    href="https://groupphoto.com/legal/terms-of-service"
                                    target="_blank"
                                    rel="nofollow"
                                >
                                    {translator("auth.footerProtect")}
                                </Link>
                            </Box>
                        ),
                    })}
                </Typography>
            </Container>
        </Box>
    );
};

export default Invite;
