import { Box, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { assetURLHelper, formatDateRange } from "../../lib/helpers";
import { ModifiedAssetSummary } from "../../lib/interfaces";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { CheckedCircleIcon } from "../../icons";
import GalleryRating from "./GalleryRating";
import { MediaType, PoolRole } from "groupphoto-models";
import { usePool, usePoolPermissions } from "../../hooks";
import { useAppSelector } from "../../store";
import { generatePath, Link } from "react-router-dom";
import { ROUTES } from "../../routes";

const Item = styled(Paper)(({ theme }) => ({
    position: "relative",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: "rgba(0,0,0,0.3)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: "white",
}));

interface Props {
    asset: ModifiedAssetSummary;
    isRateMode: boolean;
    memberRole?: PoolRole;
    onAssetClick: any;
}

const GalleryGridItem: React.FC<Props> = ({ asset, memberRole, isRateMode, onAssetClick }) => {
    const { pool } = usePool();
    const { canRate } = usePoolPermissions(memberRole);
    const selectedAssets = useAppSelector((state) => state.selection.selectedAssets);

    const title = (() => {
        if (asset.description) {
            return asset.description;
        }

        if (typeof asset.takenAt === "number") {
            const takenAt = new Date(asset.takenAt);
            return formatDateRange(takenAt, takenAt);
        }

        return formatDateRange(asset.takenAt, asset.takenAt);
    })();

    const isSelected = () => {
        return selectedAssets.includes(asset.id!);
    };

    return (
        <Link
            to={generatePath(ROUTES.MEDIA_VIEWER_TIMELINE, { id: pool?.id, assetId: asset.id })}
            onClick={onAssetClick}
        >
            <Item
                data-id={asset.id}
                sx={{
                    backgroundImage: `url('${assetURLHelper(asset.thumbnailURL)}')`,
                    border: (theme) => (isSelected() ? `4px solid ${theme.palette.primary.main}` : undefined),
                }}
            >
                {isSelected() && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                        }}
                    >
                        <CheckedCircleIcon />
                    </Box>
                )}

                {asset.takenAt && (
                    <Typography
                        component="span"
                        sx={{
                            position: "absolute",
                            bottom: "10px",
                            left: 0,
                            width: "100%",
                            textShadow: "1px 1px 1px #000",
                            fontSize: { xs: "0.75rem", md: "1rem" },
                            "@media (orientation: landscape) and (max-width: 992px)": {
                                fontSize: "0.75rem !important",
                            },
                        }}
                    >
                        {title}
                    </Typography>
                )}

                {asset.mediaType === MediaType.Video && (
                    <PlayCircleIcon
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            fontSize: { xs: "24px", sm: "48px" },
                            transform: "translate(-50%, -50%)",
                            filter: "drop-shadow(2px 4px 6px rgba(0,0,0,0.2))",
                        }}
                    />
                )}

                {isRateMode && canRate && <GalleryRating assetId={asset.id} />}
            </Item>
        </Link>
    );
};

export default GalleryGridItem;
