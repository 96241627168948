import { fetchSignInMethodsForEmail } from "firebase/auth";
import { useCallback } from "react";
import { auth } from "../firebase";
import { http } from "../http";

const useProfileService = () => {
    const fetchUser = useCallback(async () => {
        try {
            const { data } = await http.get("/api/user");

            return data ?? null;
        } catch (error) {
            throw error;
        }
    }, []);

    const updateProfile = async (payload: {
        name: string;
        username: string;
        phone: string;
    }) => {
        try {
            const { data } = await http.put("/api/user", {
                name: payload.name,
                username: payload.username,
                phone: payload.phone,
            });

            return data;
        } catch (error: any) {
            throw error;
        }
    };

    const updatePassword = async (password: string) => {
        try {
            if (!auth.currentUser) throw new Error("User is not logged in!");
            if (!auth.currentUser.email) throw new Error("No email");

            const methods = await fetchSignInMethodsForEmail(
                auth,
                auth.currentUser.email
            );
            if (!methods.includes("password"))
                throw new Error("Invalid sign in methods");

            const { data } = await http.put("/user/password", { password });

            return data;
        } catch (error) {
            throw error;
        }
    };

    const updateDigestSettings = async (value: string) => {
        try {
            const { data } = await http.put(`/api/user/digest`, {
                frequency: value,
            });

            return data;
        } catch (e) {
            throw e;
        }
    };

    return {
        fetchUser,
        updateProfile,
        updatePassword,
        updateDigestSettings,
    };
};

export default useProfileService;
