import { Theme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

const useDialog = () => {
    const matchesMDDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
    const matchesOrientation = useMediaQuery<Theme>("(orientation: landscape) and (max-width: 991px)");

    const fullScreen = (() => {
        if (matchesMDDown || matchesOrientation) return true;
        return false;
    })();

    const navigate = useNavigate();

    const onClose = () => {
        navigate(-1);
    };

    return {
        fullScreen,
        onClose,
    };
};

export default useDialog;
