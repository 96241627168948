import { useCallback } from "react";
import { logEvent } from "firebase/analytics";
import { PoolMemberInvitationState, PoolRole } from "groupphoto-models";
import { http } from "../http";
import { analytics, auth } from "../firebase";
import { ANALYTIC_EVENTS } from "../lib/constants";

const useMemberService = () => {
    const inviteUsersToPool = async (payload: {
        state: PoolMemberInvitationState;
        invitee: {
            id?: string;
            identity: string;
            role: PoolRole;
        };
        poolId: string;
    }) => {
        try {
            const { data } = await http.post("/api/invitation", {
                memberId: payload.invitee.id ?? null,
                identity: payload.invitee.identity,
                role: payload.invitee.role,
                poolId: payload.poolId,
            });

            logEvent(analytics, ANALYTIC_EVENTS.INVITED_CONTACT_TO_POOL);

            return data;
        } catch (error) {
            throw error;
        }
    };

    const checkTokenValidity = useCallback(async (token: string) => {
        try {
            const { data } = await http.post("/api/invitation/token", {
                token,
            });

            return data;
        } catch (error) {
            throw error;
        }
    }, []);

    const updateInvitation = async (
        token: string,
        action: PoolMemberInvitationState
    ) => {
        try {
            if (!auth.currentUser) throw new Error("User is not signed in");

            const userToken = await auth.currentUser.getIdToken();
            const { data } = await http.put(
                "/api/invitation",
                {
                    token,
                    action,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            if (action === PoolMemberInvitationState.Accepted) {
                logEvent(analytics, ANALYTIC_EVENTS.JOINED_POOL_AS_INVITEE);
            }

            return data;
        } catch (e) {
            throw e;
        }
    };

    const fetchMemberById = useCallback(
        async (poolId: string, userId: string) => {
            try {
                const id = `${poolId}-${userId}`;
                const { data } = await http.get(`/api/member/${id}`);

                return data;
            } catch (e) {
                throw e;
            }
        },
        []
    );

    const updateMember = async (
        id: string | undefined,
        pending: boolean,
        role: PoolRole
    ) => {
        try {
            if (!id) throw new Error("ID is required");

            const { data } = await http.put(`/api/member/${id}`, {
                pending,
                role,
            });

            return data;
        } catch (e) {
            throw e;
        }
    };

    const removeMember = async (memberId: string) => {
        try {
            const { data } = await http.delete(`/api/member/${memberId}`);
            return data;
        } catch (error) {
            throw error;
        }
    };

    return {
        inviteUsersToPool,
        checkTokenValidity,
        updateInvitation,
        fetchMemberById,
        updateMember,
        removeMember,
    };
};

export default useMemberService;
