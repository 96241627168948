import { createSlice } from "@reduxjs/toolkit";
import { ModifiedPhotobook } from "../lib/interfaces";

const initialState: ModifiedPhotobook[] = [];

export const storybookSlice = createSlice({
    name: "photobook",
    initialState,
    reducers: {
        insertStorybooks: (state, { payload }) => {
            const validPhotobooks = payload.filter((p) => {
                if (p.artifacts) return true;
                return false;
            });

            return validPhotobooks;
        },
        resetStorybooks: () => {
            return initialState;
        },
    },
});

export const { insertStorybooks, resetStorybooks } = storybookSlice.actions;

export default storybookSlice.reducer;
