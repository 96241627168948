import {
    Avatar,
    Box,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { Group, MoreVert, QuestionMark, Upload } from "@mui/icons-material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import PhotoAlbumIcon from "@mui/icons-material/PhotoAlbum";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { useEffect, useState } from "react";
import { translator } from "../../i18n/translator";
import { ModifiedCluster, ModifiedUserPool } from "../../lib/interfaces";

import { ClusteringIcon, PoolAddMediaIcon, PoolSelectIcon, PoolSettingsIcon, TimelineIcon } from "../../icons";
import { useAppDispatch, useAppSelector } from "../../store";
import { openUploadDrawer } from "../../store/uploadSlice";
import { PoolType } from "groupphoto-models";
import { useFeatures, usePoolPermissions } from "../../hooks";
import { generatePath, Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes";
import { useAuth } from "../../services";
import { http } from "../../http";

interface Props {
    pool: ModifiedUserPool | null;
    cluster: ModifiedCluster | null;
    isTimeline: boolean;
    isSelectMode: boolean;
    isRateMode: boolean;
    onModeClick: VoidFunction;
    onRateClick: VoidFunction;
    onSelectClick: VoidFunction;
}

interface AvatarProps extends React.PropsWithChildren {
    active?: boolean;
    customIcon?: boolean;
}

const PoolDropdownIconsAvatar: React.FC<AvatarProps> = ({ children, active, customIcon }) => {
    if (customIcon) {
        return (
            <Avatar
                children={children}
                sx={{
                    mr: "12px",
                    background: (theme) =>
                        active
                            ? theme.palette.primary.main
                            : theme.palette.mode === "light"
                            ? "#eff4fa"
                            : "rgba(255,255,255,0.1)",
                }}
            />
        );
    }

    return (
        <Avatar
            children={children}
            sx={{
                mr: "12px",
                background: (theme) =>
                    active
                        ? theme.palette.primary.main
                        : theme.palette.mode === "light"
                        ? "#eff4fa"
                        : "rgba(255,255,255,0.1)",
                "& svg": {
                    width: "20px",
                    "& path, & rect, & polygon, & circle, & line": {
                        stroke: (theme) => (active ? "#fff !important" : `${theme.palette.primary.main} !important`),
                    },
                },
            }}
        />
    );
};

const GalleryToolbarActions: React.FC<Props> = ({
    pool,
    cluster,
    isTimeline,
    isRateMode,
    isSelectMode,
    onModeClick,
    onRateClick,
    onSelectClick,
}) => {
    const [optAnchor, setOptAnchor] = useState<HTMLElement | null>(null);
    const openOptions = Boolean(optAnchor);

    const location = useLocation();
    const navigate = useNavigate();

    const upload = useAppSelector((state) => state.files);
    const selection = useAppSelector((state) => state.selection);
    const dispatch = useAppDispatch();

    const { user: authUser } = useAuth();
    const { canEdit, canRate } = usePoolPermissions(pool?.role);
    const { allowReprocessAssets, allowStorybooks } = useFeatures();

    useEffect(() => {
        let proceed = true;

        const controls = (event: KeyboardEvent) => {
            if (!proceed) return;

            if (event.key === "?" && event.shiftKey) {
                navigate(ROUTES.KEYBOARD_SHORTCUTS, {
                    state: {
                        dialogLocation: location,
                    },
                });
            }
        };

        document.addEventListener("keydown", controls);

        return () => {
            proceed = false;
            document.removeEventListener("keydown", controls);
        };
    }, [location, navigate]);

    const onReprocess = async () => {
        try {
            await http.post("/api/asset/re-transcode", {
                selectedAssets: selection.selectedAssets,
                selectedClusters: selection.selectedClusters,
            });
        } catch (e) {
            console.error(e);
        }
    };

    const hidePoolSettings = () => {
        if (!pool) return true;
        if (pool && pool.type === PoolType.Home) return true;
        return false;
    };

    const onOpenUploadDrawer = () => {
        dispatch(openUploadDrawer());
    };

    const onSetOptAnchor = (event) => {
        setOptAnchor(event.currentTarget);
    };

    const onRemoveOptAnchor = () => {
        setOptAnchor(null);
    };

    if (!authUser || !pool) return null;

    return (
        <Box>
            <Stack direction="row" alignItems="center" spacing={1} sx={{ paddingRight: 2 }}>
                {upload.files.length > 0 && (
                    <Tooltip title="Uploads" arrow>
                        <IconButton onClick={onOpenUploadDrawer}>
                            <Upload />
                        </IconButton>
                    </Tooltip>
                )}

                {!isSelectMode && (
                    <Tooltip title="Pool Members" arrow>
                        <IconButton
                            component={Link}
                            to={generatePath(ROUTES.INVITE_TO_POOL, {
                                orgId: authUser.uid,
                                id: pool.poolId || pool.id,
                            })}
                            state={{ dialogLocation: location }}
                        >
                            <Group />
                        </IconButton>
                    </Tooltip>
                )}

                {isSelectMode && <Typography>With Selected: </Typography>}

                {isSelectMode && allowReprocessAssets && (
                    <Tooltip title={translator("pools.reprocessAssets")}>
                        <IconButton onClick={onReprocess}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                )}

                {isSelectMode && (
                    <Tooltip title={translator("pools.addToPool")}>
                        <IconButton
                            component={Link}
                            to={generatePath(ROUTES.POOL_ADD, { orgId: authUser?.uid, id: pool?.id }) + location.search}
                            state={{ dialogLocation: location }}
                        >
                            <AddBoxIcon />
                        </IconButton>
                    </Tooltip>
                )}

                {isSelectMode && (
                    <Tooltip title={translator("pools.updateTakenAt")}>
                        <IconButton
                            component={Link}
                            to={
                                generatePath(ROUTES.POOL_ASSET_UPDATE, { orgId: authUser?.uid, id: pool?.id }) +
                                location.search
                            }
                            state={{ dialogLocation: location }}
                        >
                            <DateRangeIcon />
                        </IconButton>
                    </Tooltip>
                )}

                <Tooltip title="Options" arrow>
                    <IconButton onClick={onSetOptAnchor}>
                        <MoreVert />
                    </IconButton>
                </Tooltip>
            </Stack>

            <Menu
                open={openOptions}
                anchorEl={optAnchor}
                onClick={onRemoveOptAnchor}
                onClose={onRemoveOptAnchor}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                PaperProps={{
                    sx: {
                        minWidth: "280px",
                    },
                }}
            >
                <MenuItem onClick={onModeClick}>
                    <ListItemIcon>
                        <PoolDropdownIconsAvatar>
                            {isTimeline ? <ClusteringIcon /> : <TimelineIcon />}
                        </PoolDropdownIconsAvatar>
                    </ListItemIcon>
                    <ListItemText>
                        {translator(isTimeline ? "pools.viewModeClustered" : "pools.viewModeTimeline")}
                    </ListItemText>
                </MenuItem>

                {canEdit && (
                    <MenuItem
                        component={Link}
                        to={
                            generatePath(ROUTES.POOL_ADD_MEDIA, { orgId: authUser?.uid, id: pool?.id }) +
                            location.search
                        }
                        state={{ dialogLocation: location }}
                    >
                        <ListItemIcon>
                            <PoolDropdownIconsAvatar>
                                <PoolAddMediaIcon />
                            </PoolDropdownIconsAvatar>
                        </ListItemIcon>
                        <ListItemText>{translator("pools.addMedia")}</ListItemText>
                    </MenuItem>
                )}

                {canEdit && (
                    <MenuItem onClick={onSelectClick}>
                        <ListItemIcon>
                            <PoolDropdownIconsAvatar active={isSelectMode ? true : false}>
                                <PoolSelectIcon />
                            </PoolDropdownIconsAvatar>
                        </ListItemIcon>
                        <ListItemText>{translator("select")}</ListItemText>
                    </MenuItem>
                )}

                {canRate && (
                    <MenuItem onClick={onRateClick}>
                        <ListItemIcon>
                            <PoolDropdownIconsAvatar customIcon active={isRateMode ? true : false}>
                                <StarOutlineIcon
                                    sx={{ color: (theme) => (isRateMode ? "#fff" : theme.palette.primary.main) }}
                                />
                            </PoolDropdownIconsAvatar>
                        </ListItemIcon>
                        <ListItemText>{translator("rate")}</ListItemText>
                    </MenuItem>
                )}

                {allowStorybooks && (
                    <MenuItem
                        component={Link}
                        to={
                            generatePath(ROUTES.CREATE_STORYBOOK, { orgId: authUser?.uid, id: pool?.id }) +
                            location.search
                        }
                        state={{ dialogLocation: location }}
                    >
                        <ListItemIcon>
                            <PoolDropdownIconsAvatar customIcon>
                                <PhotoAlbumIcon color="primary" />
                            </PoolDropdownIconsAvatar>
                        </ListItemIcon>
                        <ListItemText>{translator("storybook")}</ListItemText>
                    </MenuItem>
                )}

                <MenuItem
                    component={Link}
                    to={generatePath(ROUTES.POOL_SETTINGS, { orgId: authUser?.uid, id: pool?.id }) + location.search}
                    state={{ dialogLocation: location }}
                    disabled={hidePoolSettings()}
                >
                    <ListItemIcon>
                        <PoolDropdownIconsAvatar>
                            <PoolSettingsIcon />
                        </PoolDropdownIconsAvatar>
                    </ListItemIcon>
                    <ListItemText secondary={hidePoolSettings() ? "Disabled for Home pool" : ""}>
                        {translator("pools.poolSettings")}
                    </ListItemText>
                </MenuItem>
                <MenuItem component={Link} to={ROUTES.KEYBOARD_SHORTCUTS} state={{ dialogLocation: location }}>
                    <ListItemIcon>
                        <PoolDropdownIconsAvatar customIcon>
                            <QuestionMark color="primary" />
                        </PoolDropdownIconsAvatar>
                    </ListItemIcon>
                    <ListItemText>{translator("pools.keyboardShortcuts")}</ListItemText>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default GalleryToolbarActions;
