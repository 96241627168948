import { http } from "../http";

const useStorybookService = () => {
    const createStorybook = async (values: {
        templates: string[];
        title: string;
        minRating: number;
        cluster: {
            x: number;
            y: number;
            k: number;
            min_taken_at: number;
            max_taken_at: number;
            name: string;
        };
    }) => {
        try {
            await http.post("/api/photobook", values);
        } catch (e) {
            throw e;
        }
    };

    return { createStorybook };
};

export default useStorybookService;
