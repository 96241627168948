import { LOCALES } from "../locales";

const TRANSLATIONS = {
    [LOCALES.SPANISH]: {
        signup: "Regístrate",
        login: "Iniciar sesión",
        logout: "Cerrar sesión",
        email: "Email",
        phone: "Teléfono",
        terms_of_service: "Términos de servicio",
        privacy_policy: "Política de privacidad",
        continue: "Seguir",
        back: "Espalda",
        you: "Tú",
        done: "Hecho",
        modalTitle: 'A "Foto grupal" le gustaría acceder a sus contactos',
        modalDescription:
            "Group Photo sincroniza solo los números de teléfono de su libreta de direcciones con los servidores de Group Photo para ayudarlo a conectarse con otros usuarios de Group Photo",
        suggested: "Sugerir",
        choose_color: "Elige color",
        choose_color_instruction:
            "Seleccione un color personalizado arrastrando el asa alrededor del anillo",
        activity: "Actividad",
        rate: "la valuación",
        select: "Seleccione",
        color: "Color",
        archive: "Archivo",
        save: "ahorrar",
        no: "No",
        invite: "Invitación",
        owner: "Propietario",
        remove: "Eliminar",
        contributor: "Contribuyente",
        viewer: "Espectador",
        moderator: "Moderador",
        voter: "Votante",
        inviteByEmail: "Invitar por correo electrónico",
        participant: "Partícipe",
        votingParticipant: "Participante votante",
        viewerWithInvites: "Espectador con invitaciones",
        next: "Next",

        "email.required": "Correo electronico es requerido",
        "email.invalid": "El correo electrónico es invalido",
        "phone.required": "Se requiere telefono",
        "phone.invalid": "El teléfono no es válido",
        "phone.min": "El teléfono debe tener al menos 10 caracteres.",

        "login.welcome": "Dar una buena acogida",
        "login.google": "Inicia sesión con Google",
        "login.facebook": "Iniciar sesión con Facebook",
        "login.email": "Inicia sesión con Email",
        "login.apple": "Inicia sesión con Apple",
        "login.to_signup": "¿No tienes una cuenta? {signup}",

        "auth.footer":
            "Al usar Group Photo, acepta nuestros {terms} y nuestra {privacy}",

        "register.welcome": "Compartir lo que importa",
        "register.google": "Regístrese con Google",
        "register.facebook": "Registrate con Facebook",
        "register.email": "Regístrese con Email",
        "register.apple": "Regístrese con Apple",
        "register.to_login": "¿Ya tienes una cuenta? {login}",

        "nav.pools": "Quinielas",
        "nav.members": "Miembros",
        "nav.inbox": "Bandeja de entrada",
        "nav.me": "Yo",

        "pools.pools": "Quinielas",
        "pools.new": "Nueva piscina",
        "pools.home": "Hogar",
        "pools.select_pools": "Seleccionar piscinas",
        "pools.invite_contact": "Invitar desde contactos",
        "pools.copy_share": "Copiar y compartir enlace",
        "pools.frequent_contacts": "Contactos de Frequent",
        "pools.poolChat": "Pool Chat",
        "pools.shareToRings": "Compartir en anillos",
        "pools.addMedia": "Agregar medios",
        "pools.poolSettings": "Configuraciones de piscina",
        "pools.managePool": "Administrar grupo",
        "pools.descriptionOptional": "Descripción (opcional)",

        "members.members": "Miembros",
        "members.invite": "Invitar a familiares y amigos, amigas",

        "inbox.inbox": "Bandeja de entrada",
        "inbox.delete": "{user} borraste la foto de {pool}",
        "inbox.added_single": "{user} agregó una foto a {pool}",
        "inbox.added_bulk": "{user} agregó {number} fotos a {pool}",

        "account.account": "Mi cuenta de fotos grupales",
        "account.archive": "Archivo",
        "account.backup": "Configuración de copia de seguridad",
        "account.refer": "Recomendar amigas",
        "account.help": "Servicio de asistencia",
        "account.feedback": "Deja un comentario",
        "account.type": "Tipo de cuenta",
        "account.space_used": "Espacio usado",
        "account.upgrade": "Actualizar a Group Photo Standard",
        "account.archived_pools": "Piscinas archivadas",
        "account.unarchive": "Desarchivar",
        "account.media": "Medios de comunicación",
        "account.backup_history": "Historial de respaldo",

        "upload.choose": "Elige de la biblioteca",
        "upload.take_photo": "Tomar foto o video",
        "upload.cancel": "Cancelar",

        "memberRoles.description1":
            "Puede agregar / eliminar medios. No se puede administrar el grupo.",
        "memberRoles.description2":
            "Solo puede ver medios y chatear. No se puede editar el grupo.",
        "memberRoles.description3":
            "Puede asignar un rol y administrar miembros. Tiene el privilegio de crear, agregar, eliminar y calificar activos",
        "memberRoles.description4":
            "Eliminar otros miembros, administrar miembros, contenido / activos, no puede modificar la configuración del grupo",
        "memberRoles.description5": "Puede chatear y calificar",
        "memberRoles.description6": "Solo puedo chatear",
        "memberRoles.description7": "Solo puede calificar",
        "memberRoles.description8": "Ver solo pero puede invitar personas",
        "memberRoles.description9": "Mostrar permisos avanzados",
        "memberRoles.description10": "Can add assets, chat",
        "memberRoles.description11": "View only",
    },
};

export default TRANSLATIONS;
