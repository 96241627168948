// @ts-nocheck

import { Component, ReactNode } from "react";
import Box from "@mui/material/Box";
import * as Sentry from "@sentry/react";

class ErrorBox extends Component<{}, {}> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    reloadApp() {
        window.location.reload();
    }

    render() {
        return (
            <Box
                position="fixed"
                top={0}
                left={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                width="100%"
                height="100%"
                sx={{
                    background: "#f5fbfc",
                }}
            >
                <h2 style={{ color: "#1d92ff" }}>We're sorry! Something went wrong.</h2>
                <button className="button" onClick={this.reloadApp}>
                    Click here to refresh the page.
                </button>
            </Box>
        );
    }
}

export class ErrorBoundary extends Component<{ children: ReactNode }, { hasError: boolean }> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // // You can also log the error to an error reporting service
        // console.error(error);
    }

    render() {
        return (
            <Sentry.ErrorBoundary showDialog fallback={<ErrorBox />}>
                {this.props.children}
            </Sentry.ErrorBoundary>
        );

        // return this.props.children;
    }
}
