import { LOCALES } from "../locales";

const TRANSLATIONS = {
    [LOCALES.FRENCH]: {
        signup: "S'inscrire",
        login: "S'identifier",
        logout: "Se déconnecter",
        email: "Email",
        phone: "Phone",
        terms_of_service: "Conditions de services",
        privacy_policy: "Politique de confidentialité",
        continue: "Continuer",
        back: "Arrière",
        you: "Tu",
        done: "Terminé",
        modalTitle: '"Photo de groupe" souhaite accéder à vos contacts',
        modalDescription:
            "La photo de groupe synchronise uniquement les numéros de téléphone de votre carnet d'adresses vers les serveurs de photo de groupe pour vous aider à vous connecter avec d'autres utilisateurs de photo de groupe",
        suggested: "Suggéré",
        choose_color: "Choisissez la couleur",
        choose_color_instruction:
            "Sélectionnez une couleur personnalisée en faisant glisser la poignée autour de l'anneau",
        activity: "Activité",
        rate: "Taux",
        select: "Sélectionner",
        color: "Couleur",
        archive: "Archiver",
        save: "Sauver",
        no: "Non",
        invite: "Inviter",
        owner: "Propriétaire",
        remove: "Retirer",
        contributor: "le collaborateur",
        viewer: "le téléspectateur",
        moderator: "le modérateur",
        voter: "le voteur",
        inviteByEmail: "Inviter par email",
        participant: "Participant",
        votingParticipant: "Participant votant",
        viewerWithInvites: "Visionneuse avec invitations",
        next: "Next",

        "email.required": "L'email est requis",
        "email.invalid": "Le courriel est invalide",
        "phone.required": "Le téléphone est requis",
        "phone.invalid": "Le téléphone n'est pas valide",
        "phone.min": "Le téléphone doit contenir au moins 10 caractères",

        "login.welcome": "Nous saluons le retour",
        "login.google": "Connectez-vous avec Google",
        "login.facebook": "Se connecter avec Facebook",
        "login.email": "Connectez-vous par e-mail",
        "login.apple": "Connectez-vous avec Apple",
        "login.to_signup": "Vous n'avez pas de compte? {signup}",

        "auth.footer":
            "En utilisant la photo de groupe, vous acceptez nos {terms} et notre {privacy}",

        "register.welcome": "Sharing What Matters",
        "register.google": "S'inscrire avec Google",
        "register.facebook": "Inscrivez-vous avec Facebook",
        "register.email": "S'enregistrer avec l'adresse e-mail",
        "register.apple": "Inscrivez-vous avec Apple",
        "register.to_login": "Vous avez déjà un compte? {login}",

        "nav.pools": "Piscines",
        "nav.members": "Membres",
        "nav.inbox": "Boîte de réception",
        "nav.me": "Moi",

        "pools.pools": "Piscines",
        "pools.new": "Nouvelle piscine",
        "pools.home": "Accueil",
        "pools.select_pools": "Sélectionnez les piscines",
        "pools.invite_contact": "Inviter des contacts",
        "pools.copy_share": "Copier et partager le lien",
        "pools.frequent_contacts": "Contacts fréquents",
        "pools.poolChat": "Chat en piscine",
        "pools.shareToRings": "Partager aux anneaux",
        "pools.addMedia": "Ajouter des médias",
        "pools.poolSettings": "Paramètres de la piscine",
        "pools.managePool": "Gérer la piscine",
        "pools.descriptionOptional": "Description (facultatif)",

        "members.members": "Membres",
        "members.invite": "Invitez votre famille et vos amis",

        "inbox.inbox": "Boîte de réception",
        "inbox.delete": "{user} a supprimé la photo de {pool}",
        "inbox.added_single": "{user} a ajouté une photo à {pool}",
        "inbox.added_bulk": "{user} a ajouté {number} photos à {pool}",

        "account.account": "Mon compte de photo de groupe",
        "account.archive": "Archiver",
        "account.backup": "Paramètres de sauvegarde",
        "account.refer": "Parrainer des amis",
        "account.help": "Support d'aide",
        "account.feedback": "Laisser les commentaires",
        "account.type": "Type de compte",
        "account.space_used": "Espace utilisé",
        "account.upgrade": "Mise à niveau vers la photo de groupe standard",
        "account.archived_pools": "Pools archivés",
        "account.unarchive": "Désarchiver",
        "account.media": "Médias",
        "account.backup_history": "Historique des sauvegardes",

        "upload.choose": "Choisissez dans la bibliothèque",
        "upload.take_photo": "Prendre une photo ou une vidéo",
        "upload.cancel": "Annuler",

        "memberRoles.description1":
            "Peut ajouter / supprimer des médias. Impossible de gérer la piscine",
        "memberRoles.description2":
            "Ne peut afficher que les médias et le chat. Impossible de modifier le pool",
        "memberRoles.description3":
            "Peut attribuer un rôle et gérer les membres. A le privilège de créer, ajouter, supprimer et évaluer des actifs",
        "memberRoles.description4":
            "Supprimer d'autres membres, gérer les membres, le contenu / les actifs, ne peut pas modifier les paramètres du pool",
        "memberRoles.description5": "Peut discuter et évaluer",
        "memberRoles.description6": "Ne peut discuter que",
        "memberRoles.description7": "Ne peut évaluer que",
        "memberRoles.description8":
            "Afficher uniquement, mais peut inviter des personnes",
        "memberRoles.description9": "Afficher les autorisations avancées",
        "memberRoles.description10": "Can add assets, chat",
        "memberRoles.description11": "View only",
    },
};

export default TRANSLATIONS;
