import { useParams } from "react-router-dom";

import GalleryContent from "./GalleryContent";

const Content: React.FC = () => {
    const { id: urlPoolId } = useParams();

    return <GalleryContent key={urlPoolId} />;
};

export default Content;
