import en from "./en";
import es from "./es";
import de from "./de";
import fr from "./fr";
import jp from "./jp";
import pt from "./pt";
import man from "./man";
import zh from "./zh";

const LANGS = {
    ...en,
    ...es,
    ...de,
    ...fr,
    ...jp,
    ...pt,
    ...man,
    ...zh,
};

export default LANGS;
