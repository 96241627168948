import { useCallback, useEffect, useState } from "react";
import {
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    GoogleAuthProvider,
    isSignInWithEmailLink,
    multiFactor,
    MultiFactorSession,
    OAuthProvider,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    RecaptchaVerifier,
    sendPasswordResetEmail,
    sendSignInLinkToEmail,
    signInWithEmailAndPassword,
    signInWithEmailLink,
    signInWithPhoneNumber,
    signInWithPopup,
    User,
} from "firebase/auth";
import { auth } from "../firebase";
import { useAppDispatch } from "../store";
import { resetPoolData } from "../store/poolSlice";
import { resetUserData } from "../store/userSlice";
import { clearFiles } from "../store/uploadSlice";
import { resetSidebars } from "../store/sidebarSlice";
import { resetBreadcrumbs } from "../store/breadcrumbSlice";
import { resetStorybooks } from "../store/storybookSlice";
import { resetSelection } from "../store/selectionSlice";

const useAuth = () => {
    const [isFetching, setIsFetching] = useState(true);
    const [user, setUser] = useState<User | null>(null);

    const dispatch = useAppDispatch();

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setIsFetching(false);

            if (!user) {
                setUser(null);
                return;
            }

            setUser(user);
        });
    }, []);

    const prepareProvider = (platform: string) => {
        if (platform === "google") {
            return new GoogleAuthProvider();
        } else if (platform === "apple") {
            return new OAuthProvider("apple.com");
        } else if (platform === "facebook") {
            return new FacebookAuthProvider();
        }
    };

    const loginWithPassword = async (email: string, password: string) => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (e) {
            throw e;
        }
    };

    const registerWithPassword = async (email: string, password: string) => {
        try {
            await createUserWithEmailAndPassword(auth, email, password);
        } catch (e) {
            throw e;
        }
    };

    const verifier = (elementID: string, cb?: Function) => {
        return new RecaptchaVerifier(
            elementID,
            {
                size: "invisible",
                callback: cb,
            },
            auth
        );
    };

    const confirmAuthLink = useCallback(async () => {
        try {
            if (!isSignInWithEmailLink(auth, window.location.href)) return false;

            let email = window.localStorage.getItem("emailForSignIn") || "";
            if (!email) {
                email = window.prompt("Please provide your email for confirmation") || "";
            }

            const response = await signInWithEmailLink(auth, email, window.location.href);
            window.localStorage.removeItem("emailForSignIn");
            return response;
        } catch (error) {
            throw error;
        }
    }, []);

    const authSocial = async (platform: string) => {
        try {
            const provider = prepareProvider(platform);

            if (!provider) throw new Error("No provider generated");

            await signInWithPopup(auth, provider);
        } catch (error) {
            throw error;
        }
    };

    const authPhone = async (phone: string, verifier: RecaptchaVerifier) => {
        try {
            const confirmation = await signInWithPhoneNumber(auth, phone, verifier);
            return confirmation;
        } catch (error) {
            throw error;
        }
    };

    const authLink = async (email: string, phone?: string) => {
        try {
            const actionCodeSettings = {
                url: window.location.href,
                handleCodeInApp: true,
            };

            return await sendSignInLinkToEmail(auth, email, actionCodeSettings).then(() => {
                window.localStorage.setItem("emailForSignIn", email);

                if (phone) window.localStorage.setItem("phoneForSignIn", phone);
            });
        } catch (error) {
            throw error;
        }
    };

    const authSMSMFA = async (opts: { phoneNumber?: string; session: MultiFactorSession; multiFactorHint?: any }) => {
        try {
            const provider = new PhoneAuthProvider(auth);

            if (!window.mfaVerifier) throw new Error("MFA Verifier not initialized");

            return provider.verifyPhoneNumber(opts, window.mfaVerifier);
        } catch (error) {
            throw error;
        }
    };

    const enrollMFA = async (id: string, code: string) => {
        try {
            if (!auth.currentUser) throw new Error("Not logged in");

            const credential = PhoneAuthProvider.credential(id, code);
            const assertion = PhoneMultiFactorGenerator.assertion(credential);

            return multiFactor(auth.currentUser).enroll(assertion, "Phone");
        } catch (error) {
            throw error;
        }
    };

    const resolveMFA = async (id: string, code: string, resolver: any) => {
        try {
            const credential = PhoneAuthProvider.credential(id, code);
            const assertion = PhoneMultiFactorGenerator.assertion(credential);

            return resolver.resolveSignIn(assertion);
        } catch (error) {
            throw error;
        }
    };

    const sendResetEmail = async (email: string) => {
        try {
            await sendPasswordResetEmail(auth, email);
        } catch (error) {
            throw error;
        }
    };

    const signOut = async () => {
        try {
            dispatch(resetBreadcrumbs());
            dispatch(resetSidebars());
            dispatch(resetSelection());
            dispatch(resetStorybooks());
            dispatch(resetPoolData());
            dispatch(resetUserData());
            dispatch(clearFiles());

            await auth.signOut();
        } catch (e) {
            throw e;
        }
    };

    return {
        isFetching,
        user,
        loginWithPassword,
        registerWithPassword,
        signOut,

        confirmAuthLink,
        authSocial,
        authPhone,
        authLink,
        authSMSMFA,
        enrollMFA,
        resolveMFA,
        sendResetEmail,
        verifier,
    };
};

export default useAuth;
