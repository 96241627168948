import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

const StepSelectRangeType = ({ value, onTypeChange }) => {
    return (
        <Box sx={{ my: 2 }}>
            <FormControl required>
                <FormLabel
                    sx={{
                        mb: 2,
                        color: "grey.500",
                        fontSize: "0.875rem",
                        fontWeight: 600,
                    }}
                >
                    Generate Storybooks by:
                </FormLabel>
                <RadioGroup name="type" value={value} onChange={onTypeChange}>
                    <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
                    <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
                </RadioGroup>
            </FormControl>
        </Box>
    );
};

export default StepSelectRangeType;
