import { useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import CustomDialogTitle from "../CustomDialogTitle";
import { translator } from "../../../i18n/translator";
import { useAssetService } from "../../../services";
import { useAppSelector } from "../../../store";
import { useDialog } from "../../../hooks";
import { useSearchParams } from "react-router-dom";

const UpdateTakenAtDialog: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState<Dayjs | null>(null);

    const { updateTakenAt } = useAssetService();
    const { fullScreen, onClose } = useDialog();

    const [search] = useSearchParams();
    const viewMode = search.get("mode");
    const isTimeline = viewMode && viewMode === "timeline" ? true : false;

    const selectedAssets = useAppSelector((state) => state.selection.selectedAssets);

    const handleDateChange = (newValue) => {
        setValue(newValue);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            if (!value) return;
            setLoading(true);

            if (selectedAssets.length === 0) return;

            await updateTakenAt(selectedAssets, value.toDate());

            setValue(null);
            setLoading(false);
            onClose();
        } catch (e) {
            console.error(e);
        }
    };

    const disabledSubmit = (() => {
        if (selectedAssets.length === 0) return true;
        else if (!value) return true;
        else return false;
    })();

    return (
        <Dialog open fullWidth maxWidth="sm" fullScreen={fullScreen} onClose={onClose}>
            <CustomDialogTitle onClose={onClose}>{translator("pools.editDateTime")}</CustomDialogTitle>
            <Box component="form" onSubmit={handleSubmit}>
                <DialogContent>
                    {isTimeline && selectedAssets.length > 0 && (
                        <>
                            <Typography variant="h6">Set one date for all</Typography>
                            <DialogContentText marginBottom="20px">
                                This will make the date of all selected items the same.
                            </DialogContentText>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Choose a date"
                                    value={value}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} fullWidth required />}
                                />
                            </LocalizationProvider>
                        </>
                    )}
                    {isTimeline && selectedAssets.length === 0 && (
                        <DialogContentText>No assets to update</DialogContentText>
                    )}
                    {!isTimeline && <DialogContentText>Feature coming soon for clusters</DialogContentText>}
                </DialogContent>
                <DialogActions>
                    <Button type="button" onClick={onClose}>
                        {translator("cancel")}
                    </Button>
                    {isTimeline && (
                        <LoadingButton disabled={disabledSubmit} type="submit" loading={loading}>
                            {translator("update")}
                        </LoadingButton>
                    )}
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default UpdateTakenAtDialog;
