import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Dayjs } from "dayjs";
import { translator } from "../../../i18n/translator";

import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";

import CustomDialogTitle from "../CustomDialogTitle";
import StepDetails from "./StepDetails";
import StepSelectRangeType from "./StepSelectRangeType";
import StepSelectRange from "./StepSelectRange";
import { STORYBOOK_TEMPLATES } from "../../../lib/constants";
import { useStorybookService } from "../../../services";
import { useDialog } from "../../../hooks";

// TEMPORARY: ONLY TEMPLATES WITH 4 SLOTS OR MORE
const templates = STORYBOOK_TEMPLATES.filter((t) => t.slots > 3);

const CreateStorybookDialog: React.FC = () => {
    const [loading, setLoading] = useState(false);

    const { poolId } = useParams();
    const [searchParams] = useSearchParams();

    const [title, setTitle] = useState("");
    const [rangeType, setRangeType] = useState("monthly");
    const [fromDate, setFromDate] = useState<Dayjs | null>(null);
    const [toDate, setToDate] = useState<Dayjs | null>(null);

    const { createStorybook } = useStorybookService();

    const { fullScreen, onClose } = useDialog();

    const resetValues = () => {
        setTitle("");
        setRangeType("monthly");
        setFromDate(null);
        setToDate(null);
    };

    const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setTitle(value);
    };

    const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();

            if (!poolId) throw Error("Pool ID is missing");

            if (loading || !title || !fromDate || !toDate) return;

            setLoading(true);

            const k = 8;
            const x = searchParams.get("x") ?? "0";
            const y = searchParams.get("y") ?? "0";

            const template = templates[Math.floor(Math.random() * templates.length)];
            const min_taken_at = fromDate.startOf("month").unix(); // start of the month
            const max_taken_at = toDate.endOf("month").unix(); // end of the month

            await createStorybook({
                title,
                minRating: 0,
                templates: [template.name],
                cluster: {
                    k: k,
                    y: parseInt(y),
                    x: parseInt(x),
                    min_taken_at,
                    max_taken_at,
                    name: poolId,
                },
            });

            resetValues();
            onClose();
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const onTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setRangeType(value);
    };

    const onDateChange = (newDate, direction: "from" | "to") => {
        if (direction === "from") {
            setFromDate(newDate);
        } else if (direction === "to") {
            setToDate(newDate);
        }
    };

    return (
        <Dialog fullScreen={fullScreen} maxWidth="sm" fullWidth open onClose={onClose}>
            <CustomDialogTitle onClose={onClose}>{translator("storybook.create")}</CustomDialogTitle>
            <DialogContent>
                <Box id="createStorybookForm" component="form" autoComplete="off" mt={2} onSubmit={handleFormSubmit}>
                    <StepDetails loading={loading} title={title} onTitleChange={onTitleChange} />

                    <StepSelectRangeType value={rangeType} onTypeChange={onTypeChange} />

                    <StepSelectRange
                        rangeType={rangeType}
                        fromDate={fromDate}
                        toDate={toDate}
                        onDateChange={onDateChange}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} type="button" onClick={onClose}>
                    {translator("cancel")}
                </Button>
                <Button form="createStorybookForm" disabled={loading} type="submit">
                    {translator("save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateStorybookDialog;
