import { memo } from "react";
import { Typography } from "@mui/material";

const KeyboardListPrimaryText: React.FC<React.PropsWithChildren> = ({ children }) => (
    <Typography variant="body2" color="grey.500" fontWeight={800} gutterBottom>
        {children}
    </Typography>
);

export default memo(KeyboardListPrimaryText);
