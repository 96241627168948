import { useAppSelector } from "../store";

const useFeatures = () => {
    const user = useAppSelector((state) => state.user);

    const allowStorybooks = (() => {
        if (!user) return false;
        if (!user.details.features) return false;
        if (user.details.features.includes("storybooks")) return true;
        return false;
    })();

    const allowReprocessAssets = (() => {
        if (!user) return false;
        if (!user.details.features) return false;
        if (user.details.features.includes("reprocess-assets")) return true;
        return false;
    })();

    return {
        allowStorybooks,
        allowReprocessAssets,
    };
};

export default useFeatures;
