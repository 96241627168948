import { LOCALES } from "../locales";

const TRANSLATIONS = {
    [LOCALES.CANTONESE]: {
        signup: "註冊",
        login: "登錄",
        logout: "Logout",
        email: "Email",
        phone: "Phone",
        terms_of_service: "服務條款",
        privacy_policy: "私隱政策",
        continue: "继续！",
        back: "背",
        you: "You",
        done: "做",
        modalTitle: "徜徉群張照片徜徉想訪問您的聯繫人",
        modalDescription:
            "群組照片僅將通訊簿中的電話號碼同步到群組照片服務器,以幫助您與其他群組照片用戶聯繫",
        suggested: "建議",
        choose_color: "揀顏色",
        choose_color_instruction: "透過將手柄拖動環周圍嚟選擇自定義顏色",
        activity: "活動",
        rate: "率",
        select: "選擇",
        color: "顏色",
        archive: "檔案",
        save: "救",
        no: "唔係",
        invite: "邀請",
        owner: "所有者",
        remove: "刪除",
        contributor: "貢獻",
        viewer: "觀眾",
        moderator: "主持人",
        voter: "選民",
        inviteByEmail: "透過電子郵件邀請",
        participant: "參與者",
        votingParticipant: "投票參與者",
        viewerWithInvites: "帶邀請嘅查看器",
        next: "Next",

        "email.required": "电子邮件系必需嘅",
        "email.invalid": "Email is invalid",
        "phone.required": "电话系必需嘅",
        "phone.invalid": "Phone is invalid",
        "phone.min": "Phone must have at least 10 characters",

        "login.welcome": "欢迎返嚟",
        "login.google": "登录谷歌",
        "login.facebook": "登录Facebook",
        "login.email": "使用电子邮件登录",
        "login.apple": "登录苹果",
        "login.to_signup": "冇账户？ {signup}",

        "auth.footer":
            "通过使用合照，你同意我哋服务条款同私隐政策！ {terms} and {privacy}",

        "register.welcome": "Sharing What Matters",
        "register.google": "注册谷歌",
        "register.facebook": "注册Facebook",
        "register.email": "使用电子邮件注册",
        "register.apple": "与苹果签约",
        "register.to_login": "已经有账户？ {login}",

        "nav.pools": "池",
        "nav.members": "成員",
        "nav.inbox": "收件箱",
        "nav.me": "我",

        "pools.pools": "池",
        "pools.new": "新池",
        "pools.home": "家",
        "pools.select_pools": "揀池",
        "pools.invite_contact": "來自聯繫人嘅邀請",
        "pools.copy_share": "複製和共享連結",
        "pools.frequent_contacts": "頻繁聯繫",
        "pools.poolChat": "池傾偈",
        "pools.shareToRings": "分享到戒指",
        "pools.addMedia": "添加媒體",
        "pools.poolSettings": "池設置",
        "pools.managePool": "管理池",
        "pools.descriptionOptional": "描述(可選)",

        "members.members": "成員",
        "members.invite": "邀請家人和朋友",

        "inbox.inbox": "收件箱",
        "inbox.delete": "{user} 你刪除了照片 {pool}",
        "inbox.added_single": "{user} 將照片添加到 {pool}",
        "inbox.added_bulk": "{user} added {number} photos to {pool}",

        "account.account": "我嘅群組照片账户",
        "account.archive": "檔案",
        "account.backup": "備份設置",
        "account.refer": "推薦好友",
        "account.help": "幫助和支持",
        "account.feedback": "留下反饋",
        "account.type": "帳戶類型",
        "account.space_used": "使用空間",
        "account.upgrade": "升級到群組照片標準",
        "account.archived_pools": "存檔池",
        "account.unarchive": "無存檔",
        "account.media": "媒體",
        "account.backup_history": "備份歷史記錄",

        "upload.choose": "從庫中選擇",
        "upload.take_photo": "拍攝照片或視頻",
        "upload.cancel": "取消",

        "memberRoles.description1": "可以添加/刪除媒體。 無法管理池",
        "memberRoles.description2": "只能查看媒體和傾偈。 無法編輯池",
        "memberRoles.description3":
            "可以分配角色同管理成員。 具有創建、添加、刪除和評價資產的權限",
        "memberRoles.description4":
            "刪除其他成員、管理成員、內容/資產、無法修改池設置",
        "memberRoles.description5": "可以傾偈同評價",
        "memberRoles.description6": "只能傾偈",
        "memberRoles.description7": "只能對",
        "memberRoles.description8": "只查看但可以邀請人員",
        "memberRoles.description9": "顯示高級權限",
        "memberRoles.description10": "Can add assets, chat",
        "memberRoles.description11": "View only",
    },
};

export default TRANSLATIONS;
