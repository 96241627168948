import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemText,
    Paper,
    Skeleton,
    Typography,
} from "@mui/material";

import { translator } from "../../i18n/translator";

const SkeletonIndex: React.FC = () => {
    return (
        <Box mt={5}>
            <Paper
                elevation={0}
                sx={{
                    mb: 4,
                    p: 2,
                    bgcolor: (theme) => theme.palette.action.hover,
                }}
            >
                <Box display="flex">
                    <Box position="relative" flexGrow={1}>
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{
                                position: "absolute",
                                top: "-35px",
                            }}
                        >
                            <Skeleton
                                variant="circular"
                                width={84}
                                height={84}
                                sx={{
                                    opacity: 1,
                                    bgcolor: "#121212",
                                }}
                            />
                            <Typography sx={{ ml: 1 }}>
                                <Skeleton variant="text" width={75} />
                            </Typography>
                        </Box>
                    </Box>
                    <Skeleton
                        variant="rectangular"
                        width={134.72}
                        height={36.5}
                        sx={{ borderRadius: "4px" }}
                    />
                </Box>
                <Paper elevation={1} sx={{ mt: 4 }}>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary="Name"
                                secondary={
                                    <Skeleton variant="text" width={100} />
                                }
                            />
                            <Skeleton
                                variant="rectangular"
                                width={64}
                                height={36.5}
                                sx={{ borderRadius: "4px" }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Username"
                                secondary={
                                    <Skeleton variant="text" width={100} />
                                }
                            />
                            <Skeleton
                                variant="rectangular"
                                width={64}
                                height={36.5}
                                sx={{ borderRadius: "4px" }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Email"
                                secondary={
                                    <Skeleton variant="text" width={200} />
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Phone Number"
                                secondary={
                                    <Skeleton variant="text" width={110} />
                                }
                            />
                            <Skeleton
                                variant="rectangular"
                                width={64}
                                height={36.5}
                                sx={{ borderRadius: "4px" }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Account Type"
                                secondary={
                                    <Skeleton variant="text" width={100} />
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Date Joined"
                                secondary={
                                    <Skeleton variant="text" width={100} />
                                }
                            />
                        </ListItem>
                    </List>
                </Paper>
            </Paper>

            <Divider />

            <Box my={4}>
                <Typography variant="h6">{translator("storage")}</Typography>

                <Paper
                    elevation={0}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: 2,
                        p: 2,
                        bgcolor: (theme) => theme.palette.action.hover,
                    }}
                >
                    <Skeleton variant="circular" width={100} height={100} />
                    <Skeleton variant="text" width={100} />
                </Paper>
            </Box>

            <Divider />

            <Box mt={4}>
                <Typography variant="h6">
                    {translator("account.deleteAccount")}
                </Typography>

                <Typography variant="body2" mt={3} mb={2}>
                    {translator("account.deleteAccountDesc")}
                </Typography>

                <Skeleton
                    variant="rectangular"
                    width={141.38}
                    height={36.5}
                    sx={{ borderRadius: "4px" }}
                />
            </Box>
        </Box>
    );
};

export default SkeletonIndex;
