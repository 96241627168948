import { LOCALES } from "../locales";

const TRANSLATIONS = {
    [LOCALES.JAPANESE]: {
        signup: "サインアップ",
        login: "ログインする",
        logout: "ログアウト",
        email: "Email",
        phone: "Phone",
        terms_of_service: "利用規約",
        privacy_policy: "個人情報保護方針",
        continue: "継続する",
        back: "バック",
        you: "君は",
        done: "できた",
        modalTitle: "「集合写真」が連絡先へのアクセスを希望しています",
        modalDescription:
            "集合写真は、アドレス帳から集合写真サーバーに電話番号のみを同期し、他の集合写真ユーザーとの接続に役立ちます",
        suggested: "提案",
        choose_color: "色を選択",
        choose_color_instruction:
            "リングの周りのハンドルをドラッグしてカスタムカラーを選択します",
        activity: "アクティビティ",
        rate: "割合",
        select: "選択する",
        color: "色",
        archive: "アーカイブ",
        save: "保存する",
        no: "番号",
        invite: "招待する",
        owner: "オーナー",
        remove: "削除する",
        contributor: "寄稿者",
        viewer: "閲覧者",
        moderator: "モデレータ",
        voter: "有権者",
        inviteByEmail: "メールで招待",
        participant: "参加者",
        votingParticipant: "投票参加者",
        viewerWithInvites: "招待された閲覧者",
        next: "Next",

        "email.required": "メールは必須です",
        "email.invalid": "メールが無効です",
        "phone.required": "電話が必要です",
        "phone.invalid": "電話が無効です",
        "phone.min": "電話は10文字以上にする必要があります",

        "login.welcome": "お帰りなさい",
        "login.google": "Googleでログイン",
        "login.facebook": "Facebookでログイン",
        "login.email": "メールでログイン",
        "login.apple": "アップルでログイン",
        "login.to_signup": "アカウントを持っていないのですか?{signup}",

        "auth.footer":
            "By using Group Photo, you agree to our {terms} and {privacy}",

        "register.welcome": "Sharing What Matters",
        "register.google": "Googleにサインアップ",
        "register.facebook": "Facebookでサインアップ",
        "register.email": "メールでサインアップ",
        "register.apple": "Appleにサインアップ",
        "register.to_login": "すでにアカウントをお持ちですか? {login}",

        "nav.pools": "プール",
        "nav.members": "会員",
        "nav.inbox": "受信トレイ",
        "nav.me": "わたし",

        "pools.pools": "プール",
        "pools.new": "新しいプール",
        "pools.home": "ホーム",
        "pools.select_pools": "プールを選択",
        "pools.invite_contact": "連絡先から招待",
        "pools.copy_share": "リンクをコピーして共有",
        "pools.frequent_contacts": "頻繁な連絡先",
        "pools.poolChat": "プールチャット",
        "pools.shareToRings": "リングに共有",
        "pools.addMedia": "メディアを追加",
        "pools.poolSettings": "プール設定",
        "pools.managePool": "プールを管理",
        "pools.descriptionOptional": "説明（オプション）",

        "members.members": "会員",
        "members.invite": "家族や友達を招待する",

        "inbox.inbox": "受信トレイ",
        "inbox.delete": "{user}さんが{pool}から写真を削除しました",
        "inbox.added_single": "{user}さんが写真を{pool}に追加しました",
        "inbox.added_bulk":
            "{user}さんが{number}枚の写真を{pool}に追加しました",

        "account.account": "集合写真のアカウント",
        "account.archive": "アーカイブ",
        "account.backup": "バックアップ設定",
        "account.refer": "友達を紹介",
        "account.help": "ヘルプサポート",
        "account.feedback": "フィードバックを残す",
        "account.type": "口座の種類",
        "account.space_used": "使用スペース",
        "account.upgrade": "集合写真標準にアップグレード",
        "account.archived_pools": "アーカイブされたプール",
        "account.unarchive": "アーカイブ解除",
        "account.media": "メディア",
        "account.backup_history": "バックアップ履歴",

        "upload.choose": "ライブラリから選択",
        "upload.take_photo": "写真やビデオを撮る",
        "upload.cancel": "キャンセル",

        "memberRoles.description1":
            "メディアを追加/削除できます。プールを管理できません",
        "memberRoles.description2":
            "メディアとチャットのみを表示できます。プールを編集できません",
        "memberRoles.description3":
            "役割を割り当て、メンバーを管理できます。アセットを作成、追加、削除、評価する権限があります",
        "memberRoles.description4":
            "他のメンバーの削除、メンバー、コンテンツ/アセットの管理、プール設定の変更はできません",
        "memberRoles.description5": "チャットして評価できます",
        "memberRoles.description6": "チャットのみできます",
        "memberRoles.description7": "評価のみ可能",
        "memberRoles.description8": "表示のみですが、招待できます",
        "memberRoles.description9": "高度な権限を表示",
        "memberRoles.description10": "Can add assets, chat",
        "memberRoles.description11": "View only",
    },
};

export default TRANSLATIONS;
