import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const AuthButton = styled(Button)<any>(({ theme }) => ({
    justifyContent: "flex-start",
    paddingLeft: "20px",
    paddingRight: "20px",
    height: "52px",
    width: "100%",
    maxWidth: "297px",
    color:
        theme.palette.mode === "light"
            ? theme.palette.common.black
            : theme.palette.common.white,
    background:
        theme.palette.mode === "light"
            ? theme.palette.common.white
            : theme.palette.action.hover,
    border: theme.palette.mode === "light" ? "1px solid #e5edf8" : undefined,
    borderRadius: "4px",
    boxShadow: "none",
    "&:hover": {
        background:
            theme.palette.mode === "light"
                ? theme.palette.common.white
                : theme.palette.action.selected,
    },
    "& .MuiButton-startIcon": {
        marginRight: "15px",
    },
}));

export default AuthButton;
