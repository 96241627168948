import { collection, doc, getDoc, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { Collections } from "groupphoto-models";
import { useCallback, useRef } from "react";
import { AssetConverter } from "../converters";
import { db } from "../firebase";

const useMediaViewer = () => {
    const isFetching = useRef<boolean>(false);
    const isFetchingNext = useRef<boolean>(false);
    const isFetchingPrev = useRef<boolean>(false);

    const getAssetQuery = useCallback((id: string) => {
        return doc(db, Collections.Asset, id).withConverter(AssetConverter);
    }, []);

    const fetchDoc = useCallback(
        async (id: string) => {
            try {
                const docRef = getAssetQuery(id);
                return await getDoc(docRef);
            } catch (error: any) {
                throw error;
            }
        },
        [getAssetQuery]
    );

    const fetchCurrentAsset = useCallback(
        async (id: string) => {
            try {
                if (isFetching.current) return null;

                isFetching.current = true;

                const snapshot = await fetchDoc(id);
                if (!snapshot.exists()) return null;
                if (!snapshot.data()) return null;

                return snapshot.data();
            } catch (error: any) {
                throw error;
            } finally {
                isFetching.current = false;
            }
        },
        [fetchDoc]
    );

    /**
     * Fetches the previous document based on the current id
     *
     * @param poolId
     * @param id
     * @returns
     */
    const fetchPreviousTimelineAsset = useCallback(
        async (poolId: string, id: string) => {
            try {
                if (isFetchingPrev.current) return null;

                isFetchingPrev.current = true;

                const docSnap = await fetchDoc(id);

                if (!docSnap.exists()) return null;

                const q = query(
                    collection(db, Collections.Asset),
                    where("poolId", "==", poolId),
                    where("isArchived", "==", false),
                    orderBy("takenAt", "asc"),
                    startAfter(docSnap),
                    limit(3)
                ).withConverter(AssetConverter);

                const snapshot = await getDocs(q);

                if (snapshot.empty) return null;

                return snapshot.docs.map((d) => d.data());
            } catch (error: any) {
                throw error;
            } finally {
                isFetchingPrev.current = false;
            }
        },
        [fetchDoc]
    );

    /**
     * Fetches the next asset based on the id
     *
     * @param poolId
     * @param id
     * @returns
     */
    const fetchNextTimelineAsset = useCallback(
        async (poolId: string, id: string) => {
            try {
                if (isFetchingNext.current) return null;

                isFetchingNext.current = true;

                const docSnap = await fetchDoc(id);

                if (!docSnap.exists()) return null;

                const q = query(
                    collection(db, Collections.Asset),
                    where("poolId", "==", poolId),
                    where("isArchived", "==", false),
                    orderBy("takenAt", "desc"),
                    startAfter(docSnap),
                    limit(3)
                ).withConverter(AssetConverter);

                const snapshot = await getDocs(q);

                if (snapshot.empty) return null;

                return snapshot.docs.map((d) => d.data());
            } catch (error: any) {
                throw error;
            } finally {
                isFetchingNext.current = false;
            }
        },
        [fetchDoc]
    );

    return {
        getAssetQuery,
        fetchCurrentAsset,
        fetchNextTimelineAsset,
        fetchPreviousTimelineAsset,
    };
};

export default useMediaViewer;
