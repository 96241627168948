import { Box, Typography } from "@mui/material";
import MediaViewerHeader from "./MediaViewerHeader";

const MediaUnavailable = ({ closePreview, title }) => (
    <>
        <MediaViewerHeader onClosePreview={closePreview} title={title} />

        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
            }}
        >
            <Typography>This photo will be available soon</Typography>
        </Box>
    </>
);

export default MediaUnavailable;
