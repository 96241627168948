// @ts-nocheck

import { Box, Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import { translator } from "../../../i18n/translator";
import { useProfileService } from "../../../services";
import { useAppSelector } from "../../../store";
import CustomDialogTitle from "../CustomDialogTitle";

import "react-phone-number-input/style.css";
import { useDialog } from "../../../hooks";

const CustomPhoneInput = (props, ref) => {
    return <TextField {...props} inputRef={ref} fullWidth label="Phone" variant="outlined" name="phone" />;
};

const ForwardedInput = forwardRef(CustomPhoneInput);

const EditPhoneDialog: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState("");

    const ProfileService = useProfileService();
    const { fullScreen, onClose } = useDialog();

    const user = useAppSelector((state) => state.user);

    useEffect(() => {
        if (user) {
            setPhone(user.details.phone ?? "");
        }
    }, [user]);

    const handleTextChange = (value: any) => {
        setPhone(value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();

            if (loading || !phone || !isValidPhoneNumber(phone)) return;

            setLoading(true);

            await ProfileService.updateProfile({
                name: user.details.fullName,
                username: user.details.username,
                phone: phone,
            });

            setLoading(false);
            onClose();
        } catch (e) {
            setLoading(false);
            console.error(e);
        }
    };

    return (
        <Dialog fullScreen={fullScreen} open onClose={onClose}>
            <CustomDialogTitle onClose={onClose}>Edit Phone</CustomDialogTitle>
            <DialogContent>
                <Box id="editPhoneForm" component="form" mt={3} onSubmit={handleSubmit}>
                    <PhoneInput value={phone} onChange={handleTextChange} inputComponent={ForwardedInput} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} type="button" onClick={onClose}>
                    {translator("cancel")}
                </Button>
                <Button form="editPhoneForm" disabled={loading} type="submit">
                    {translator("update")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditPhoneDialog;
