import { LoadingButton } from "@mui/lab";
import {
    AppBar,
    Avatar,
    Box,
    Divider,
    Link,
    List,
    ListItem,
    ListItemText,
    Paper,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { auth } from "../firebase";
import { useAuthenticationStyles } from "../hooks";
import { translator } from "../i18n/translator";
import { LogoHorizontal } from "../icons";
import { stringInitials, stringToColor } from "../lib/helpers";
import { ROUTES } from "../routes";
import { useUserService } from "../services";
import { useAppSelector } from "../store";

const DeleteAccount: React.FC = () => {
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const [search] = useSearchParams();
    const user = useAppSelector((state) => state.user);

    const styles = useAuthenticationStyles();

    const { confirmAccountDeletion } = useUserService();

    const token = search.get("token");

    useEffect(() => {
        if (!user) return;

        if (
            !user.plan.deletion ||
            user.plan.deletion.status === "cancelled" ||
            !token
        ) {
            navigate(ROUTES.ACCOUNT, { replace: true });
        }
    }, [user, token, navigate]);

    const onConfirm = async () => {
        try {
            if (loading) return;

            setLoading(true);

            await confirmAccountDeletion(token);

            navigate(ROUTES.ACCOUNT);
        } catch (e: any) {
            setLoading(false);
            console.error(e);
        }
    };

    const authUser = auth.currentUser;

    if (!user || !authUser) return null;

    const details = user.details;
    const plan = user.plan;
    const digest = user.digest;

    const displayName = () => {
        return (
            details.fullName ??
            details.username ??
            authUser.displayName ??
            authUser.email
        );
    };

    return (
        <Box sx={styles.wrapperStyle()}>
            <AppBar position="static" sx={styles.appBarStyle()}>
                <Toolbar>
                    <LogoHorizontal style={{ width: "150px" }} />

                    <Box sx={{ flexGrow: 1 }}></Box>

                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        <Link
                            href="https://groupphoto.com/legal/terms-of-service"
                            target="_blank"
                            rel="nofollow"
                        >
                            {translator("terms_of_service")}
                        </Link>
                        <Link
                            href="https://groupphoto.com/legal/privacy-policy"
                            target="_blank"
                            rel="nofollow"
                        >
                            {translator("privacy_policy")}
                        </Link>
                    </Stack>
                </Toolbar>
            </AppBar>

            <Paper
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    margin: "60px auto 0",
                    padding: "40px",
                    width: "100%",
                    maxWidth: "1000px",
                    borderRadius: "12px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "460px",
                        width: "100%",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar
                            sx={{
                                width: "58px",
                                height: "58px",
                                background: stringToColor(displayName()),
                            }}
                            children={stringInitials(displayName())}
                        />

                        <Typography
                            variant="h4"
                            component="h4"
                            sx={{
                                color: (theme) =>
                                    theme.palette.mode === "light"
                                        ? "#062f5d"
                                        : undefined,
                            }}
                        >
                            {displayName()}
                        </Typography>

                        <Typography component="span" sx={{ color: "#57585e" }}>
                            {details.email ?? authUser.email ?? ""}
                        </Typography>
                    </Box>

                    <Box sx={{ margin: "42px auto", width: "100%" }}>
                        <List>
                            <ListItem divider>
                                <ListItemText>
                                    {translator("digest.yourStorage")}
                                </ListItemText>
                                {translator("account.expandedSpaceUsed", {
                                    used: plan.storage.size,
                                    total: plan.storage.total,
                                })}
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    {translator("digest.photosUploaded")}
                                </ListItemText>
                                {digest.fileUploadCount}
                            </ListItem>
                        </List>
                    </Box>

                    <Typography sx={{ textAlign: "center", color: "#57585e" }}>
                        {translator("account.restoreDays")}
                    </Typography>

                    <LoadingButton
                        disableElevation
                        variant="contained"
                        color="error"
                        disabled={loading}
                        loading={loading}
                        onClick={onConfirm}
                        sx={{ marginTop: "66px" }}
                    >
                        {translator("account.confirmDeletion")}
                    </LoadingButton>
                </Box>
            </Paper>
        </Box>
    );
};

export default DeleteAccount;
