import { Box, Skeleton } from "@mui/material";

const SkeletonList = () => (
    <Box px={2}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
    </Box>
);

export default SkeletonList;
