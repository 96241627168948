import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import MainSidebar from "../Sidebars/Main";
import UploadDrawer from "../Sidebars/UploadDrawer";

const MainLayout = () => (
    <Box display="flex" width="100%" height="100vh">
        <MainSidebar />

        <Outlet />

        <UploadDrawer />
    </Box>
);

export default MainLayout;
