import { memo } from "react";
import { Stack } from "@mui/material";

const KeyboardButtonStack: React.FC<React.PropsWithChildren> = ({ children }) => (
    <Stack direction="row" spacing={0.5} sx={{ marginBottom: "4px" }}>
        {children}
    </Stack>
);

export default memo(KeyboardButtonStack);
