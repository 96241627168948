/* eslint-disable */
// @ts-nocheck

export default () => {
    function sha512(file) {
        return crypto.subtle.digest("SHA-512", file).then((buf) => {
            return Array.prototype.map
                .call(new Uint8Array(buf), (x) =>
                    ("00" + x.toString(16)).slice(-2)
                )
                .join("");
        });
    }

    const allowedVideoTypes: string[] = [
        "AVI",
        "MPG",
        "MPEG",
        "MP4",
        "MKV",
        "MPV",
        "WMV",
        "MOV",
        "QT",
        "WEBM",
        "M4V",
        "HEVC",
    ];

    const allowedImageTypes: string[] = [
        "3FR",
        "ARW",
        "CR2",
        "CRW",
        "DCR",
        "DNG",
        "DXO",
        "ERF",
        "JPG",
        "KDC",
        "MEF",
        "MOS",
        "MRW",
        "NEF",
        "NRW",
        "ORF",
        "PEF",
        "PPM",
        "RAF",
        "RAW",
        "RW2",
        "SR2",
        "SRF",
        "SRW",
        "TIF",
        "TIFF",
        "X3F",
        "HEIF",
        "HEIC",
        "JPEG",
        "PNG",
        "WEBP",
        "BMP",
    ];

    const allowedAudioTypes: string[] = []; // out of scope right now ['MP2','MP3']

    function determineMediaType(
        extension: string,
        mimeType: string
    ): "image" | "video" | "audio" | "" {
        switch (true) {
            case mimeType.startsWith("image/"):
            case allowedImageTypes.includes(extension):
                return "image";
            case allowedVideoTypes.includes(extension):
                return "video";
            case allowedAudioTypes.includes(extension):
                return "audio";
            default:
                return "";
        }
    }

    function extractExtension(name: string) {
        let extension = name.split(".").pop();
        return extension!.toUpperCase().replace(/[^A-Z0-9]+/g, "");
    }

    function parseFileToSHA512(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onerror = (e) => console.error(e);
            reader.onload = () => {
                sha512(reader.result).then((hash) => resolve(hash));
            };
            reader.readAsArrayBuffer(file);
        });
    }

    function generatePreview(file): Promise<string> {
        return new Promise((resolve) => {
            const extension = extractExtension(file.name);
            const type = determineMediaType(extension, file.type);

            if (
                type === "image" &&
                ["JPEG", "JPG", "PNG", "WEBP"].includes(extension)
            ) {
                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onerror = (e) => console.error(e);
                reader.onload = (event) => {
                    if (!event.target) return;
                    const { result } = event.target;
                    resolve(result as string);
                };
            } else {
                resolve("");
            }
        });
    }

    self.addEventListener("message", (message: MessageEvent) => {
        if (!message.data) return;

        if (message.data.function === "processFile") {
            Promise.all([
                parseFileToSHA512(message.data.file),
                generatePreview(message.data.file),
            ]).then((results) => {
                self.postMessage({
                    result: {
                        id: message.data.id,
                        hash: results[0],
                        previewURL: results[1],
                    },
                });
            });

            // generatePreview(message.data.file).then((base64Thumb) => {
            //     self.postMessage({
            //         result: {
            //             id: message.data.id,
            //             hash: "",
            //             previewURL: base64Thumb,
            //         },
            //     });
            // });
        }
    });
};
