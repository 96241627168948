import { Box } from "@mui/material";

interface Props {
    children: any;
}

const KeyboardTile: React.FC<Props> = ({ children }) => (
    <Box
        component="span"
        sx={{
            backgroundColor: "#4f545c",
            border: "1px solid #36393f",
            display: "block",
            padding: "3px 6px 4px",
            marginRight: "3px",
            borderRadius: "4px",
            cursor: "default",
            minWidth: "14px",
            minHeight: "14px",
            textAlign: "center",
            color: "#f6f6f7",
            fontWeight: 900,
            fontSize: "14px",
            boxShadow: "inset 0 -4px 0 #202225",
        }}
    >
        {children}
    </Box>
);

export default KeyboardTile;
