import { Box, TextField, Typography } from "@mui/material";
import { remainingChars } from "../../../lib/helpers";

const StepDetails = ({ loading, title, onTitleChange }) => {
    return (
        <Box sx={{ my: 3 }}>
            <Typography variant="body2" color="grey.500" fontWeight={600} sx={{ mb: 2 }}>
                Details
            </Typography>
            <TextField
                autoFocus
                fullWidth
                required
                disabled={loading}
                label="Storybook Title"
                value={title}
                onChange={onTitleChange}
                inputProps={{
                    maxLength: 35,
                }}
                helperText={remainingChars(title)}
            />
        </Box>
    );
};

export default StepDetails;
