import React from "react";
import { IntlProvider } from "react-intl";

import { LOCALES } from "./locales";
import messages from "./messages";

const Provider = ({
    children,
    locale = LOCALES.ENGLISH,
}: {
    children: any;
    locale: any;
}) => (
    <IntlProvider
        locale={locale}
        defaultLocale={LOCALES.ENGLISH}
        textComponent={React.Fragment}
        messages={messages[locale]}
    >
        {children}
    </IntlProvider>
);

export default Provider;
