import {
    allowedAudioTypes,
    allowedImageTypes,
    allowedVideoTypes,
} from "../lib/constants";

const useFileUpload = () => {
    const generateMetadata = (opts: {
        poolId: string;
        file: File;
        userId: string;
        hash: string;
    }) => {
        const extension = extractExtension(opts.file.name);
        const contentType = opts.file.type || `image/${extension}`;

        return {
            contentType,
            size: opts.file.size,
            customMetadata: {
                "gp-backup-type": "original",
                "mime-type": contentType,
                hidden: "false",
                "original-file-name": opts.file.name,
                "creation-date": new Date(
                    opts.file.lastModified
                ).toDateString(),
                "sha-512-hash": opts.hash,
                "user-id": opts.userId,
                "pixel-height": "",
                "pixel-width": "",
                "media-type": determineMediaType(extension, contentType),
                "pool-ids": opts.poolId,
                "source-type": "",
                favorite: "false",
                "gp-storage-event-type": "upload",
            },
        };
    };

    const validateFile = (file: File) => {
        const name = file.name;
        const extension = extractExtension(name);
        const allowedTypes = [...allowedVideoTypes, ...allowedImageTypes];

        if (!allowedTypes.includes(extension)) {
            console.error(
                `${file.name} has an invalid extension: ${extension}`
            );
            return false;
        }

        return true;
    };

    /**
     * Determine File media type
     * @param extension
     * @param mimeType
     * @returns
     */
    const determineMediaType = (
        extension: string,
        mimeType: string
    ): "image" | "video" | "audio" | "" => {
        switch (true) {
            case mimeType.startsWith("image/"):
            case allowedImageTypes.includes(extension):
                return "image";
            case allowedVideoTypes.includes(extension):
                return "video";
            case allowedAudioTypes.includes(extension):
                return "audio";
            default:
                return "";
        }
    };

    const extractExtension = (name: string) => {
        let extension = name.split(".").pop();
        return extension!.toUpperCase().replace(/[^A-Z0-9]+/g, "");
    };

    return {
        determineMediaType,
        extractExtension,
        generateMetadata,
        validateFile,
    };
};

export default useFileUpload;
