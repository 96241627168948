import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../routes";
import { useAuth } from "../services";
import { translator } from "../i18n/translator";
import { useAuthenticationStyles } from "../hooks";
import { http } from "../http";

import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
    Stack,
    Typography,
} from "@mui/material";

import AuthButton from "../components/AuthButton";
import { AppleIcon, FacebookIcon, GoogleIcon, LogoHorizontal, MailIcon } from "../icons";

type QR_STATUS = "initial" | "loading" | "approved" | "rejected" | "error";

const Authentication: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [status, setStatus] = useState<QR_STATUS>("initial");
    const [error, setError] = useState<string>("");

    const isInitial = status === "initial";
    const isLoading = status === "loading";
    const isApproved = status === "approved";
    const isRejected = status === "rejected";
    const isError = status === "error";

    const search = new URLSearchParams(window.location.search);
    const isAssisted = search.has("assistedLoginReqId");

    const navigate = useNavigate();
    const location = useLocation();
    const { user, authSocial } = useAuth();

    const styles = useAuthenticationStyles();

    const mode = (() => {
        if (location.pathname.includes("login")) {
            return "login";
        } else if (location.pathname.includes("register")) {
            return "register";
        } else {
            return "";
        }
    })();

    useEffect(() => {
        const search = new URLSearchParams(window.location.search);
        const isAssisted = search.has("assistedLoginReqId");

        if (user && !isAssisted) {
            navigate(ROUTES.HOME, { replace: true });
        } else if (user && isAssisted) {
            setOpen(true);
        }
    }, [user, isAssisted, navigate]);

    useEffect(() => {
        const title = mode === "login" ? "Login" : "Register";
        document.title = `${title} - Group Photo`;
    }, [mode]);

    const onSocialAuth = async (platform: "apple" | "google" | "facebook") => {
        try {
            await authSocial(platform);
        } catch (e: any) {
            setError(`firebaseError.${e.code}`);
            console.error(e);
        }
    };

    const onClose = () => {
        if (isLoading) return;
        setOpen(false);
    };

    const onApproveRequest = async () => {
        try {
            if (isLoading) return;
            if (!user) throw new Error("User is not yet logged in.");

            setStatus("loading");

            const search = new URLSearchParams(window.location.search);
            const assistedLoginReqId = search.get("assistedLoginReqId");
            const { data } = await http.post(`/api/assisted-login/approve?key=${assistedLoginReqId}`);

            if (data.approved) {
                setStatus("approved");
            }
        } catch (e) {
            console.error(e);
            setStatus("error");
        }
    };

    const onRejectRequest = async () => {
        try {
            if (isLoading) return;

            setStatus("loading");

            const search = new URLSearchParams(window.location.search);
            const assistedLoginReqId = search.get("assistedLoginReqId");
            await http.post(`/api/assisted-login/reject?key=${assistedLoginReqId}`);

            setStatus("rejected");
        } catch (e) {
            console.error(e);
            setStatus("error");
        }
    };

    const onConfirm = () => {
        if (user) {
            navigate(ROUTES.HOME, { replace: true });
        } else {
            onClose();
        }
    };

    const LOGIN_ROUTE = `${ROUTES.LOGIN}?${search.toString()}`;
    const LOGIN_PASSWORD_ROUTE = `${ROUTES.LOGIN_PASSWORD}?${search.toString()}`;
    const REGISTER_PASSWORD_ROUTE = `${ROUTES.REGISTER_PASSWORD}?${search.toString()}`;
    const REGISTER_ROUTE = `${ROUTES.REGISTER}?${search.toString()}`;

    return (
        <Box className="authentication" sx={styles.wrapperStyle()}>
            <Container maxWidth="sm">
                <Box sx={{ mt: 1, "& .MuiTextField-root": { mb: 1 } }}>
                    <LogoHorizontal style={{ display: "block", margin: "0 auto" }} />

                    <Typography
                        sx={{
                            display: "block",
                            mt: 2,

                            textAlign: "center",
                        }}
                    >
                        {translator(`${mode}.welcome`)}
                    </Typography>

                    {error && (
                        <Alert severity="error" sx={{ mt: 2, mx: "auto", maxWidth: "297px" }}>
                            {translator(error)}
                        </Alert>
                    )}

                    {isAssisted && (
                        <Alert severity="info" sx={{ mt: 2, mx: "auto", maxWidth: "297px" }}>
                            <AlertTitle>QR Assisted Login</AlertTitle>
                            Please login first
                        </Alert>
                    )}

                    <Stack alignItems="center" spacing={1} sx={{ my: 4 }}>
                        <AuthButton
                            startIcon={<GoogleIcon />}
                            variant="contained"
                            disableElevation
                            onClick={() => onSocialAuth("google")}
                        >
                            {translator(`${mode}.google`)}
                        </AuthButton>
                        <AuthButton
                            startIcon={<FacebookIcon />}
                            variant="contained"
                            disableElevation
                            onClick={() => onSocialAuth("facebook")}
                        >
                            {translator(`${mode}.facebook`)}
                        </AuthButton>
                        <AuthButton
                            startIcon={<AppleIcon />}
                            variant="contained"
                            disableElevation
                            onClick={() => onSocialAuth("apple")}
                        >
                            {translator(`${mode}.apple`)}
                        </AuthButton>
                        <AuthButton
                            component={RouterLink}
                            to={mode === "login" ? LOGIN_PASSWORD_ROUTE : REGISTER_PASSWORD_ROUTE}
                            startIcon={<MailIcon />}
                            variant="contained"
                            disableElevation
                        >
                            {translator(`${mode}.password`)}
                        </AuthButton>
                    </Stack>

                    <Typography
                        sx={{
                            textAlign: "center",
                        }}
                    >
                        {translator(`${mode}.redirectText`, {
                            link: (
                                <Link component={RouterLink} to={mode === "login" ? REGISTER_ROUTE : LOGIN_ROUTE}>
                                    {translator(mode === "login" ? "signup" : "login")}
                                </Link>
                            ),
                        })}
                    </Typography>

                    <Typography
                        sx={{
                            mt: 5,
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#627796",
                        }}
                    >
                        {translator("auth.footer", {
                            link: (
                                <Box component="span" sx={{ display: "block" }}>
                                    <Link
                                        href="https://groupphoto.com/legal/terms-of-service"
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        {translator("auth.footerProtect")}
                                    </Link>
                                </Box>
                            ),
                        })}
                    </Typography>
                </Box>
            </Container>

            <Dialog open={open} maxWidth="sm" fullWidth>
                <DialogTitle>QR code login</DialogTitle>
                <DialogContent>
                    {isInitial && <DialogContentText>You've scanned a QR code from another device.</DialogContentText>}
                    {isLoading && <DialogContentText>Loading Request...</DialogContentText>}
                    {isRejected && (
                        <DialogContentText>
                            The login request has been <strong>rejected</strong>.
                        </DialogContentText>
                    )}
                    {isApproved && (
                        <DialogContentText>
                            Your login request has been <strong>approved</strong>.
                        </DialogContentText>
                    )}
                    {isError && (
                        <DialogContentText>
                            There was an error logging in your other device. Please try again.
                        </DialogContentText>
                    )}
                </DialogContent>
                {(isInitial || isLoading) && (
                    <DialogActions>
                        <Button disabled={isLoading} onClick={onRejectRequest}>
                            Cancel
                        </Button>
                        <Button variant="contained" disabled={isLoading} onClick={onApproveRequest}>
                            Approve Request
                        </Button>
                    </DialogActions>
                )}
                {(isApproved || isRejected) && (
                    <DialogActions>
                        <Button onClick={onConfirm}>Ok</Button>
                    </DialogActions>
                )}
                {isError && (
                    <DialogActions>
                        <Button onClick={onClose}>Close</Button>
                        <Button onClick={onApproveRequest}>Re-approve Request</Button>
                    </DialogActions>
                )}
            </Dialog>
        </Box>
    );
};

export default Authentication;
